export function parseAreaKey(areaKey) {
  if (!areaKey) return

  const [countryCode, stateCode, city, postalCode] = areaKey.split('_')

  if (!countryCode) return

  let level
  if (stateCode && city && postalCode) {
    level = 'postal'
  } else if (stateCode && city) {
    level = 'city'
  } else if (stateCode) {
    level = 'state'
  } else {
    return
  }

  return {countryCode, stateCode, city, postalCode, level}
}

export function areaToString(area) {
  if (!area) return ''

  const {countryCode, stateCode, city, postalCode, level} = area
  if (level === 'state') {
    return `${stateCode}, ${countryCode}`
  }

  if (level === 'city') {
    return `${city}, ${stateCode}`
  }

  if (level === 'postal') {
    return `${city}, ${stateCode} ${postalCode}`
  }
}
