import {Auth0Client} from '@auth0/auth0-spa-js'

const auth0 = new Auth0Client({
  domain: config.AUTH0_DOMAIN,
  clientId: config.AUTH0_CLIENT_ID,
  authorizationParams: {
    audience: config.AUTH0_AUDIENCE,
    redirect_uri: `${window.document.location.origin}/auth`,
  },
})

export default auth0
