import * as React from 'react'
import {Checkbox} from '@quotefactory/ui'
import {useSelector} from 'react-redux'
import {selectAccountById} from 'reducers/account'

const RmisForm = function ({
  accountId,
  formValues,
  setFormValues,
  submitForm,
  connectionConfig,
  code,
  error,
}) {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [passwordEdited, setPasswordEdited] = React.useState(false)
  const [subDomain, setSubDomain] = React.useState('')

  const account = useSelector((state) => selectAccountById(state, accountId))

  React.useEffect(() => {
    const usernameConfig =
      connectionConfig.find((config) => config.key === 'username')?.value || ''
    const passwordConfig =
      connectionConfig.find((config) => config.key === 'password')?.value || ''
    const subDomainConfig =
      connectionConfig.find((config) => config.key === 'subDomain')?.value || ''
    setUsername(usernameConfig)
    setPassword(passwordConfig)
    setSubDomain(subDomainConfig)
  }, [connectionConfig])

  const inputChange = (e) => {
    const {name, value} = e.target
    switch (name) {
      case 'username':
        setUsername(value)
        break
      case 'password':
        setPassword(value)
        setPasswordEdited(true)
        break
      case 'subDomain': {
        const sd = value.replace(/^http(s)?:\/\//, '').split('.')[0]
        setSubDomain(sd)
        break
      }
      default:
        break
    }
  }

  const activeChange = ({target}) => {
    setFormValues((prev) => ({...prev, active: target.checked}))
  }

  const onSubmit = () => {
    const updateIntegration = {
      connectionConfig: [
        {key: 'username', value: username},
        {key: 'subDomain', value: subDomain},
      ],
      connectionState: 'connected',
    }

    if (passwordEdited) {
      updateIntegration.connectionConfig.push({
        key: 'password',
        value: password,
      })
    }

    submitForm(updateIntegration)
  }

  return (
    <div className="p-8">
      <div className="pt-8">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <span className="font-semibold">Code:</span>
              <span>{code}</span>
            </div>

            <div className="flex justify-between items-center">
              <span className="font-semibold">Slack:</span>
              <a
                target="_blank"
                href="https://quotefactory.slack.com/archives/C06L7NFBR7U"
                rel="noreferrer"
                className="text-blue-600 hover:text-blue-800">
                #launch-rmis
              </a>
            </div>

            <div className="flex justify-between items-center">
              <span className="font-semibold mr-2">Username:</span>
              <input
                className="p-2 border border-gray-300 rounded w-64"
                name="username"
                value={username}
                onChange={inputChange}
              />
            </div>

            <div className="flex justify-between items-center">
              <span className="font-semibold mr-2">Password:</span>
              <input
                className="p-2 border border-gray-300 rounded w-64"
                name="password"
                type="password"
                value={password}
                onChange={inputChange}
                placeholder={password ? '********' : ''}
                required
              />
            </div>

            <div className="flex justify-between items-center">
              <div>
                <span className="font-semibold mr-2">Subdomain:</span>
                <span className="block text-xs text-gray-600">
                  without https:// or .com &nbsp;
                </span>
              </div>
              <input
                className="p-2 border border-gray-300 rounded w-64"
                name="subDomain"
                value={subDomain}
                onChange={inputChange}
                required
              />
            </div>

            <div className="flex justify-between items-center">
              <span className="font-semibold mr-2">Request Credentials:</span>
              <a
                href={mailToLink({
                  to: 'tsi@truckstop.com',
                  cc: 'support@quotefactory.com',
                  subject: `RMIS ⇔ Quote Factory Integration for ${account.name}`,
                  body: [
                    'Hi Truckstop,',
                    '',
                    `${account.name} ${
                      account?.brokerProfile?.mcNumber
                        ? `(MC-${account.brokerProfile.mcNumber})`
                        : ''
                    } wants to integrate their RMIS and Quote Factory accounts.`,
                    '',
                    'Please reply with:',
                    '- API client ID',
                    '- API password',
                    '- Custom subdomain',
                    '',
                    'Thank you,',
                    '',
                    'Quote Factory Support',
                    'support@quotefactory.com',
                  ].join('\n'),
                })}
                className="btn">
                Send Email
              </a>
            </div>
          </div>

          <div className="flex justify-center my-3">
            <label className="flex">
              <Checkbox
                name="active"
                checked={formValues.active}
                onChange={activeChange}
              />
              <span className="ml-2">Active</span>
            </label>
          </div>

          {error && <div className="p-2 text-red-600 rounded-md">{error}</div>}

          <div className="mt-8">
            <span className="block w-full">
              <button
                type="button"
                onClick={onSubmit}
                className="btn blue shadowed w-full">
                Update
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}

/**
 * Returns a mailto link for preformatted email at this time.
 * @param {{to: string, cc: string, subject: string, body: string}} props
 * @returns {string}
 */
const mailToLink = ({to, cc, subject, body}) => {
  const emailSubject = encodeURIComponent(subject)
  const emailBody = encodeURIComponent(body)
  return `mailto:${to}?cc=${cc}&subject=${emailSubject}&body=${emailBody}`
}

export default RmisForm
