/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  FilterStatus,
  FilterEquipmentType,
  FilterBroker,
  FilterCarrier,
  FilterProvider,
  FilterBookedDate,
  FilterPickupDate,
  FilterLocation,
  FilterXN,
  FilterInterline,
  FilterTest,
  FilterSearch,
  FilterCoverageEligible,
} from 'components/filters'

export default function FiltersPanel({
  entityType,
  filterKeys,
  filters,
  setFilters,
  isFiltered,
  isDesktop,
}) {
  const onFilterChange = (filterValues) => {
    setFilters((prev) => ({
      ...prev,
      ...filterValues,
    }))
  }

  return (
    <>
      {!isDesktop && (
        <FilterSearch
          filters={filters}
          setFilters={setFilters}
          isFiltered={isFiltered}
          isDesktop={isDesktop}
        />
      )}
      <div
        className={`flex ${
          isDesktop ? 'filter-pills' : 'filter-mobile flex-col'
        } flex-wrap justify-end min-w-0`}>
        {filterKeys.has('status') && (
          <FilterStatus
            items={filters.status}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}

        {filterKeys.has('brokerId') && (
          <FilterBroker
            brokerId={filters.brokerId}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}

        {filterKeys.has('carrierId') && (
          <FilterCarrier
            entityType={entityType}
            carrierId={filters.carrierId}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}

        {filterKeys.has('provider') && (
          <FilterProvider
            entityType={entityType}
            items={filters.provider}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}

        {filterKeys.has('bookedDate') && (
          <FilterBookedDate
            bookedDate={filters.bookedDate}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}

        {filterKeys.has('pickupDate') && (
          <FilterPickupDate
            pickupDate={filters.pickupDate}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}

        {filterKeys.has('origin') && (
          <FilterLocation
            stopType="origin"
            areaKey={filters.origin}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}

        {filterKeys.has('destination') && (
          <FilterLocation
            stopType="destination"
            areaKey={filters.destination}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}

        {filterKeys.has('xn') && (
          <FilterXN
            value={filters.xn}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}

        {filterKeys.has('interline') && (
          <FilterInterline
            value={filters.interline}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}

        {filterKeys.has('equipment') && (
          <FilterEquipmentType
            items={filters.equipment}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}

        {filterKeys.has('isTest') && (
          <FilterTest
            value={filters.isTest}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}

        {filterKeys.has('autoCover') && (
          <FilterCoverageEligible
            value={filters.autoCover}
            onFilterChange={onFilterChange}
            isDesktop={isDesktop}
          />
        )}
      </div>
      {isDesktop && (
        <FilterSearch
          filters={filters}
          setFilters={setFilters}
          isFiltered={isFiltered}
          isDesktop={isDesktop}
        />
      )}
    </>
  )
}
