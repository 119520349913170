import React from 'react'
import {Link} from '@reach/router'
import {SVG} from '@quotefactory/ui'
import {Layout} from 'pages'
import FactorsList from '../components/FactorsList'

export default function Factors({report}) {
  return (
    <Layout>
      <div className="flex items-center py-2 justify-between my-6">
        <div className="flex">
          <SVG
            name="factoring"
            className="flex-shrink-0 h-8 w-8 mr-1"
            aria-hidden="true"
          />
          <h2>Factors</h2>
          <Link
            to="/factor/new"
            className="inline-flex items-center justify-center y-1.5 text-13 ml-4 font-bold rounded-full text-gray-800 hover:text-blue-500">
            <SVG name="addCircle" className="" />
          </Link>
        </div>
      </div>

      <FactorsList />
    </Layout>
  )
}
