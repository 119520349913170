import * as React from 'react'
import {navigate} from '@reach/router'
import {useIntegrations} from '../hooks'
import useKeyListener from '../hooks/use-key-listener'
import FactorsListItem from './FactorsListItem'

let filteredFactors = []

export default function FactorsList(props) {
  const {getIntegrations, isLoading} = useIntegrations()
  const factors = getIntegrations({type: 'factoring'})

  // A press-any-key search filter
  const [search, setSearch] = React.useState('')
  const onKeyDown = (key, modifiers) => {
    if (key === 'Escape') {
      setSearch('')
      return
    }
    if (key === 'Delete' || key === 'Backspace') {
      setSearch((prev) => prev.slice(0, -1))
      return
    }
    // If the filtered list only has one item showing, navigate
    // to the detail with the Enter key.
    if (key === 'Enter' && filteredFactors.length === 1) {
      navigate(`/factor/${filteredFactors[0].id}`)
    }

    // Don't care about function keys or key combinations except "shift" for uppercase.
    if (key.length > 1 || (modifiers.length > 0 && modifiers[0] !== 'shift')) {
      return
    }
    setSearch((prev) => (prev === '' && key === ' ' ? prev : `${prev}${key}`))
  }
  useKeyListener(onKeyDown)

  const searchRegex = React.useMemo(() => new RegExp(search, 'ig'), [search])

  const sortedFactors = React.useMemo(
    () =>
      isLoading
        ? []
        : factors.sort((a, b) => {
            const aName = a.name.toLowerCase()
            const bName = b.name.toLowerCase()
            if (aName < bName) return -1
            if (aName > bName) return 1
            return 0
          }),
    [factors.length],
  )

  filteredFactors = sortedFactors.filter((a) => {
    if (!search) return true
    return a.name.match(searchRegex)
  })

  return (
    <div className="pb-8 @container">
      {/* Header Row */}
      <div className="header z-[2] flex items-center gap-x-2 pl-6 pr-5 py-2 text-12 @3xl:text-13 sticky top-0 mt-8 mb-3 -mx-[4px] border-x-4 text-black-100 bg-gray-200 border-gray-400 border-x-gray-200">
        <div className="w-4/12 @3xl:w-7/12 @3xl:flex">
          Name
          {search && (
            <div className="ml-6">
              <span className="text-green-500">search: </span>
              <span className="text-black-500">{search}</span>
            </div>
          )}
        </div>
        <div className="w-[25%] @3xl:w-3/12">Integrated</div>
      </div>
      {/* Body rows */}
      {filteredFactors.map((factor) => (
        <FactorsListItem key={factor.id} factor={factor} match={search} />
      ))}
    </div>
  )
}
