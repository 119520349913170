import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit'
import api from '../services/api'

export const getAuthenticatedUser = createAsyncThunk(
  'getAuthenticatedUser',
  async () => {
    const result = await api.fetchAuthenticatedUser()
    return result
  },
)

export const updateUser = createAsyncThunk('updateUser', async (payload) => {
  const result = await api.updateUser(payload)
  return result
})

export const anonymous = {}

const userSlice = createSlice({
  name: 'user',

  initialState: anonymous,

  extraReducers: (builder) => {
    builder.addCase(getAuthenticatedUser.fulfilled, (state, action) => {
      state.user = action.payload.user
    })
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = action.payload
    })
  },
})

export const selectUser = createSelector([(state) => state.user], (state) => {
  return state.user
})

export default userSlice.reducer
