import {parseISO} from 'date-fns'
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'
import api from '../services/api'

const shipmentAdapter = createEntityAdapter({
  sortComparer: (a, b) => {
    const aCreatedAt = parseISO(a.createdAt)
    const bCreatedAt = parseISO(b.createdAt)

    if (aCreatedAt.getTime() < bCreatedAt.getTime()) return 1
    if (aCreatedAt.getTime() > bCreatedAt.getTime()) return -1
    return 0
  },
})

///////////////////////////////////////////////////////////////////////////////
// Async middleware
///////////////////////////////////////////////////////////////////////////////

export const uncancelShipmentById = createAsyncThunk(
  'uncancelShipmentById',
  async ({accountId, shipmentId}, {rejectWithValue}) => {
    console.log({accountId, shipmentId})
    try {
      const result = await api.updateShipment(accountId, shipmentId, {
        canceledAt: 0,
      })
      return result
    } catch (err) {
      return rejectWithValue({
        message: err.message,
        statusCode: err.statusCode,
        apiStack: err.apiStack,
      })
    }
  },
)

export const xnCloneShipment = createAsyncThunk(
  'xnCloneShipment',
  async ({shipmentId}, {rejectWithValue}) => {
    try {
      const result = await api.cloneXNShipment(shipmentId)
      return result
    } catch (err) {
      console.log(err)
      return rejectWithValue({
        message: err.message,
        statusCode: err.statusCode,
        apiStack: err.apiStack,
      })
    }
  },
)

export const fetchShipmentById = createAsyncThunk(
  'fetchShipmentById',
  async (shipmentId, {rejectWithValue}) => {
    try {
      const result = await api.fetchShipment(shipmentId)
      return result
    } catch (err) {
      return rejectWithValue({
        message: err.message,
        statusCode: err.statusCode,
        apiStack: err.apiStack,
      })
    }
  },
)

export const listShipments = createAsyncThunk(
  'listShipments',
  async (options = {}, {rejectWithValue}) => {
    try {
      const result = await api.listShipments(options)
      return result
    } catch (err) {
      return rejectWithValue({
        message: err.message,
        statusCode: err.statusCode,
        apiStack: err.apiStack,
      })
    }
  },
)

///////////////////////////////////////////////////////////////////////////////
// Slice
///////////////////////////////////////////////////////////////////////////////

const shipmentSlice = createSlice({
  name: 'shipment',

  initialState: shipmentAdapter.getInitialState({
    isFetching: false,
    queries: {},
  }),

  extraReducers: (builder) => {
    // fetchAccounts
    builder.addCase(uncancelShipmentById.fulfilled, (state, action) => {
      shipmentAdapter.upsertOne(state, action.payload)
    })

    builder.addCase(xnCloneShipment.fulfilled, (state, action) => {
      shipmentAdapter.upsertOne(state, action.payload)
      return state
    })
    // fetchShipmentById handlers
    builder.addCase(fetchShipmentById.pending, (state) => {
      return state
    })
    builder.addCase(fetchShipmentById.fulfilled, (state, action) => {
      shipmentAdapter.upsertOne(state, action.payload)
      return state
    })
    builder.addCase(fetchShipmentById.rejected, (state, action) => {
      return state
    })

    // listShipments handlers
    builder.addCase(listShipments.pending, (state, action) => {
      const apiArgs = {...action.meta.arg}

      delete apiArgs.pageNumber
      delete apiArgs.skip
      delete apiArgs.limit

      const key = JSON.stringify(apiArgs, Object.keys(apiArgs).sort())

      state.queries = {
        ...state.queries,
        [key]: {
          ...state.queries[key],
          isFetching: true,
          ids: state.queries[key]?.ids || [],
        },
      }

      return state
    })
    builder.addCase(listShipments.fulfilled, (state, action) => {
      shipmentAdapter.upsertMany(state, action.payload.entities)

      const apiArgs = {...action.meta.arg}

      delete apiArgs.pageNumber
      delete apiArgs.skip
      delete apiArgs.limit

      const key = JSON.stringify(apiArgs, Object.keys(apiArgs).sort())

      const {total} = action.payload

      const ids =
        state.queries[key].ids.length !== total
          ? Array.from(new Array(total))
          : [...state.queries[key].ids]

      for (let i = 0; i < action.payload.entities.length; i += 1) {
        ids[i + action.payload.skip] = action.payload.entities[i].id
      }

      state.queries = {
        ...state.queries,
        [key]: {
          ...state.queries[key],
          isFetching: false,
          total: action.payload.total,
          ids,
        },
      }

      return state
    })
    builder.addCase(listShipments.rejected, (state, action) => {
      const apiArgs = {...action.meta.arg}

      delete apiArgs.pageNumber
      delete apiArgs.skip
      delete apiArgs.limit

      const key = JSON.stringify(apiArgs, Object.keys(apiArgs).sort())

      state.queries = {
        ...state.queries,
        [key]: {
          ...state.queries[key],
          isFetching: false,
          fetchError: {...action.payload},
        },
      }

      return state
    })
  },
})

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

export const {clearShipmentFetchError} = shipmentSlice.actions

///////////////////////////////////////////////////////////////////////////////
// Selectors
///////////////////////////////////////////////////////////////////////////////

export const selectShipmentSlice = (state) => state.shipment

export const {
  selectById,
  selectIds: selectShipmentIds,
  selectEntities,
  selectAll: selectAllShipments,
  selectTotal: selectTotalShipments,
} = shipmentAdapter.getSelectors(selectShipmentSlice)

export const selectShipmentById = createSelector(
  selectShipmentSlice,
  selectById,
  ({entities, ids, ...slice}, shipment) => {
    return {
      ...slice,
      shipment,
    }
  },
)

export const selectShipments = createSelector(
  selectShipmentSlice,
  (state, options) => {
    return options
  },
  ({queries, entities, ...slice}, args) => {
    const query = {...args}
    delete query.pageNumber
    delete query.skip
    delete query.limit
    const queryKey = JSON.stringify(query, Object.keys(query).sort())

    const queryResult = queries?.[queryKey]
      ? {
          ...queries?.[queryKey],
          entities: queries?.[queryKey].ids.map((id) => entities[id]),
        }
      : {pages: {}, ids: [], entities: []}

    return queryResult
  },
)

export default shipmentSlice.reducer
