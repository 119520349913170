import * as React from 'react'
import {Timestamp, Modal} from '@quotefactory/ui'
import {AccountIntegrationForm} from 'components/account'
import {capitalize} from 'helpers'

const statusColor = (status) =>
  ({
    none: 'text-orange-500 border-orange-500',
    connected: 'text-green-500 border-green-500',
    disconnected: 'text-red-500 border-red-500',
  })[status] || 'text-gray-500 border-gray-500'

const activeColor = (active) =>
  ({
    false: 'bg-orange-500',
    true: 'bg-green-500',
  })[active] || 'bg-gray-500'

export default function AccountIntegrationListItem({account, refreshData}) {
  const {createdAt, code, connectionState, active, integrationType} = account
  const [showModal, setShowModal] = React.useState(false)

  if (!account) return null

  const onSave = () => {
    refreshData()
    setShowModal(false)
  }

  const onClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <button
        type="button"
        // eslint-disable-next-line no-alert
        onClick={() => setShowModal(!showModal)}
        className="flex row text-left text-14 text-black-500 hover:bg-gray-200 bg-white border border-gray-400 rounded-md">
        <div className="w-5 mr-0.5 flex items-center justify-end self-stretch">
          <div className={`w-2 h-2 rounded-full ${activeColor(active)}`} />
        </div>
        <div className="w-1/5 truncate font-bold">
          <div className="flex items-center self-stretch">
            <div className="ml-2">{code}</div>
          </div>
        </div>
        <div className="w-1/6 whitespace-nowrap text-13">
          <Timestamp timestamp={createdAt} displayFormat="distance" />
        </div>
        <div className="w-1/6 text-13 flex items-center self-stretch">
          <div
            className={`border px-1 rounded font-bold ${statusColor(
              connectionState,
            )}`}>
            {capitalize(connectionState)}
          </div>
        </div>
        <div className="flex-1 text-13 text-right pr-4">
          <div>{capitalize(integrationType)}</div>
        </div>
      </button>

      <Modal isOpen={showModal} onClose={onClose}>
        <Modal.Content>
          <AccountIntegrationForm
            item={account}
            onSave={onSave}
            onClose={onClose}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}
