import React from 'react'
import {useDispatch} from 'react-redux'
import {Link} from '@reach/router'
import {Toggle} from '@quotefactory/ui'
import {Layout} from 'pages'
import PaceChart, {getBounds, sortByVolume} from 'components/account/PaceChart'
import {clearProviderIntegrations} from 'reducers/providerIntegrations'
import {useGetPaceQuery} from 'services/rtk-api'
import DashboardSelect from '../components/DashboardSelect'

export default function Dashboard() {
  const reduxDispatch = useDispatch()

  const [relativeScale, setRelativeScale] = React.useState(false)
  const [sort, setSort] = React.useState('chrono')

  // Fetch pace data
  const {data: allPaceData = []} = useGetPaceQuery()

  const sortedPaceData = React.useMemo(() => {
    if (!allPaceData?.[0]) {
      return allPaceData
    }
    if (sort === 'volume') {
      return sortByVolume([...allPaceData])
    }
    return allPaceData
  }, [allPaceData, sort])

  const bounds = React.useMemo(
    () => (relativeScale ? getBounds(allPaceData) : undefined),
    [relativeScale],
  )

  const resetAccountData = () => {
    reduxDispatch(clearProviderIntegrations())
  }

  return (
    <Layout>
      <div className="flex items-center py-2 px-4 xl:px-4 justify-between my-6">
        <div className="flex items-center gap-2">
          <h2>Dashboard</h2>
          <DashboardSelect />
        </div>
        <div className="flex items-center space-x-6">
          <div className="flex items-center justify-between">
            <Toggle
              id="sort"
              name="sort"
              checked={sort === 'volume'}
              onChange={() =>
                setSort((prev) => (prev === 'volume' ? 'chrono' : 'volume'))
              }
            />
            <div className="ml-2">Sort by count</div>
          </div>
          <div className="flex items-center justify-between">
            <Toggle
              id="relative"
              name="relative"
              checked={relativeScale}
              onChange={() => setRelativeScale(!relativeScale)}
            />
            <div className="ml-2">Relative scale</div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap">
        {/* Combine all broker counts for an overall pace chart */}
        <div className="p-4 w-72">
          <div className="ml-4 text-15">Quote Factory combined</div>
          <PaceChart />
        </div>

        {/* Show a pace chart for each broker */}
        {sortedPaceData.map((paceData) => (
          <div className="p-4 w-72">
            <div className="ml-4 text-13">
              <Link
                to={`/account/${paceData.brokerId}`}
                onClick={resetAccountData}
                className="ml-4 text-13 hover:text-blue-500">
                {paceData.Broker}
              </Link>
            </div>
            <PaceChart accountId={paceData.brokerId} bounds={bounds} />
          </div>
        ))}
      </div>
    </Layout>
  )
}
