import * as React from 'react'
import {Card, SVG, twMerge, createFormatterFn} from '@quotefactory/ui'
import {useGetBrokerXnCreditUsageQuery} from 'services/rtk-api'

/**
 * 99% copied from qf-app CreditLimitChart.js
 */

export default function CreditLimitChart({accountId, size, className}) {
  const {data: usageData, isLoading} = useGetBrokerXnCreditUsageQuery(
    {
      id: accountId,
    },
    {skip: !accountId},
  )

  if (!usageData) {
    return null
  }

  if (!accountId || isLoading) {
    return <LoadingView size={size} className={className} />
  }

  return <DefaultView usageData={usageData} />
}

/**
 * @typedef LoadingProps
 * @prop {Variant} size
 * @prop {string} [className]
 *
 * @param {LoadingProps} props
 */
function LoadingView({size, className}) {
  const mini = size === 'mini'
  const chartOnly = size === 'chart-only'

  return (
    <Card
      className={twMerge(
        'bg-transparent',
        size === 'default' && 'border border-gray-400 p-3',
        size === 'chart-only' && 'min-w-[100px]',
        className,
      )}>
      {!chartOnly && <div className="invisible h-[19px]" aria-hidden />}
      <div
        className={`invisible ${!mini && !chartOnly && 'h-5 mt-2'} ${
          mini ? 'h-2 mt-1' : ''
        } ${chartOnly ? 'h-3 mt-1' : ''}`}
        aria-hidden
      />
    </Card>
  )
}

/**
 * @typedef DefaultViewProps
 * @prop {QF.CreditUsageResult} usageData
 *
 * @param {DefaultViewProps} props
 */
function DefaultView({usageData}) {
  const {availableCredit} = usageData
  const over = availableCredit < 0
  const close = isCloseToLimit(usageData)

  return (
    <Card
      className={`bg-transparent border border-gray-400 ${over ? 'over' : 'left'}`}
      data-size="default">
      <div className="p-3">
        <div className="flex justify-between gap-2 mb-2">
          <div className="text-12 text-black-100 font-semibold flex items-center gap-1">
            <SVG name="xn" /> Credit {formatAsUSD(usageData.creditLimit)}
          </div>
          <div className="flex items-center gap-0">
            <div className="text-13">
              {formatAsUSD(Math.abs(availableCredit ?? 0))}{' '}
              {over ? 'over' : 'left'}
            </div>
            {(over || close) && (
              <SVG
                name="warning"
                className={`"w-5 h-5 -my-2  ${over ? 'text-red-500' : 'text-orange-500'}`}
              />
            )}
          </div>
        </div>
        <Chart usageData={usageData} size="default" height="h-3" />
      </div>
    </Card>
  )
}

/**
 * @typedef ChartProps
 * @prop {string} [height]
 * @prop {string} [width]
 * @prop {QF.CreditUsageResult} usageData
 * @prop {Variant} size
 *
 * @param {ChartProps} props
 */
export function Chart({usageData, size, height}) {
  const {
    creditLimit,
    availableCredit,
    unpaidShipmentBalance: creditUsed,
    onScheduleInvoiceShipmentBalance: invoicedAmount,
    overdueInvoiceShipmentBalance: overdueAmount,
    uninvoicedShipmentBalance: nonInvoicedAmount,
  } = usageData

  const close = isCloseToLimit(usageData)
  const over = availableCredit < 0

  const base = creditLimit <= 1 ? Math.abs(creditUsed) : creditLimit

  const percents = React.useMemo(
    () => ({
      creditUsed: creditLimit ? Math.round(creditUsed / creditLimit) * 100 : 0,
      nonInvoicedAmount: nonInvoicedAmount
        ? Math.round((nonInvoicedAmount / base) * 100)
        : 0,
      invoicedAmount: invoicedAmount
        ? Math.round((invoicedAmount / base) * 100)
        : 0,
      overdueAmount: overdueAmount
        ? Math.round((overdueAmount / base) * 100)
        : 0,
      availableCredit: availableCredit
        ? Math.round((availableCredit / base) * 100)
        : 0,
    }),
    [
      creditLimit,
      creditUsed,
      nonInvoicedAmount,
      invoicedAmount,
      overdueAmount,
      availableCredit,
    ],
  )

  const chartOnly = size === 'chart-only'

  return (
    <div
      className={twMerge(
        'flex items-stretch rounded-md [&:hover>div]:opacity-30 min-w-[100px] relative overflow-hidden hover:overflow-visible',
        height || 'h-1',
        over && !chartOnly
          ? 'before:absolute before:inset-0 before:rounded-md before:z-10 before:bg-red-500 group-hover/open:before:hidden [&>div]:rounded-md'
          : '[&>div:first-child]:rounded-l-md [&>div:last-child]:rounded-r-md',
      )}>
      <ChartItem
        width={`${percents.nonInvoicedAmount}%`}
        close={close && !chartOnly}
        className={
          chartOnly ? 'bg-black-200' : 'group-hover/open:bg-black-200'
        }>
        Not invoiced {formatAsUSD(nonInvoicedAmount)}
      </ChartItem>
      <ChartItem
        width={`${percents.invoicedAmount}%`}
        close={close && !chartOnly}
        className={
          chartOnly ? 'bg-black-600' : 'group-hover/open:bg-black-600'
        }>
        Current {formatAsUSD(invoicedAmount)}
      </ChartItem>
      <ChartItem
        width={`${percents.overdueAmount}%`}
        close={close && !chartOnly}
        className={chartOnly ? 'bg-red-400' : 'group-hover/open:bg-red-400'}>
        Overdue {formatAsUSD(overdueAmount)}
      </ChartItem>

      {(availableCredit > 0 ||
        (availableCredit === 0 && creditLimit === 0)) && (
        <ChartItem className="flex-1 bg-gray-400/70">
          Remaining {formatAsUSD(availableCredit)}
        </ChartItem>
      )}
    </div>
  )
}

/**
 * @typedef ChartItemProps
 * @prop {React.ReactNode} children
 * @prop {string} [className]
 * @prop {string} [width]
 * @prop {boolean} [close]
 *
 * @param {ChartItemProps} props
 */
function ChartItem({children, className, width, close}) {
  return (
    <div
      style={{width}}
      className={twMerge(
        'bg-black-100 hover:!opacity-100 hover:!shadow-[inset_0_0_0_2px_#00000010] transition-all flex items-end first:justify-start justify-center last:justify-end cursor-pointer relative group',
        className,
        close && 'bg-orange-500',
        width === '0%' && '!hidden !rounded-xl !bg-green-500',
      )}>
      <div className="absolute -bottom-6 whitespace-nowrap px-1 py-0.5 rounded-sm shadow-sm bg-black-700 text-white text-11 z-10 transition-opacity delay-100 opacity-0 group-hover:opacity-100 pointer-events-none">
        {children}
      </div>
    </div>
  )
}

const formatAsUSD = createFormatterFn({
  locale: 'en-US',
  currency: 'USD',
  decimalPlaces: 0,
})

/**
 * Determines if the current usage is close to the limit.
 *
 * @param {QF.CreditUsageResult} usageData
 */
function isCloseToLimit(usageData) {
  const {unpaidShipmentBalance: creditUsed, creditLimit} = usageData

  /* Within 10% of the limit (90% used) */
  return creditUsed / creditLimit >= 0.9
}
