import React from 'react'
import {FilterPill, FilterSection} from 'components/filters'

/**
 * Filter for test shipments
 */
export default function FilterCoverageEligible({
  onFilterChange,
  value,
  isDesktop,
}) {
  const filterValue = value

  const UiComponent = isDesktop ? FilterPill : FilterSection

  /* An individual filter option */
  const FilterItem = ({thisValue, children}) => {
    const selected = thisValue === filterValue
    return (
      <li className={`item ${selected ? 'active' : ''}`}>
        <button
          type="button"
          onClick={() => {
            const next = {autoCover: thisValue}

            if (thisValue === 'only') {
              next.status = 'booked'
            }

            onFilterChange(next)
          }}>
          {children}
        </button>
      </li>
    )
  }

  return (
    <UiComponent
      title={<div>Auto coverable</div>}
      label={<div className="flex items-center">{filterValue} coverable</div>}
      active={filterValue === 'only'}
      clearFilter={() => {
        onFilterChange({autoCover: 'include'})
      }}>
      <ul className="menu-list -mx-2 w-64">
        <FilterItem thisValue="include">
          <div className="flex items-center">include</div>
        </FilterItem>
        <FilterItem thisValue="only">
          <div className="flex items-center">only</div>
        </FilterItem>
      </ul>
    </UiComponent>
  )
}
