import * as React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Button} from '@quotefactory/ui'
import {fetchAccounts, selectAccounts} from 'reducers/account'
import {FilterSelect} from 'components/filters'

export default function MacroPointCarriersReport({onRun, isRunning}) {
  const [formValues, setFormValues] = React.useState(() => {
    return {
      brokerId: '',
    }
  })

  const reduxDispatch = useDispatch()
  const {loadedAt} = useSelector((state) => state.account)
  const brokers = useSelector(selectAccounts) || []

  React.useEffect(() => {
    if (!loadedAt) {
      reduxDispatch(fetchAccounts())
    }
  }, [])

  // If possible, limit the list of options to distinct values that exist in our data
  const options = brokers.map((item) => ({
    value: item.id,
    label: item.name,
    img: {url: item.logoUrl, name: item.name},
  }))

  const onSelectBroker = (brokerId) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      brokerId,
    }))
  }

  const onSubmit = () => {
    onRun({
      reportName: 'MacroPointCarriersReport',
      runAsync: false,
      params: {brokerId: formValues.brokerId},
    })
  }

  return (
    <div>
      <h2>Carriers List</h2>

      <div className="pt-4">
        <div className="mt-2">
          <FilterSelect
            entity="broker"
            placeholder="select a broker"
            options={options}
            selectedValue={formValues.brokerId}
            width={300}
            onSelect={onSelectBroker}
          />
        </div>
        <Button
          className="mt-4 !px-10"
          loading={isRunning}
          onClick={onSubmit}
          disabled={!formValues.brokerId}>
          Run
        </Button>
      </div>
    </div>
  )
}
