import * as React from 'react'

export default function useKeyListener(
  callback,
  keys = ['*'],
  preventDefault = false,
) {
  React.useEffect(() => {
    const onKeyDown = (event) => {
      const wasAnyKeyPressed =
        keys[0] === '*' || keys.some((key) => event.key === key)
      if (wasAnyKeyPressed) {
        const modifiers = []
        if (event.altKey) {
          modifiers.push('alt')
        }
        if (event.ctrlKey) {
          modifiers.push('ctrl')
        }
        if (event.shiftKey) {
          modifiers.push('shift')
        }
        if (preventDefault) {
          event.preventDefault()
        }
        callback(event.key, modifiers)
      }
    }

    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])
}
