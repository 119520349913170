import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'
import api from '../services/api'

///////////////////////////////////////////////////////////////////////////////
// Async middleware
///////////////////////////////////////////////////////////////////////////////

export const fetchProviders = createAsyncThunk(
  'providers/fetchAll',
  async () => {
    const result = await api.fetchProviders()
    return result
  },
)

///////////////////////////////////////////////////////////////////////////////
// Slice
///////////////////////////////////////////////////////////////////////////////

export const entityAdapter = createEntityAdapter()
const initialState = entityAdapter.getInitialState({
  isLoading: false,
  loadedAt: null,
})

const slice = createSlice({
  name: 'providers',
  initialState,

  // reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchProviders.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchProviders.fulfilled, (state, action) => {
      state.isLoading = false
      state.loadedAt = new Date().getTime()
      if (!action.payload.error) {
        entityAdapter.upsertMany(state, action.payload.entities)
      }
    })
    builder.addCase(fetchProviders.rejected, (state, action) => {
      state.isLoading = false
      console.log(action.error)
    })
  },
})

///////////////////////////////////////////////////////////////////////////////
// Selectors
///////////////////////////////////////////////////////////////////////////////
export const {selectById: selectProviderById, selectAll: selectAllProviders} =
  entityAdapter.getSelectors((state) => state.providers)

export const selectIntegratedProviders = createSelector(
  [selectAllProviders],
  (entities) => {
    /*     const providers = entities.filter(
      (item) => item.integrations.ltlQuoting === 'p44',
    )
 */ const providers = entities
    return providers.sort((a, b) =>
      a.friendlyName.localeCompare(b.friendlyName),
    )
  },
)

export default slice.reducer
