import * as React from 'react'
import {Chart} from 'react-google-charts'
import {format} from 'date-fns'
import {useGetPaceQuery} from 'services/rtk-api'

// Google Chart options
const getChartOptions = (bounds) => {
  const options = {
    legend: 'none',
    backgroundColor: {fill: 'transparent'},
    chartArea: {
      left: 32,
      top: 20,
      width: '90%',
      height: '70%',
    },
    hAxis: {
      textStyle: {color: '#909eb2'},
      slantedText: true,
      slantedTextAngle: 78,
    },
    vAxis: {
      baselineColor: '#d8dfeb',
      gridlines: {count: 6, color: '#e4ebf5'},
      format: '#,###',
    },
    series: {
      // This color becomes the color of the "certainty" (dashed) line
      // We then override the other segment colors in the row data
      0: {pointSize: 6, color: '#909eb2'},
    },
    animation: {
      startup: true,
      duration: 1000,
      easing: 'out',
    },
  }

  if (bounds) {
    options.vAxis.viewWindow = {
      min: bounds.min,
      max: bounds.max * 1.25,
    }
  }

  return options
}

// Define what each of the 3 columns in the data rows are about
const chartColumns = [
  {id: '', label: 'Month', pattern: '', type: 'string'},
  {id: '', label: 'Shipments', pattern: '', type: 'number'},
  // When certainty is false in the row, we get our dotted line
  {id: '', label: '', pattern: '', type: 'boolean', p: {role: 'certainty'}},
  {id: '', label: '', pattern: '', type: 'string', p: {role: 'style'}},
]

// Sum up all the broker counts to create an overall pace
const sumOverallPace = (allPaceData) => {
  if (!allPaceData?.[0]) return undefined
  const initialMonths = {}
  const monthKeys = allPaceData[0].months.map((month) => month.key)
  for (const month of allPaceData[0].months) {
    initialMonths[month.key] = month.count
  }
  const pace = allPaceData.slice(1).reduce(
    (acc, broker) => {
      for (const month of broker.months) {
        acc.months[month.key] += month.count
      }
      return acc
    },
    {Broker: 'Overall', months: initialMonths},
  )

  // Convert months object into an array
  const sortedMonths = []
  for (const monthKey of monthKeys) {
    sortedMonths.push({key: monthKey, count: pace.months[monthKey]})
  }

  pace.months = sortedMonths

  return pace
}

// Find min & max count across all brokers
export const getBounds = (allPaceData) =>
  allPaceData.reduce(
    (acc, broker) => {
      const counts = broker.months.map((month) => month.count)
      counts.push(acc.min)
      counts.push(acc.max)
      return {
        min: Math.min(...counts),
        max: Math.max(...counts),
      }
    },
    {min: 0, max: 0},
  )

// Sort brokers from most volume in prior month to least
export const sortByVolume = (allPaceData) => {
  if (!allPaceData?.[0]) return undefined
  const priorMonthIdx = 1
  allPaceData.sort((a, b) => {
    if (a.months[priorMonthIdx].count < b.months[priorMonthIdx].count) return 1
    if (a.months[priorMonthIdx].count > b.months[priorMonthIdx].count) return -1
    if (a.months[0].count < b.months[0].count) return 1
    if (a.months[0].count > b.months[0].count) return -1
    return 0
  })
  return allPaceData
}

export default function PaceChart({accountId, bounds}) {
  // Fetch pace data
  const {data: allPaceData, isLoading} = useGetPaceQuery()
  const paceData = React.useMemo(() => {
    if (!allPaceData) {
      return {}
    }

    if (!accountId) {
      return sumOverallPace(allPaceData)
    }

    // Find this broker's pace data in the list
    return allPaceData.find((p) => p.brokerId === accountId) || {}
  }, [accountId, allPaceData])

  // Construct chart data for Google line chart
  const chartRows = React.useMemo(() => {
    if (!paceData?.months) return

    // Reverse the order of the months--chrono order
    const months = [...(paceData?.months || [])]?.reverse()
    const rows = []
    for (const month of months.slice(0, months.length - 1)) {
      rows.push({
        c: [
          {v: format(new Date(`${month.key}-02`), 'MMM'), f: null},
          {v: month.count, f: null},
          {v: true, f: null},
          // Override the line color
          {v: 'color: #4388f7;', f: null},
        ],
      })
    }
    const month = months.slice(-1)[0]
    rows.push({
      c: [
        {v: format(new Date(`${month.key}-02`), 'MMM'), f: null},
        {v: month.count, f: null},
        {v: false, f: null}, // false "certainty" means we get a dashed line
        // Use the base line color for the dashed line.
        {v: null, f: null},
      ],
    })

    return rows
  }, [paceData])

  const chartData = {
    cols: chartColumns,
    rows: chartRows,
    p: null,
  }

  // If we got nothing, do nothing.
  if (!chartData || isLoading) {
    return null
  }

  // Render the Google charrt
  return (
    <div className="[&_svg]:w-full">
      <Chart
        chartType="LineChart"
        width="100%"
        height="180px"
        data={chartData}
        options={getChartOptions(bounds)}
        columns={chartColumns}
      />
    </div>
  )
}
