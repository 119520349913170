import React, {useState} from 'react'
import {Modal, SVG, Timestamp} from '@quotefactory/ui'
import {ProviderIntegrationForm, ProviderLogo} from 'components'
import {capitalize} from 'helpers'

const statusColor = (status) =>
  ({
    activating: 'text-yellow-500',
    activated: 'text-green-500',
    error: 'text-red-500',
  })[status] || 'text-gray-500'

export default function ProviderIntegrationListItem({
  integration,
  refreshData,
}) {
  const {
    provider,
    createdAt,
    accountNumber,
    status,
    blanket,
    quoteRequestsCount,
    shipmentsCount,
    extendedNetwork,
  } = integration
  const [showModal, setShowModal] = useState(false)

  if (!integration) return null

  const onSave = () => {
    refreshData()
    setShowModal(false)
  }

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(!showModal)}
        className="flex row items-center text-left text-sm text-black-500 hover:bg-gray-200">
        <div className="w-1/3 truncate font-bold">
          <div className="flex items-center">
            <ProviderLogo
              code={provider.code}
              name={provider.friendlyName}
              className="w-20"
            />
            <div className="ml-2">{provider.friendlyName}</div>
          </div>
        </div>
        <div
          className={`w-1/12 text-xs mx-2 px-1 rounded-sm text-center ${
            blanket ? 'border border-gray-700' : ''
          }`}>
          {accountNumber}
        </div>

        <div className="w-1/6 whitespace-nowrap text-xs">
          <Timestamp timestamp={createdAt} displayFormat="distance" />
        </div>
        <div className="w-1/6 text-xs">
          <span className={`${statusColor(status)}`}>
            {'\u2022'}&nbsp;{capitalize(status)}
          </span>
        </div>
        <div className="w-1/6 text-xs">
          {extendedNetwork === 'true' && (
            <span className="extendedNetwork">
              <SVG name="xn" className="w-5 h-5 -ml-0.5 -mr-1" />
            </span>
          )}
        </div>
        <div className="w-1/12 text-xs">{quoteRequestsCount}</div>
        <div className="w-1/12 text-xs">{shipmentsCount}</div>
      </button>

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content>
          <ProviderIntegrationForm item={integration} onSave={onSave} />
        </Modal.Content>
      </Modal>
    </>
  )
}
