import * as React from 'react'
import {format, subMonths, startOfMonth, endOfMonth} from 'date-fns'
import {Button} from '@quotefactory/ui'
import {QFDateRangePicker} from 'components/ui'

const dtFmt = (dt) => format(dt, 'yyyy-MM-dd')

const now = new Date()

export default function PRONumberAndTrackingReport({onRun, isRunning}) {
  const [dtRange, setDtRange] = React.useState(() => {
    const startDt = startOfMonth(subMonths(now, 1))
    const endDt = endOfMonth(startDt)
    return {
      start: startDt,
      end: endDt,
    }
  })

  const onSelectDateRange = (start, end) => {
    setDtRange({
      start,
      end,
    })
  }

  const onSubmit = () => {
    const dtStart = dtFmt(dtRange.start)
    const dtEnd = dtFmt(dtRange.end)
    onRun({
      reportName: 'PRONumberAndTrackingReport',
      runAsync: false,
      params: {dtStart, dtEnd},
    })
  }

  return (
    <div>
      <h2>PRO Number / Tracking Report</h2>

      <div className="pt-4">
        <div className="mt-2">
          <QFDateRangePicker
            start={dtRange.start}
            end={dtRange.end}
            onSelect={onSelectDateRange}
          />
        </div>
        <Button className="mt-4 !px-10" loading={isRunning} onClick={onSubmit}>
          Run
        </Button>
      </div>
    </div>
  )
}
