import React, {useState, useEffect} from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {SVG, ProviderLogo, Avatar} from 'components'
import MUIThemeProvider from 'components/ui/MUIThemeProvider'
import {themeOverrides} from 'components/ui/QFAutocomplete'

const entityImg = (entity, img) => {
  if (entity === 'provider') {
    return <ProviderLogo code={img} />
  }
  if (entity === 'broker') {
    return <Avatar url={img.url} title={img.name} size={2} square />
  }
  return img
}

/* A generic Autocomplete select list for use in filters */
export default function FilterSelect(props) {
  const {
    entity,
    placeholder,
    options,
    selectedValue,
    onSelect,
    width = 200,
  } = props

  const [filterNode, setFilterNode] = useState()

  const [inputValue, setInputValue] = useState(
    options.find((o) => o.value === selectedValue)?.label || '',
  )

  // Autofocus the input
  useEffect(() => {
    if (filterNode) {
      filterNode.querySelector('input').focus()
    }
  }, [filterNode])

  const onInputChange = (e, val) => {
    setInputValue(val)
  }

  const onChange = (evt, value) => {
    if (value) {
      onSelect(value.value)
    }
  }

  return (
    <div ref={(newRef) => setFilterNode(newRef)} className="field">
      <MUIThemeProvider overrides={themeOverrides}>
        <Autocomplete
          style={{width}}
          options={options}
          // classes={{ option: classes.option, }}
          value={options.find((o) => o.value === selectedValue) || null}
          inputValue={inputValue}
          onChange={onChange}
          onInputChange={onInputChange}
          disablePortal
          autoHighlight
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          // Don't want Up,Down,Home,End to also scroll the react-window behind this component
          onKeyDown={(event) => {
            event.stopPropagation()
            event.defaultMuiPrevented = false
          }}
          getOptionLabel={(option) => option.label || ''}
          renderOption={(option) => (
            <>
              {option.img && (
                <span className="w-12 mr-2">
                  {entityImg(entity, option.img)}
                </span>
              )}
              {option.label}
            </>
          )}
          renderInput={(params) => (
            <div ref={params.InputProps.ref} className="select relative">
              <input
                style={{width: '100%'}}
                type="text"
                placeholder={placeholder}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params.inputProps}
                className={`${params.inputProps.className} input`}
                // disable autocomplete and autofill
                autoComplete="new-password"
              />
              <div className="drop-arrow absolute inset-y-0 right-0 pr-1 flex items-center pointer-events-none text-gray-500">
                <SVG name="arrowDown" className="transform" />
              </div>
            </div>
          )}
        />
      </MUIThemeProvider>
    </div>
  )
}
