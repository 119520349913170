import React, {useRef, useState, useEffect} from 'react'
import {
  format,
  parseISO,
  startOfDay,
  endOfDay,
  addDays,
  startOfWeek,
} from 'date-fns'
import {QFDateRangePicker} from 'components/ui'
import {FilterPill, FilterSection} from 'components/filters'

const DATE_FMT = 'yyyy-MM-dd'

const getRangeFromPreset = (preset, dayCache) => {
  const day = dayCache || parseISO(format(new Date(), 'yyyy-MM-dd'))
  let startDay
  let endDay
  if (preset === 'today') {
    startDay = day
    endDay = startDay
  }
  if (preset === 'tomorrow') {
    startDay = addDays(day, 1)
    endDay = startDay
  }
  if (preset === 'yesterday') {
    startDay = addDays(day, -1)
    endDay = startDay
  }
  if (preset === 'this week') {
    startDay = startOfWeek(day, {weekStartsOn: 0})
    endDay = addDays(startDay, 6)
  }
  if (preset === 'next week') {
    startDay = startOfWeek(addDays(day, 6), {weekStartsOn: 0})
    endDay = addDays(startDay, 6)
  }
  if (preset === 'last week') {
    startDay = startOfWeek(addDays(day, -6), {weekStartsOn: 0})
    endDay = addDays(startDay, 6)
  }

  return {begin: startOfDay(startDay), end: endOfDay(endDay)}
}

const buildPresets = () => {
  const day = parseISO(format(new Date(), 'yyyy-MM-dd'))
  return [
    {label: 'Today', ...getRangeFromPreset('today', day)},
    {label: 'Tomorrow', ...getRangeFromPreset('tomorrow', day)},
    {label: 'Yesterday', ...getRangeFromPreset('yesterday', day)},
    {label: 'This week', ...getRangeFromPreset('this week', day)},
    {label: 'Next week', ...getRangeFromPreset('next week', day)},
    {label: 'Last week', ...getRangeFromPreset('last week', day)},
  ]
}

/* An individual filter option */
const OptionItem = ({label, begin, end, selected, onClick}) => {
  return (
    <li className={`item ${selected ? 'active' : ''}`}>
      <button type="button" onClick={() => onClick(label, begin, end)}>
        {label}
      </button>
    </li>
  )
}

export default function FilterPickupDate({
  onFilterChange,
  pickupDate,
  isDesktop,
}) {
  const [pickupDateBegin, pickupDateEnd] = (pickupDate || '').split(',')
  const presets = useRef(buildPresets())
  const [preset, setPreset] = useState()
  const UiComponent = isDesktop ? FilterPill : FilterSection

  useEffect(() => {
    const presetMatch = presets.current.find(
      ({begin, end}) =>
        pickupDateBegin === format(begin, DATE_FMT) &&
        pickupDateEnd === format(end, DATE_FMT),
    )
    setPreset(presetMatch?.label)
  }, [pickupDateBegin, pickupDateEnd])

  const onSelectDateRange = (start, end) => {
    onFilterChange({
      pickupDate: `${format(start, DATE_FMT)},${format(end, DATE_FMT)}`,
    })
  }

  const onPreset = (label, begin, end) => {
    onSelectDateRange(begin, end)
    setPreset(label)
  }

  const clearFilter = () => {
    onFilterChange({pickupDate: null})
  }

  const getLabel = () => {
    if (preset) {
      return preset
    }
    if (pickupDateBegin && pickupDateEnd) {
      const begin = parseISO(pickupDateBegin)
      const end = parseISO(pickupDateEnd)
      return `${format(begin, 'MM/dd')}-${format(end, 'MM/dd')}`
    }
    return 'Pickup date'
  }

  return (
    <UiComponent
      title="Pickup date"
      label={getLabel()}
      active={pickupDate}
      closeOnChange
      clearFilter={clearFilter}>
      <ul className="menu-list -mx-2 w-64">
        {presets.current.map(({label, begin, end}) => (
          <OptionItem
            key={label}
            label={label}
            begin={begin}
            end={end}
            selected={preset === label}
            onClick={onPreset}
          />
        ))}
      </ul>
      <div className="mt-2">
        <QFDateRangePicker
          start={pickupDateBegin ? parseISO(pickupDateBegin) : null}
          end={pickupDateEnd ? parseISO(pickupDateEnd) : null}
          onSelect={onSelectDateRange}
        />
      </div>
    </UiComponent>
  )
}
