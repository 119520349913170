import * as React from 'react'
import {Checkbox, IconWithCopy} from '@quotefactory/ui'
import {HiddenValue} from 'components'
import api from 'services/api'
import MacroPointForm from '../integrations/MacroPointForm'
import RmisForm from '../integrations/RmisForm'
import QuickBooksOnlineForm from '../integrations/QuickBooksOnlineForm'
import TruckerToolsForm from '../integrations/TruckerTools'
import DATForm from '../integrations/DATForm'
import MyCarrierPortalForm from '../integrations/MyCarrierPortalForm'
import DenimForm from '../integrations/DenimForm'

const KeyValRow = function ({label, value}) {
  const truncateString = (str, num) =>
    str.length > num ? `${str.slice(0, num)}...` : str

  return (
    <div className="flex items-center py-2">
      <div className="flex-1 text-right pr-2 whitespace-nowrap">{label}:</div>
      <div className="flex items-center flex-1">
        {/password|api|oauth|token/i.test(label) ? (
          <HiddenValue value={value} />
        ) : (
          truncateString(value, 15)
        )}
        <div className="ml-2">
          <IconWithCopy icon="copy" text={value} />
        </div>
      </div>
    </div>
  )
}

const DefaultAccountIntegrationForm = function ({
  code,
  connectionConfig,
  error,
  formValues,
  onClose,
  onToggle,
}) {
  const connectionDetailRows = React.useMemo(
    () =>
      connectionConfig?.map((config) => (
        <KeyValRow
          key={config.id}
          label={config.displayName}
          value={config.value ?? ''}
        />
      )),
    [connectionConfig],
  )

  return (
    <div className="p-8">
      <div className="pt-8">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="flex flex-col">
            <div className="flex justify-between border-b border-gray-200 py-2">
              <KeyValRow label="Code" value={code} />
            </div>
            {connectionDetailRows}
          </div>

          <div className="flex justify-center my-3">
            <label className="flex disabled">
              <Checkbox
                name="active"
                checked={formValues.active}
                onChange={onToggle}
              />
              <span className="ml-2">Active</span>
            </label>
          </div>

          {error && <div className="p-2 text-red-600 rounded-md">{error}</div>}

          <div className="mt-8 flex justify-center">
            <button
              type="button"
              className="btn blue shadowed w-full"
              onClick={onClose}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const IntegrationForm = function ({code, formProps}) {
  switch (code) {
    case 'macropoint':
      return <MacroPointForm {...formProps} />
    case 'rmis':
      return <RmisForm {...formProps} />
    case 'quickbooks-online':
      return <QuickBooksOnlineForm {...formProps} />
    case 'trucker-tools':
      return <TruckerToolsForm {...formProps} />
    case 'dat':
      return <DATForm {...formProps} />
    case 'my-carrier-portal':
      return <MyCarrierPortalForm {...formProps} />
    case 'denim':
      return <DenimForm {...formProps} />
    // case 'haulpay': return <HaulpayForm/>
    // case 'revenova': return <RevenovaForm/>
    default:
      return <DefaultAccountIntegrationForm {...formProps} />
  }
}

export default function AccountIntegrationFormRoot({item, onSave, onClose}) {
  if (!item) return null

  return (
    <AccountIntegrationForm item={item} onSave={onSave} onClose={onClose} />
  )
}

const AccountIntegrationForm = function ({item, onSave, onClose}) {
  const {
    accountId,
    code,
    connectionConfig,
    id: accountIntegration,
    integrationId,
  } = item

  const [error, setError] = React.useState('')

  const [formValues, setFormValues] = React.useState({
    active: item.active || false,
  })

  const onToggle = ({target}) => {
    setFormValues((prev) => ({...prev, [target.name]: target.checked}))
  }

  const submitForm = async (updateValues) => {
    try {
      await api.updateAccountIntegration({
        id: accountIntegration,
        integrationId,
        active: formValues.active,
        accountId,
        ...updateValues,
      })
      if (onSave) onSave()
    } catch (err) {
      setError('Failed to update account integration')
    }
  }

  const formProps = {
    accountId,
    code,
    connectionConfig,
    error,
    formValues,
    KeyValRow,
    onClose,
    onToggle,
    setFormValues,
    submitForm,
  }

  return <IntegrationForm code={code} formProps={formProps} />
}
