const addressToString = (addr, entityName) => {
  if (!addr) return ''
  const {name, street = [], city, stateCode, postalCode} = addr
  const addrParts = []
  if (name && name !== entityName) {
    addrParts.push(name)
  }
  if (street.length) addrParts.push(street.join(' '))
  if (city) addrParts.push(city)
  if (stateCode || postalCode) addrParts.push(`${stateCode} ${postalCode}`)
  return addrParts.join(', ')
}

const addressToClipboardString = (addr) => {
  if (!addr) return ''
  const {name, street = [], city, stateCode, postalCode} = addr
  const addrLines = [name, ...street]
  if (city || stateCode || postalCode) {
    addrLines.push(`${city}, ${stateCode} ${postalCode}`)
  }
  return addrLines.filter((line) => !!line).join('\n')
}

export {addressToString, addressToClipboardString}
