import React from 'react'
import {UserAvatar} from 'components'
import {SVG, ClickToCopy, TelephoneLink, EmailLink} from '@quotefactory/ui'
import {useSetPrimaryUserMutation} from 'services/rtk-api'

export default function AccountUser({accountId, member, fetchTeam}) {
  const [setPrimaryUser] = useSetPrimaryUserMutation()

  if (!member) return null
  const {id, fullName, isOwner, isPrimary, username, phone} = member

  const setAsPrimary = async () => {
    await setPrimaryUser({accountId, userId: id})
    fetchTeam()
  }

  return (
    <div className="flex items-center gap-3">
      <UserAvatar user={member} size={3} />
      <div>
        <div className="flex items-center gap-1 text-15 font-semibold pb-0.5">
          {fullName}
          <ClickToCopy
            className="text-gray-900 hover:text-black-500"
            text={fullName}>
            <SVG name="copy" className="-mt-0.5 w-5 h-5" />
          </ClickToCopy>
          {isOwner && (
            <button
              onClick={setAsPrimary}
              type="button"
              aria-label="Set as primary user">
              <SVG
                name="star"
                className={`${isPrimary ? 'text-yellow-500' : 'text-gray-300'}`}
              />
            </button>
          )}
        </div>
        <div
          className={`inline-block text-white font-bold tracking-wide px-1 py-px text-11 rounded mr-1 ${
            isOwner ? 'bg-green-500' : 'bg-black-100'
          }`}>
          {isOwner ? 'Owner' : 'Member'}
        </div>
        <div className="flex items-center gap-1 mt-2">
          <EmailLink email={username}>{username}</EmailLink>
          <ClickToCopy
            className="text-gray-900 hover:text-black-500"
            text={username}>
            <SVG name="copy" className="-mt-0.5 w-5 h-5" />
          </ClickToCopy>
        </div>
        {phone && (
          <div className="flex items-center gap-1 mt-1">
            <TelephoneLink phone={phone} className="whitespace-nowrap" />
            <ClickToCopy
              className="text-gray-900 hover:text-black-500"
              text={phone}>
              <SVG name="copy" className="-mt-0.5 w-5 h-5" />
            </ClickToCopy>
          </div>
        )}
      </div>
    </div>
  )
}
