/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import * as React from 'react'

/**
 * @param {string} [code]
 */
const displayCountryCode = (code) => {
  switch (code) {
    case 'MEX':
      return 'MX'
    default:
      return ''
  }
}

export default function Address({address, showCompany = true, ...props}) {
  if (!address) {
    return null
  }
  const {
    company,
    street = [],
    city,
    stateCode,
    postalCode,
    countryCode,
  } = address
  return (
    <address {...props} className="not-italic">
      {company && showCompany && <div className="font-bold">{company}</div>}
      {street.map((streetLine, idx) => (
        <div key={idx}>{streetLine}</div>
      ))}
      <div>
        {city}
        {city && stateCode && ', '}
        {stateCode}
        {(city || stateCode) && postalCode && ' '}
        {postalCode} {displayCountryCode(countryCode)}
      </div>
    </address>
  )
}
