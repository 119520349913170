import * as React from 'react'
import {Toggle, IconWithCopy} from '@quotefactory/ui'
import yn from 'yn'

// connnectionConfig items to manage
const configToggleKeys = [
  'hasRateView',
  'useEmailContact',
  'pushRateViewContributions',
]

const DATForm = function ({
  formValues,
  setFormValues,
  submitForm,
  connectionConfig,
  code,
  error,
}) {
  React.useEffect(() => {
    // Merge in this integration's unique config params to the form state
    const configItemsState = {}
    for (const key of configToggleKeys) {
      configItemsState[key] = yn(
        connectionConfig.find((i) => i.key === key)?.value,
      )
    }
    setFormValues((prev) => ({
      ...prev,
      ...configItemsState,
    }))
  }, [connectionConfig])

  const handleToggleSetting = (settingName, value) => () => {
    setFormValues((prev) => ({...prev, [settingName]: value}))
  }

  const onSubmit = () => {
    const updateIntegration = {
      connectionConfig: [
        {key: 'hasRateView', value: !!formValues.hasRateView},
        {
          key: 'pushRateViewContributions',
          value: !!formValues.pushRateViewContributions,
        },
        {key: 'useEmailContact', value: !!formValues.useEmailContact},
      ],
    }
    submitForm(updateIntegration)
  }

  const getConfigVal = (key, len) => {
    const val = connectionConfig.find((i) => i.key === key)?.value || ''

    if (len) {
      return val.length > len ? `${val.slice(0, len)}...` : val
    }

    return val
  }

  return (
    <div className="p-8 w-[500px]">
      <div className="pt-2">
        <form>
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold">DAT Settings</h2>
            <hr className="flex-grow ml-4 border-t-2 border-gray-300" />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <span className="font-semibold">Org Username:</span>
              <div className="flex items-center">
                <span className="mr-1">{getConfigVal('orgUsername', 25)}</span>
                <div>
                  <IconWithCopy
                    className="w-4"
                    icon="copy"
                    text={getConfigVal('orgUsername')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <span className="font-semibold">Org Password:</span>
              <div className="flex items-center">
                <span className="mr-1">{getConfigVal('orgPassword', 25)}</span>
                <div>
                  <IconWithCopy
                    className="w-4"
                    icon="copy"
                    text={getConfigVal('orgPassword')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-stretch pt-8">
            <div className="text-17 font-bold pb-4">Options</div>
            <div className="flex flex-col px-4 gap-4">
              {configToggleKeys.map((key) => {
                const item = connectionConfig.find((i) => i.key === key)
                if (!item) return null
                return (
                  <div className="flex items-center justify-between">
                    <div>{item.displayName}</div>
                    <Toggle
                      id={key}
                      name={key}
                      checked={!!formValues[key]}
                      onChange={handleToggleSetting(key, !formValues[key])}
                    />
                  </div>
                )
              })}
            </div>
          </div>

          {error && <div className="p-2 text-red-600 rounded-md">{error}</div>}

          <div className="mt-8">
            <span className="block w-full">
              <button
                type="button"
                onClick={onSubmit}
                className="btn blue shadowed w-full">
                Update
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DATForm
