import * as React from 'react'
import {Layout} from 'pages'
import ProvidersTable from '../components/ProvidersTable'
import ProviderModal from '../components/ProviderModal'

export default function ProviderList() {
  const [provider, setProvider] = React.useState()
  const handleRowClick = React.useCallback((id) => {
    setProvider(id)
  }, [])
  const handleClose = React.useCallback(() => {
    setProvider(null)
  }, [])

  return (
    <>
      <Layout>
        <ProvidersTable onRowClick={handleRowClick} />
        <ProviderModal provider={provider} onClose={handleClose} />
      </Layout>
    </>
  )
}
