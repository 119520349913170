import * as React from 'react'
import {Checkbox, IconWithCopy} from '@quotefactory/ui'
import yn from 'yn'

const QuickbooksForm = function ({
  formValues,
  setFormValues,
  submitForm,
  connectionConfig,
  code,
  error,
}) {
  const [isExcludingFactored, setIsExcludingFactored] = React.useState(false)

  React.useEffect(() => {
    const isExcludingFactoredConfig =
      connectionConfig.find((i) => i.key === 'isExcludingFactored')?.value ||
      false
    setIsExcludingFactored(yn(isExcludingFactoredConfig))
  }, [connectionConfig])

  const isExcludingFactoredChange = ({target}) => {
    setIsExcludingFactored(target.checked)
    setFormValues((prev) => ({...prev, isExcludingFactored: target.checked}))
  }

  const onSubmit = () => {
    const updateIntegration = {
      connectionConfig: [
        {key: 'isExcludingFactored', value: isExcludingFactored},
      ],
    }
    submitForm(updateIntegration)
  }

  const getConfigVal = (key, len) => {
    const val = connectionConfig.find((i) => i.key === key)?.value || ''

    if (len) {
      return val.length > len ? `${val.slice(0, len)}...` : val
    }

    return val
  }

  return (
    <div className="p-8 w-[500px]">
      <div className="pt-2">
        <form>
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold">Quickbooks Online Settings</h2>
            <hr className="flex-grow ml-4 border-t-2 border-gray-300" />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <span className="font-semibold">Company Id:</span>
              <div className="flex items-center">
                <span className="mr-1">{getConfigVal('realmId', 25)}</span>
                <div>
                  <IconWithCopy
                    className="w-4"
                    icon="copy"
                    text={getConfigVal('realmId')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 my-3">
            <div className="flex justify-between items-center">
              <span className="font-semibold">Options:</span>
            </div>
          </div>

          <div className="flex justify-end my-3">
            <span className="ml-2 mr-2">Exclude factored shipments</span>
            <label className="flex">
              <Checkbox
                name="active"
                checked={isExcludingFactored}
                onChange={isExcludingFactoredChange}
              />
            </label>
          </div>

          {error && <div className="p-2 text-red-600 rounded-md">{error}</div>}

          <div className="mt-8">
            <span className="block w-full">
              <button
                type="button"
                onClick={onSubmit}
                className="btn blue shadowed w-full">
                Update
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}

export default QuickbooksForm
