import React, {useState, useEffect} from 'react'
import {format, differenceInDays} from 'date-fns'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import SVG from 'components/Svg'

const DATE_FMT = 'yyyy-MM-dd'

export default function QFDateRangePicker({start, end, onSelect}) {
  const [value, setValue] = useState(null)

  const onRangeSet = () => {
    try {
      if (differenceInDays(value[1], value[0]) > -1) {
        if (
          !start ||
          !end ||
          format(value[0], DATE_FMT) !== format(start, DATE_FMT) ||
          format(value[1], DATE_FMT) !== format(end, DATE_FMT)
        ) {
          onSelect(value[0], value[1])
        }
      }
    } catch (err) {
      // do nothing
    }
  }

  useEffect(() => {
    setValue(start && end ? [start, end] : null)
  }, [start, end])

  useEffect(() => {
    onRangeSet()
  }, [value])

  return (
    <div>
      <DateRangePicker
        onChange={(eventOptionOrRange) => {
          if (Array.isArray(eventOptionOrRange)) {
            const range = eventOptionOrRange
            setValue(range)
            return
          }

          if ('value' in eventOptionOrRange) {
            const option = eventOptionOrRange.value
            setValue(option)
            // eslint-disable-next-line no-useless-return
            return
          }

          // Intentionally ignoring direct change event
        }}
        value={value}
        calendarType="US"
        clearIcon={null}
        calendarIcon={<SVG name="calendar" />}
        rangeDivider="&mdash;"
        formatShortWeekday={(locale, date) => format(date, 'EEEEEE')}
        nextLabel={<SVG name="arrowRight" className="mx-auto" />}
        prevLabel={<SVG name="arrowLeft" className="mx-auto" />}
        next2Label={null}
        prev2Label={null}
      />
    </div>
  )
}
