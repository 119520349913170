import {combineReducers} from 'redux'
import user from './user'
import account from './account'
import providerIntegrations from './providerIntegrations'
import shipment from './shipment'
import providers from './providers'
import features from './features'

import qfAPI from '../services/rtk-api'

export default combineReducers({
  user,
  account,
  providerIntegrations,
  shipment,
  providers,
  features,
  [qfAPI.reducerPath]: qfAPI.reducer,
})
