import React from 'react'
import {Checkbox} from '@quotefactory/ui'
import {FilterPill, FilterSection} from 'components/filters'

const ITEMS = [
  {value: 'draft', label: 'Draft', color: 'gray'},
  {value: 'booked', label: 'Booked', color: 'orange'},
  {value: 'scheduled', label: 'Scheduled', color: 'yellow'},
  {value: 'in-transit', label: 'In transit', color: 'blue'},
  {value: 'delivered', label: 'Delivered', color: 'green'},
  {value: 'canceled', label: 'Canceled', color: 'dark-gray'},
]

/* An individual filter list-item checkbox */
const FilterStatusItem = function ({
  label,
  color,
  value,
  checked,
  disabled,
  onChange,
}) {
  return (
    <li className="my-2 field no-pad">
      <label className={`flex items-center ${disabled && 'disabled'}`}>
        <Checkbox
          checked={checked}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="ml-3 text-base text-black-600">{label}</span>
        <div className="ml-2 inline status">
          <span className={`circle ${color}`} />
        </div>
      </label>
    </li>
  )
}

/* The checkbox filter panel */
export default function FilterStatus({onFilterChange, items = '', isDesktop}) {
  const checkedItems = new Set(items.split(',').filter(Boolean))

  const UiComponent = isDesktop ? FilterPill : FilterSection

  const onChange = ({target}) => {
    if (target.checked) {
      checkedItems.add(target.value)
    } else {
      checkedItems.delete(target.value)
    }
    onFilterChange({status: [...checkedItems].join(',')})
  }

  const clearFilter = () => {
    onFilterChange({status: ''})
  }

  const buildLabel = () => {
    if (checkedItems.size > 0) {
      const {label} = ITEMS.find(
        (i) => i.value === checkedItems.values().next().value,
      )
      if (checkedItems.size === 1) {
        return `${label}`
      }
      return `${label} +${checkedItems.size - 1}`
    }
    return 'Status'
  }

  return (
    <UiComponent
      title="Shipment status"
      label={buildLabel()}
      active={checkedItems.size > 0}
      clearFilter={clearFilter}>
      <ul>
        {ITEMS.map(({value, label, color}) => (
          <FilterStatusItem
            key={value}
            label={label}
            color={color}
            value={value}
            checked={checkedItems.has(value)}
            onChange={onChange}
          />
        ))}
      </ul>
    </UiComponent>
  )
}
