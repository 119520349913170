import * as React from 'react'
import {
  SVG,
  Card,
  TelephoneLink,
  EmailLink,
  Timestamp,
  ExternalLink,
  CurrencyText,
} from '@quotefactory/ui'

import Address from './Address'
import {capitalize} from '../helpers/strings'

export function FMCSACard({provider, authorizedFor}) {
  const {
    orcusProvider,
    physicalAddress,
    mailingAddress,
    fmcsaPhone,
    fmcsaEmail,
    supportPhone,
    supportEmail,
  } = provider || {}

  const {fmcsaData = {}, saferData = {}} = orcusProvider || {}

  const {
    authority: authorities = [],
    dbaName,
    legalName,
    carrierOperationDesc,
    totalPowerUnits,
    totalDrivers,
    oosDate,
    safetyRating,
    safetyReviewDate,
    bipdInsuranceOnFile,
    cargoInsuranceOnFile,
    bondInsuranceOnFile,
    driverInsp,
    hazmatInsp,
    vehicleInsp,
    crashTotal,
  } = fmcsaData

  const {entityType} = saferData

  const authority = authorities.find(({prefix}) => prefix === 'MC')

  if (authority?.authorizedForHouseholdGoods)
    authorizedFor.push('Household goods')
  if (authority?.authorizedForPassenger) authorizedFor.push('Passenger')
  if (authority?.authorizedForProperty) authorizedFor.push('Property')

  const saferURL = `https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&query_string=${provider?.usdot}`
  const liURL = `https://li-public.fmcsa.dot.gov/LIVIEW/pkg_carrquery.prc_carrlist?n_dotno=${provider?.usdot}&s_prefix=MC&n_docketno=&s_legalname=&s_dbaname=&s_state=`

  return (
    <>
      <div className="px-6 py-4 md:px-10 flex lg:flex-row justify-between">
        <div className="flex flex-col gap-0.5">
          {legalName && !dbaName && (
            <div className="text-13 text-black-100">{legalName}</div>
          )}
          {dbaName && !legalName && (
            <div className="text-13 text-black-100">{dbaName}</div>
          )}
          {dbaName && legalName && (
            <div className="text-13 text-black-100">
              <span className="font-semibold">{legalName}</span> dba{' '}
              <span className="font-semibold">{dbaName}</span>
            </div>
          )}

          <div className="flex gap-4">
            {supportPhone && supportPhone !== fmcsaPhone && (
              <TelephoneLink
                phone={supportPhone}
                className="text-12 hover:text-blue-500"
              />
            )}
            {fmcsaPhone && (
              <TelephoneLink
                phone={fmcsaPhone}
                className="text-12 hover:text-blue-500"
              />
            )}
          </div>
          <div className="flex gap-4">
            {(supportEmail || fmcsaEmail) && (
              <EmailLink
                email={supportEmail || fmcsaEmail}
                className="text-12 hover:text-blue-500"
              />
            )}
          </div>
        </div>

        {(physicalAddress || mailingAddress) && (
          <div className="flex lg:flex-row gap-4">
            {physicalAddress && (
              <div className="text-13">
                <div className="text-10 text-black-100 font-bold uppercase pb-1">
                  HQ{' '}
                </div>
                <Address
                  address={physicalAddress}
                  showCompany={false}
                  className="text-13"
                />
              </div>
            )}

            {mailingAddress &&
              // eslint-disable-next-line no-use-before-define
              !checkAddressesMatch(mailingAddress, physicalAddress) && (
                <div className="text-13">
                  <div className="text-10 text-black-100 font-bold uppercase pb-1">
                    Mailing{' '}
                  </div>
                  <Address
                    address={mailingAddress}
                    showCompany={false}
                    className="text-13"
                  />
                </div>
              )}
          </div>
        )}
      </div>

      <div className="px-6 md:px-6 pt-6 pb-8 rounded-b-xl">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-1 flex flex-col gap-4 ">
            {!provider.isAuthorized || !provider?.isInService ? (
              <Card className="px-3 py-4 flex flex-col space-y-3 text-12 bg-red-100/50 border border-red-200">
                <div className="text-red-500 flex items-center space-x-1">
                  <SVG name="unacceptable" className="-ml-1" />
                  <span className="font-bold uppercase text-12">
                    {provider?.isInService
                      ? 'Not authorized'
                      : 'Out-of-service'}
                  </span>
                </div>

                {oosDate && (
                  <Timestamp timestamp={oosDate} displayFormat="yyyy-MM-dd" />
                )}
              </Card>
            ) : (
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1 flex flex-col gap-4 ">
                  <Card className="p-3 flex flex-col space-y-2.5 text-12 border border-green-200 bg-green-100/50">
                    {provider?.isAuthorized && (
                      <div className="flex items-center justify-between mb-0">
                        <div className="text-green-600 uppercase font-bold flex items-center space-x-1">
                          <SVG name="certified" className="-ml-1" />
                          <span>Authorized</span>
                        </div>
                      </div>
                    )}

                    <div>
                      {carrierOperationDesc && (
                        <span>{carrierOperationDesc} </span>
                      )}

                      {entityType && (
                        <span className="font-semibold">
                          {entityType
                            .map((e) => capitalize(e.toLowerCase()))
                            .join('/')}{' '}
                        </span>
                      )}

                      {authorizedFor.length > 0 && (
                        <>
                          <span>of </span>
                          <span className="font-semibold">
                            {Array.from(new Set(authorizedFor)).join(', ')}
                          </span>
                        </>
                      )}
                    </div>
                  </Card>

                  {safetyRating ? (
                    <Card className="p-3 flex flex-col space-y-2.5 text-12 border border-green-200 bg-green-100/50">
                      <div className="flex items-center justify-between mb-0">
                        <div className="text-green-600 uppercase font-bold flex items-center space-x-1">
                          <SVG name="success" className="-ml-1" />
                          <span>{capitalize(safetyRating)}</span>
                        </div>
                      </div>

                      <div>
                        {safetyReviewDate && (
                          <>
                            Safety rated on{' '}
                            <Timestamp
                              timestamp={safetyReviewDate}
                              displayFormat="yyyy-MM-dd"
                              className="font-semibold"
                            />
                          </>
                        )}
                      </div>
                    </Card>
                  ) : (
                    <Card className="flex-1 p-3 flex flex-col justify-center space-y-2.5 text-12 border border-gray-400 bg-gray-100">
                      <div className="flex items-center justify-between">
                        <div className="text-black-300 uppercase font-bold flex items-center space-x-1">
                          <SVG name="delete" className="-ml-1" />
                          <span>No Safety Rating</span>
                        </div>
                      </div>
                    </Card>
                  )}
                </div>
              </div>
            )}

            <Card className="flex-1 px-3 flex items-center justify-around text-14 text-center font-semibold border border-gray-400/0 bg-gray-300/0">
              <div className="flex items-baseline gap-2">
                <div className="text-12 text-black-100">Trucks</div>
                <div className="font-bold">
                  {totalPowerUnits ? totalPowerUnits.toLocaleString() : '-'}
                </div>
              </div>
              <div className="flex items-baseline gap-2">
                <div className="text-12 text-black-100">Drivers</div>
                <div className="font-bold">
                  {totalDrivers ? totalDrivers.toLocaleString() : '-'}
                </div>
              </div>
            </Card>
          </div>
          <Card className="flex-1 p-3 flex flex-col space-y-3 text-12 border border-gray-300">
            <div className="flex items-center justify-between border-b border-gray-300 pb-2 mb-0">
              <div className="text-black-100 text-12 up font-semibold flex items-center space-x-1">
                <span>Insurance</span>
              </div>
              <ExternalLink
                href={liURL}
                className="btn gray !py-0 group text-11">
                L&I
              </ExternalLink>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex items-baseline justify-between">
                <div className="text-black-300">BI/PD</div>
                <strong>
                  {bipdInsuranceOnFile ? (
                    <CurrencyText>{bipdInsuranceOnFile * 1000}</CurrencyText>
                  ) : (
                    '-'
                  )}
                </strong>
              </div>
              <div className="flex items-baseline justify-between">
                <div className="text-black-300">CARGO</div>
                <strong className="text-gray-700">
                  {cargoInsuranceOnFile ? (
                    <CurrencyText>{cargoInsuranceOnFile * 1000}</CurrencyText>
                  ) : (
                    '-'
                  )}
                </strong>
              </div>
              <div className="flex items-baseline justify-between">
                <div className="text-black-300">BOND</div>
                <strong className="text-gray-700">
                  {bondInsuranceOnFile ? (
                    <CurrencyText>{bondInsuranceOnFile * 1000}</CurrencyText>
                  ) : (
                    '-'
                  )}
                </strong>
              </div>
            </div>
          </Card>

          <Card className="flex-1 p-3 flex flex-col space-y-3 text-12 border border-gray-300">
            <div className="flex items-center justify-between border-b border-gray-300 pb-2 mb-0">
              <div className="text-black-100 text-12 up font-semibold flex items-center space-x-1">
                <span>Inspections/Crashes</span>
              </div>
              <ExternalLink
                href={saferURL}
                className="btn gray !py-0 group text-11">
                Safer
              </ExternalLink>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex items-baseline justify-between">
                <div className="text-black-300">VEHICLE</div>
                <strong className="text-gray-700">{vehicleInsp ?? '-'}</strong>
              </div>
              <div className="flex items-baseline justify-between">
                <div className="text-black-300">DRIVER</div>
                <strong className="text-gray-700">{driverInsp ?? '-'}</strong>
              </div>
              <div className="flex items-baseline justify-between">
                <div className="text-black-300">HAZMAT</div>
                <strong className="text-gray-700">{hazmatInsp ?? '-'}</strong>
              </div>
              <div className="flex items-baseline justify-between">
                <div className="text-black-300">CRASHES</div>
                <strong className="text-gray-700">{crashTotal ?? '-'}</strong>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className="bg-gray-200 px-6 md:px-10 py-6 sticky bottom-0 w-full rounded-b-lg flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="text-11 text-gray-800">
            Updated&nbsp;
            <Timestamp timestamp={orcusProvider?.fmcsaScrapeLastSuccessAt} />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex items-center gap-1">{/* FMCSA */}</div>
          <ExternalLink
            href={saferURL}
            className="btn blue text-13 text-gray-50 !border-blue-200 hover:!border-blue-600">
            View on FMCSA
          </ExternalLink>
        </div>
      </div>
    </>
  )
}

function checkAddressesMatch(addressA, addressB) {
  if (!addressA || !addressB) return false

  if (addressA.street[0].toLowerCase() !== addressB.street[0].toLowerCase()) {
    return false
  }
  if (addressA.city !== addressB.city) {
    return false
  }
  if (addressA.stateCode !== addressB.stateCode) {
    return false
  }
  if (addressA.postalCode !== addressB.postalCode) {
    return false
  }

  return true
}

export default React.memo(FMCSACard)
