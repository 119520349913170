import React from 'react'

export default function ProgressBar({label, progress}) {
  return (
    <>
      <div className="mt-2 pb-2 w-full flex justify-between">
        <span>{label} progress</span>
        <span>{progress}%</span>
      </div>
      <div className="rounded-full bg-gray-900/10 shadow-[inset_0_0_8px] shadow-gray-900/50">
        <div
          className="h-2 rounded-full bg-blue-500 transition animate-pulse"
          style={{
            width: `${progress}%`,
          }}
        />
      </div>
    </>
  )
}
