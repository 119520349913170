/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import Papa from 'papaparse'
import {Button, SVG} from '@quotefactory/ui'
import {downloadBlob} from '../../helpers/files'

export default function DataTable({data, filename, isLoading}) {
  if (isLoading) {
    return (
      <div className="p-6">
        <SVG className="pl-2" name="loading" />
      </div>
    )
  }

  if (!data?.length) {
    return <div className="p-6">no matching data found</div>
  }

  const headers = Object.keys(data[0])

  const download = () => {
    const csv = Papa.unparse(data, {
      quotes: true,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ',',
      header: true,
      newline: '\r\n',
      skipEmptyLines: false, // other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
      columns: null, // or array of strings
    })
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
    downloadBlob(blob, filename)
  }

  return (
    <div>
      <div className="absolute bottom-4 right-4">
        <Button title="download as CSV" onClick={download}>
          <SVG name="download" />
        </Button>
      </div>
      <table className="mb-14 w-full text-sm text-left rtl:text-right dark:text-gray-400">
        <thead className="text-xs text-black-500 bg-gray-500 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
          <tr>
            {headers.map((header) => (
              <th key={header} className="px-6 py-3">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIdx) => (
            <tr
              key={rowIdx}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              {Object.values(row).map((val, cellIdx) => (
                <td key={`${cellIdx}-${val}`} className="px-6 py-4">
                  {val}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
