import * as React from 'react'
import {Button, Toggle} from '@quotefactory/ui'

export default function Vendors({onRun, isRunning}) {
  const [ltlIntegrations, setLtlIntegrations] = React.useState(true)

  const onSubmit = () => {
    onRun({
      reportName: 'Vendors',
      runAsync: false,
      params: {ltlIntegrations},
    })
  }

  return (
    <>
      <h2>Vendors</h2>
      <div className="flex items-center justify-between">
        <div className="pt-4 flex">
          <div className="mr-2">LTL integrations only</div>
          <Toggle
            id="ltlIntegrations"
            name="ltlIntegrations"
            checked={ltlIntegrations}
            onChange={() => setLtlIntegrations((prev) => !prev)}
          />
        </div>
      </div>
      <Button className="mt-4 !px-10" loading={isRunning} onClick={onSubmit}>
        Run
      </Button>
    </>
  )
}
