import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {createTheme} from '@material-ui/core/styles'
import {ThemeProvider} from '@material-ui/styles'
import {colors, breakpoints} from 'style-vars'

// A custom theme for Quote Factory

const baseTheme = {
  breakpoints: {
    units: 'px',
    values: {
      ...breakpoints,
    },
  },
  typography: {
    fontFamily: ['Mulish', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    fontWeight: 600,
  },
  palette: {
    primary: {
      light: colors.blue[300],
      main: colors.blue[500],
      dark: colors.blue[900],
      contrastText: '#fff',
    },
    secondary: {
      light: '#36E7A8',
      main: '#14C788',
      dark: '#00784E',
      contrastText: '#033523',
    },
  },
  shape: {
    borderRadius: 8,
  },
  ripple: {
    color: '#5A9FFF',
  },
  elevation8: {
    boxShadow:
      '0px 5px 5px -3px rgba(21,35,45,0.2), 0px 8px 10px 1px rgba(21,35,45,0.14), 0px 3px 44px 2px rgba(21,35,45,0.12)',
  },
  overrides: {
    MuiTouchRipple: {
      child: {
        backgroundColor: '#80BDFF',
      },
    },
  },
  props: {
    MuiInputLabel: {
      shrink: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
}

export default function MUIThemeProvider(props) {
  const {children, overrides} = props

  const theme = useMemo(
    () =>
      createTheme({
        ...baseTheme,
        overrides: {
          ...baseTheme.overrides,
          ...overrides,
        },
      }),
    [overrides, baseTheme],
  )
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

MUIThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  overrides: PropTypes.objectOf(PropTypes.object).isRequired,
}
