import * as React from 'react'
import {format, parseISO, startOfMonth} from 'date-fns'

import {Button, USDatePickerControl} from '@quotefactory/ui'

const dtFmt = (dt) => format(dt, 'yyyy-MM-dd')

export default function P44Usage({onRun, isRunning}) {
  // Default Feb - Jan to match our P44 annual contract
  const [dtStart, setDtStart] = React.useState(parseISO('2025-02-01'))

  const onSubmit = () => {
    onRun({
      reportName: 'P44Usage',
      runAsync: false,
      params: {dtStart: dtFmt(startOfMonth(dtStart))},
    })
  }

  return (
    <div>
      <h2>P44 Usage</h2>

      <p className="mt-4">
        The data will start with the 1st day of the month you choose.
      </p>

      <div className="pt-4">
        <div className="mt-2">
          <USDatePickerControl
            id="deliveryOn"
            className="form-submitted:field-invalid:border-red-500"
            name="deliveryOn"
            minDate={parseISO('2020-01-01')}
            value={dtStart}
            required
            onChange={(dt) => setDtStart(dt)}
          />
        </div>
        <Button className="mt-4 !px-10" loading={isRunning} onClick={onSubmit}>
          Run
        </Button>
      </div>
    </div>
  )
}
