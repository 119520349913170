import React from 'react'
import {Link} from '@reach/router'
import {SVG} from '@quotefactory/ui'
import {Layout} from 'pages'
import {useIntegrations} from '../hooks'
import FactorForm from '../components/FactorForm'

export default function Factor({factorId}) {
  const {getIntegrationById, isLoading} = useIntegrations()

  const factor = getIntegrationById(factorId)

  if (isLoading) {
    return (
      <Layout>
        <div className="m-auto text-center px-32 py-24">
          <SVG name="loading" className="w-24" />
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <nav
        className="flex items-center justify-between pt-5 pb-8"
        aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4 m-0">
          <li>
            <div>
              <Link
                to="/factors"
                className="flex items-center space-x-1 text-gray-800 hover:text-black-500">
                <SVG
                  name="factoring"
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <div>Factors</div>
              </Link>
            </div>
          </li>
          <li>
            <div className="flex items-center space-x-4">
              <SVG
                name="slash"
                className="flex-shrink-0 h-5 w-5 text-gray-600"
              />
              <div className="ml-4 text-sm font-medium text-black-500">
                {factor?.name || 'new'}
              </div>
            </div>
          </li>
        </ol>
      </nav>

      <FactorForm factor={factor}>
        {factor?.name || 'Enter new factoring company details'}
      </FactorForm>
    </Layout>
  )
}
