import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {SVG, Titlebar, Title, Toggle} from '@quotefactory/ui'
import {Layout} from 'pages'
import {fetchAccounts, selectAccounts} from 'reducers/account'
import AccountList from '../components/account/AccountList'
import useKeyListener from '../hooks/use-key-listener'

export default function Accounts() {
  const reduxDispatch = useDispatch()
  const accounts = useSelector(selectAccounts) || []

  // A press-any-key search filter
  const [search, setSearch] = React.useState('')
  const onKeyDown = (key, modifiers) => {
    if (key === 'Escape') {
      setSearch('')
      return
    }
    if (key === 'Delete' || key === 'Backspace') {
      setSearch((prev) => prev.slice(0, -1))
      return
    }
    // Don't care about function keys or key combinations except "shift" for uppercase.
    if (key.length > 1 || (modifiers.length > 0 && modifiers[0] !== 'shift')) {
      return
    }
    setSearch((prev) => (prev === '' && key === ' ' ? prev : `${prev}${key}`))
  }
  useKeyListener(onKeyDown)

  const localStorageKey = 'qfadmin-account-filters'

  const [filter, setFilter] = React.useState({
    ...{
      includeActive: true,
      includeTest: true,
      includeDisabled: false,
    },
    ...JSON.parse(window.sessionStorage.getItem(localStorageKey) || '{}'),
  })

  useEffect(() => {
    const fetchData = async () => {
      // Send both of these simultaneously. It's a bit of a hack.
      // Fetch the full stats. If in cache, they'll come back fast
      reduxDispatch(fetchAccounts())
      // Fetch just the basic account details so we populate the
      // list fast if we have to wait for the full stats.
      reduxDispatch(fetchAccounts(true))
    }
    fetchData()
  }, [])

  useEffect(() => {
    sessionStorage.setItem(localStorageKey, JSON.stringify(filter))
  }, [filter])

  const filteredAccounts = React.useMemo(() => {
    // isTest and disabled are distinct props, but the UI treats them as one prop,
    // disabled trumps isTest
    return accounts
      .map((a) => {
        // eslint-disable-next-line no-nested-ternary
        const status = a.disabled
          ? 'includeDisabled'
          : a.isTest
            ? 'includeTest'
            : 'includeActive'
        return filter[status] ? a : null
      })
      .filter(Boolean)
      .filter((a) => {
        if (!search) return true
        const regex = new RegExp(search, 'ig')
        return a.name.match(regex)
      })
  }, [accounts, filter, search])

  const handleToggle = ({target: {id}}) => {
    setFilter((prev) => ({...prev, [id]: !prev[id]}))
  }

  return (
    <Layout className="ml:!max-w-[calc(100vw-150px)] xl:!max-w-[calc(100vw-200px)] !w-screen">
      <div className="w-full mx-auto max-w-[1400px] relative">
        <div className="pt-8 pb-4 absolute inset-x-0 z-[40] h-40 md:h-28 bg-gray-200 shadow-[0_0_0_2px] shadow-gray-200">
          <Titlebar className="flex-wrap items-center">
            <Title>
              <span className="py-2">Accounts</span>

              <a
                target="_blank"
                rel="noreferrer"
                href={`${config.QF_APP_URL}/new-broker`}
                className="inline-flex items-center justify-center y-1.5 text-13 ml-4 font-bold rounded-full text-gray-800 hover:text-blue-500">
                <SVG name="addCircle" className="" />
              </a>
            </Title>
            <div className="md:ml-auto relative w-full md:w-auto flex items-center gap-6">
              <div className="flex items-center gap-6 text-13">
                <div className="flex items-center justify-between gap-1">
                  <Toggle
                    id="includeActive"
                    name="includeActive"
                    className="small"
                    checked={filter.includeActive}
                    onChange={handleToggle}
                  />
                  <div>Active</div>
                </div>
                <div className="flex items-center justify-between gap-1">
                  <Toggle
                    id="includeTest"
                    name="includeTest"
                    className="small"
                    checked={filter.includeTest}
                    onChange={handleToggle}
                  />
                  <div>Test</div>
                </div>
                <div className="flex items-center justify-between gap-1">
                  <Toggle
                    id="includeDisabled"
                    name="includeDisabled"
                    className="small"
                    checked={filter.includeDisabled}
                    onChange={handleToggle}
                  />
                  <div>Disabled</div>
                </div>
              </div>
            </div>
          </Titlebar>
        </div>
      </div>

      <div className="h-screen overflow-auto overscroll-contain -mx-2 sm:-mx-8 px-2 sm:px-8 pb-40 pt-40 md:pt-28">
        <div className="mx-auto w-full max-w-[1400px]">
          <AccountList entities={filteredAccounts} search={search} />
        </div>
      </div>
    </Layout>
  )
}
