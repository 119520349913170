import React from 'react'
import {FilterPill, FilterSection} from 'components/filters'
import {SVG} from 'components'

const DEFAULT_VALUE = 'include'

export default function FilterXN({onFilterChange, value, isDesktop}) {
  const filterValue = value || DEFAULT_VALUE

  const UiComponent = isDesktop ? FilterPill : FilterSection

  /* An individual filter option */
  const FilterItem = ({thisValue, children}) => {
    const selected = thisValue === filterValue
    return (
      <li className={`item ${selected ? 'active' : ''}`}>
        <button
          type="button"
          onClick={() => {
            onFilterChange({xn: thisValue === DEFAULT_VALUE ? '' : thisValue})
          }}>
          {children}
        </button>
      </li>
    )
  }

  return (
    <UiComponent
      title={<SVG name="xtendedNetwork" className="w-28" />}
      label={
        <div className="flex items-center">
          <SVG name="xn" size={2} /> {filterValue}
        </div>
      }
      active={filterValue !== 'include'}
      clearFilter={() => onFilterChange({xn: ''})}>
      <ul className="menu-list -mx-2 w-64">
        <FilterItem thisValue="include">
          <div className="flex items-center">
            <SVG name="xn" size={2} /> include
          </div>
        </FilterItem>
        <FilterItem thisValue="exclude">
          <div className="flex items-center">
            <SVG name="xn" size={2} /> exclude
          </div>
        </FilterItem>
        <FilterItem thisValue="only">
          <div className="flex items-center">
            <SVG name="xn" size={2} /> only
          </div>
        </FilterItem>
        <FilterItem thisValue="not cloned">
          <div className="flex items-center">
            <SVG name="xn" size={2} /> not cloned
          </div>
        </FilterItem>
      </ul>
    </UiComponent>
  )
}
