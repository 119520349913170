import React from 'react'
import {twMerge} from '@quotefactory/ui'
import {Navbar} from 'components'

//////////////////////////////////////////////////////////////////////
// Default layout extended by pages
//////////////////////////////////////////////////////////////////////

export default function Layout({children, className}) {
  return (
    <div className="outline-none bg-gray-200 min-h-screen relative ml:pl-[150px] xl:pl-[200px] flex flex-col before:fixed before:inset-0 before:z-[200] before:rounded-b-lg before:border-[5px] before:border-purple-500 before:pointer-events-none">
      <Navbar />
      <main
        className={twMerge(
          'px-2 sm:px-8 h-full ml:min-h-screen w-full max-w-[1400px] mt-20 ml:mt-0 mx-auto flex-1 flex flex-col',
          className,
        )}>
        {children}
      </main>
    </div>
  )
}
