/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {NavItem} from 'components/nav'

export default function MenuMain() {
  return (
    <div className="flex flex-col gap-3 text-14">
      <NavItem label="Shipments" path="/shipments" />
      <NavItem label="Quotes" path="/quotes" />
      <NavItem label="Accounts" path="/accounts" />
      <NavItem label="Providers" path="/providers" />
      <NavItem label="Factors" path="/factors" />
      <NavItem label="Reports" path="/reports" />
    </div>
  )
}
