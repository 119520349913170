import React from 'react'
import {Link, useLocation} from '@reach/router'

export default function NavItem({label, path = '/dashboard', mobile}) {
  const location = useLocation()
  const screenCls = mobile ? 'block text-base' : 'text-sm'
  const activeCls =
    path === location.pathname
      ? 'link-active text-white'
      : 'text-gray-500 hover:text-white'
  return (
    <div className="flex items-center relative text-black-300 rounded-full px-3 xl:px-4 -mx-2 xl:-mx-1 transition-all py-2 hover:bg-gray-200 hover:text-black-700 group">
      <Link
        to={path}
        className={`absolute inset-0 rounded-full ${screenCls} ${activeCls}`}
        aria-label={label}
      />
      <span className="not-sr-only">{label}</span>
    </div>
  )
}
