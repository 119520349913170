/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
import React, {useEffect, useState, useRef, useMemo} from 'react'
import {useQueryParams, NumberParam, StringParam} from 'use-query-params'
import {Pagination, SortButton} from 'components'
import ShipmentCard from 'components/ShipmentCard'
import {Filters} from 'components/filters'
import api from 'services/api'
import {SVG, Titlebar, Title} from '@quotefactory/ui'

const PAGE_SIZE = 100
const [PAGE_UP, PAGE_DOWN, HOME, END] = [33, 34, 36, 35]

const QUERY_STORAGE_KEY = 'shipments-query'

const sortOptions = [
  {
    // value: 'createdAt:DESC',
    value: '', // Prevent this default from being added to querystring
    text: 'Created date: old to new',
    label: 'Created date',
  },
  {
    value: 'pickupOn:DESC',
    text: 'Pickup date: new to old',
    label: 'Pickup date',
  },
  {
    value: 'pickupOn:ASC',
    text: 'Pickup date: old to new',
    label: 'Pickup date',
  },
]

export default function ShipmentsList({onRowClick}) {
  const scrollContainerRef = useRef()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)

  /* Set state from querystring
     Only keys found in FILTERS will be considered */
  const [qs, setQS] = useQueryParams({
    skip: NumberParam,
    sortBy: StringParam,
    search: StringParam,
    xn: StringParam,
    interline: StringParam,
    status: StringParam,
    brokerId: StringParam,
    carrierId: StringParam,
    provider: StringParam,
    bookedDate: StringParam,
    pickupDate: StringParam,
    origin: StringParam,
    destination: StringParam,
    isTest: StringParam,
    equipment: StringParam,
    autoCover: StringParam,
  })

  const {
    skip,
    sortBy = '',
    search,
    xn,
    interline,
    status,
    brokerId,
    carrierId,
    provider,
    bookedDate,
    pickupDate,
    origin,
    destination,
    isTest = 'exclude',
    equipment,
    autoCover,
  } = qs

  // Restore the "remembered" querystring for the current session
  const savedQuery =
    JSON.parse(window.sessionStorage.getItem(QUERY_STORAGE_KEY) || null) || {}

  // On mount, merge saved filters into querystring if not already exist
  React.useEffect(() => {
    setQS(
      {
        ...savedQuery,
        ...Object.fromEntries(Object.entries(qs).filter(([key, val]) => val)),
      },
      'replace',
    )
  }, [])

  const setSkip = (skip) => setQS({skip: skip || undefined})
  const setSortBy = (sortBy) => setQS({sortBy})

  const [filters, setFilters] = useState({
    search,
    status,
    brokerId,
    carrierId,
    provider,
    bookedDate,
    pickupDate,
    origin,
    xn,
    interline,
    destination,
    isTest,
    equipment,
    autoCover,
  })

  const listQuery = useMemo(
    () => ({
      // Strip out filters that have an empty value
      filters: Object.fromEntries(
        Object.entries(filters).filter(([key, val]) => val),
      ),
      sortBy,
      skip,
      limit: PAGE_SIZE,
    }),
    [skip, sortBy, filters],
  )

  const fetchShipments = async () => {
    setLoading(true)
    const res = await api.listShipments({
      ...listQuery,
      brokerId: filters.brokerId,
      count: false,
    })
    setData(res.entities)
    setLoading(false)
  }

  const countQuery = useMemo(
    () => ({
      // Strip out filters that have an empty value
      filters: Object.fromEntries(
        Object.entries(filters).filter(([key, val]) => val),
      ),
      brokerId: filters.brokerId,
      sortBy,
    }),
    [sortBy, filters],
  )

  const [isCounting, setCounting] = useState(false)
  const countShipments = async () => {
    setCounting(true)
    const res = await api.countShipments(countQuery)
    setTotalRows(res.total)
    setCounting(false)
  }

  useEffect(() => {
    countShipments()
  }, [countQuery])

  const saveFilters = () => {
    // Sync the querystring with the current state
    const queryData = {
      skip: skip || undefined,
      sortBy: sortBy || undefined,
    }
    for (const [key, val] of Object.entries(filters)) {
      queryData[key] = val || undefined
    }

    // Default to `undefined` for all querystring params
    // Override with our current filters
    setQS({
      ...Object.fromEntries(Object.keys(qs).map((key) => [key, undefined])),
      ...queryData,
    })
    // Remember the querystring for this session
    sessionStorage.setItem(QUERY_STORAGE_KEY, JSON.stringify(queryData))
  }

  useEffect(() => {
    fetchShipments()
    scrollContainerRef.current.focus()
    saveFilters()
  }, [listQuery])

  useEffect(() => {
    // When a filter changes, go back to page 1
    setSkip(undefined)
  }, [filters])

  const [scrollOffset, setScrollOffset] = useState(0)
  const listHeight = 150

  // The magic number "5" is an arbitrary value from trial & error...
  const pageOffset = listHeight * 5
  const maxHeight =
    (scrollContainerRef.current &&
      scrollContainerRef.current.style.height.replace('px', '')) ||
    listHeight

  const minHeight = 0.1

  const keys = {
    [PAGE_UP]: Math.max(minHeight, scrollOffset - pageOffset),
    [PAGE_DOWN]: Math.min(scrollOffset + pageOffset, maxHeight),
    [END]: maxHeight,
    [HOME]: minHeight,
  }

  const keyDownListener = ({keyCode}) => {
    if (keys[keyCode]) {
      setScrollOffset(keys[keyCode])
    }
  }

  let even = true

  return (
    <div className="flex flex-col h-screen">
      <div className="relative">
        <div className="pt-8">
          {/* absolute inset-x-0 z-[40] h-40 md:h-28 bg-gray-200 shadow-[0_0_0_2px] shadow-gray-200 */}
          <Titlebar className="flex-wrap items-center">
            <Title className="flex items-baseline gap-3">
              <span className="py-2">Shipments</span>

              {data.length > 0 && (
                <div className="text-11 text-black-100">
                  {totalRows.toLocaleString()} matches
                </div>
              )}

              {(loading || isCounting) && <SVG name="loading" />}
            </Title>
            <div className="md:ml-auto relative w-full md:w-auto">
              <SortButton
                options={sortOptions}
                selectedSort={sortBy}
                onChange={(value) => setSortBy(value)}
              />
            </div>
          </Titlebar>
        </div>
        <div className="py-2">
          <Filters
            entityType="shipment"
            filters={filters}
            setFilters={setFilters}
          />
        </div>
      </div>

      <div
        ref={scrollContainerRef}
        className={`flex-1 overflow-auto relative outline-none max-w-[1400px] flex flex-col gap-2 p-2 ${
          totalRows > PAGE_SIZE ? 'mb-0' : ''
        }`}
        onKeyDown={keyDownListener}
        tabIndex="0">
        {data.map((item) => {
          even = !even
          return (
            <ShipmentCard
              key={item.id}
              shipment={item}
              onRowClick={onRowClick}
            />
          )
        })}
      </div>

      <div>
        <Pagination
          total={totalRows}
          perPage={PAGE_SIZE}
          skip={skip}
          onSetSkip={setSkip}
        />
      </div>
    </div>
  )
}
