import * as React from 'react'
import api from 'services/api'

export default function useImport({accountId, options, apiFunctionName, data}) {
  const [results, setResults] = React.useState([])
  // states: idle, running, paused, finished
  const stateRef = React.useRef('idle')
  const isImporting = ['running', 'paused'].includes(stateRef.current)
  const [importIdx, setImportIdx] = React.useState(0)
  const [progress, setProgress] = React.useState(0)
  const [stats, setStats] = React.useState({
    info: 0,
    warn: 0,
    error: 0,
  })

  const importRow = async (row) => {
    const result = await api[apiFunctionName]({
      ...options,
      row,
      accountId,
    })
    if (result?.results) {
      setResults((prev) => [...prev, ...result.results])
      setStats((prev) => {
        // A single entity import may return multiple messages. Count them all.
        const nextStats = {...prev}
        for (const msg of result.results) {
          nextStats[msg.level] += 1
        }
        return nextStats
      })
    } else {
      setResults((prev) => [
        ...prev,
        {level: 'error', msg: 'POST request failed'},
      ])
    }
  }

  const importRows = async (startIdx = 0) => {
    if (['idle', 'finished'].includes(stateRef.current)) {
      reset()
    }
    stateRef.current = 'running'
    let idx
    // eslint-disable-next-line no-plusplus
    for (idx = startIdx; idx < data.length; idx++) {
      const row = data[idx]
      // eslint-disable-next-line no-await-in-loop
      await importRow(row)
      setProgress(Math.ceil(((idx + 1) * 100) / data.length))
      if (stateRef.current !== 'running') {
        setImportIdx(idx + 1)
        if (stateRef.current === 'idle') {
          setResults((prev) => [
            ...prev,
            {level: 'info', msg: 'Import stopped'},
          ])
        }

        break
      }
    }

    if (idx === data.length) {
      stateRef.current = 'finished'
      setResults((prev) => [...prev, {level: 'info', msg: 'Import finished'}])
    }
  }

  const runControls = {
    start: () => importRows(0),
    resume: () => importRows(importIdx),
    pause: () => {
      stateRef.current = 'paused'
    },
    stop: () => {
      if (stateRef.current === 'paused') {
        setResults((prev) => [...prev, {level: 'info', msg: 'Import stopped'}])
      }
      stateRef.current = 'idle'
    },
  }

  const reset = () => {
    setResults([])
    setProgress(0)
    setStats({
      info: 0,
      warn: 0,
      error: 0,
    })
  }

  return {
    importState: stateRef.current,
    isImporting,
    runControls,
    reset,
    progress,
    stats,
    results,
  }
}
