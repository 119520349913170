import React from 'react'
import {capitalize} from 'helpers'
import {FilterPill, FilterSection} from 'components/filters'
import AreaLookupComboBoxInput from '../AreaLookupComboBoxInput'
import {areaToString, parseAreaKey} from '../../helpers/place'

export default function FilterLocation({
  stopType,
  onFilterChange,
  areaKey,
  isDesktop,
}) {
  const UiComponent = isDesktop ? FilterPill : FilterSection

  const filterName = stopType
  const title = capitalize(stopType)

  const handleAreaChange = (_, nextArea) => {
    onFilterChange({[stopType]: nextArea?.key ?? null})
  }

  return (
    <UiComponent
      title={title}
      label={areaKey ? areaToString(parseAreaKey(areaKey)) : title}
      active={!!areaKey}
      closeOnChange
      clearFilter={() => onFilterChange({[stopType]: null})}>
      <div>
        <AreaLookupComboBoxInput
          label=""
          name={filterName}
          value={areaKey}
          className="nostyle pl-3"
          onChange={handleAreaChange}
          onBlur={() => null}
          noOptions=""
        />
      </div>
    </UiComponent>
  )
}
