import React from 'react'
import {Layout} from 'pages'
import DashboardSelect from '../components/DashboardSelect'
import {useGetMetabaseDashboardQuery} from '../services/rtk-api'

export default function DashboardMetabase({dashboardId}) {
  const {data: embedURL} = useGetMetabaseDashboardQuery({dashboardId})

  const ref = React.useRef(null)
  const [height, setHeight] = React.useState('0px')
  const onLoad = () => {
    if (ref.current?.scrollHeight) {
      setHeight(`${ref.current.scrollHeight}px`)
    }
  }

  return (
    <Layout>
      <div
        className="h-[100vh]"
        style={{
          display: 'grid',
          gridTemplateRows: '100px 1fr',
        }}>
        <div className="flex items-center py-2 px-4 xl:px-4 justify-between my-6">
          <div className="flex items-center gap-2">
            <h2>Dashboard</h2>
            <DashboardSelect />
          </div>
        </div>

        <div ref={ref} className="-mx-4">
          <iframe
            title="Metabase Dashboard"
            src={embedURL}
            frameBorder={0}
            width="100%"
            height={height}
            onLoad={onLoad}
          />
        </div>
      </div>
    </Layout>
  )
}
