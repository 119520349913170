import React, {useState} from 'react'
import {Checkbox} from '@quotefactory/ui'
import api from 'services/api'
import {FilterPill, FilterSection} from 'components/filters'
import {ProviderLogo} from 'components'

/* An individual filter list-item checkbox */
const FilterProviderItem = function ({
  label,
  value,
  code,
  checked,
  disabled,
  onChange,
}) {
  return (
    <li className="my-2 field no-pad">
      <label className={`flex items-center ${disabled && 'disabled'}`}>
        <Checkbox
          checked={checked}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        {code && (
          <div className="ml-3 inline">
            <ProviderLogo code={code} name={label} className="w-28" />
          </div>
        )}
        {!code && (
          <span className="ml-3 text-base text-black-600">{label}</span>
        )}
      </label>
    </li>
  )
}

export default function FilterProvider({
  onFilterChange,
  items = '',
  isDesktop,
}) {
  const checkedItems = new Set(items.split(',').filter(Boolean))

  const [distinctValues, setDistinctValues] = useState([])

  const fetchProviders = async () => {
    const values = await api.fetchDistinctProviders()
    setDistinctValues(values)
  }

  const onFirstOpen = () => {
    fetchProviders()
  }

  const onChange = ({target}) => {
    const {checked, value} = target

    if (checked) {
      if (value === 'direct-only') {
        checkedItems.clear()
      } else {
        checkedItems.delete('direct-only')
      }
      checkedItems.add(value)
    } else {
      checkedItems.delete(value)
    }
    onFilterChange({provider: [...checkedItems].join(',')})
  }

  const clearFilter = () => {
    onFilterChange({provider: ''})
  }

  const buildLabel = () => {
    if (checkedItems.has('direct-only')) {
      return 'Direct only'
    }
    if (checkedItems.size > 0) {
      const {friendlyName} = distinctValues.find(
        (i) => i.id === checkedItems.values().next().value,
      )
      if (checkedItems.size === 1) {
        return `${friendlyName}`
      }
      return `${friendlyName} +${checkedItems.size - 1}`
    }
    return 'Provider'
  }

  const UiComponent = isDesktop ? FilterPill : FilterSection

  return (
    <UiComponent
      title="Provider"
      label={buildLabel()}
      active={checkedItems.size > 0}
      onFirstOpen={onFirstOpen}
      clearFilter={clearFilter}>
      <ul>
        <FilterProviderItem
          label="Direct carriers only"
          value="direct-only"
          checked={checkedItems.has('direct-only')}
          onChange={onChange}
        />
        {distinctValues.map(({id, friendlyName, code}) => (
          <FilterProviderItem
            key={id}
            label={friendlyName}
            value={id}
            code={code}
            checked={checkedItems.has(id)}
            onChange={onChange}
          />
        ))}
      </ul>{' '}
    </UiComponent>
  )
}
