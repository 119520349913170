import React, {useEffect} from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/solid'

const MAX_PAGE_BUTTONS = 7
const [LEFT_ARROW, RIGHT_ARROW] = [37, 39]

export default function Pagination({total, perPage = 50, skip = 0, onSetSkip}) {
  const maxPage = total ? Math.ceil(total / perPage) : 0
  const startPage = skip + 1

  const endPage = total
    ? Math.min(startPage + perPage - 1, total)
    : startPage + perPage - 1
  const page = Math.max(skip / perPage + 1, 1)

  const handlePageSelect = (selectedPage) => {
    onSetSkip(Math.max((selectedPage - 1) * perPage, 0))
  }

  const prevPage = () => {
    handlePageSelect(Math.max(page - 1, 0))
  }

  const nextPage = () => {
    handlePageSelect(maxPage > 0 ? Math.min(page + 1, maxPage) : page + 1)
  }

  const keys = {
    [LEFT_ARROW]: prevPage,
    [RIGHT_ARROW]: nextPage,
  }

  const keyDownListener = ({keyCode}) => {
    if (keys[keyCode]) {
      keys[keyCode]()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keyDownListener)
    return () => {
      document.removeEventListener('keydown', keyDownListener)
    }
  }, [page])

  const PageButton = ({pageNum}) => {
    const cls =
      pageNum === page
        ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
    return (
      <button
        type="button"
        onClick={() => handlePageSelect(pageNum)}
        aria-current="page"
        className={`${cls} relative inline-flex items-center px-4 py-2 border text-sm font-medium`}>
        {pageNum}
      </button>
    )
  }

  /* Figure out which page buttons to show */
  let pageButtonsStart = page - Math.ceil(MAX_PAGE_BUTTONS / 3)
  if (pageButtonsStart + MAX_PAGE_BUTTONS > maxPage) {
    pageButtonsStart = maxPage - MAX_PAGE_BUTTONS + 1
  }
  if (pageButtonsStart < 1) {
    pageButtonsStart = 1
  }
  const pages = new Array(Math.min(MAX_PAGE_BUTTONS, maxPage))
    .fill(0)
    .map((_, idx) => idx + pageButtonsStart)

  return (
    <div
      tabIndex={0}
      role="button"
      className="px-4 py-3 flex items-center justify-between border-t border-gray-400 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          type="button"
          onClick={prevPage}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
          Previous
        </button>
        <button
          type="button"
          onClick={nextPage}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{startPage}</span> to{' '}
            <span className="font-medium">{endPage}</span>{' '}
            {total ? (
              <>
                of <span className="font-medium">{total}</span> results
              </>
            ) : null}
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination">
            <button
              type="button"
              onClick={() => handlePageSelect(1)}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <span className="sr-only">Previous</span>
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={prevPage}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {pages.map((pageNum) => (
              <PageButton key={pageNum} pageNum={pageNum} />
            ))}

            <button
              type="button"
              onClick={nextPage}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={() => handlePageSelect(maxPage)}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <span className="sr-only">Next</span>
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  )
}
