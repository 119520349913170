import React, {useRef, useState, useEffect} from 'react'
import {Popper} from '@quotefactory/ui'
import {SVG} from 'components'

function FilterPill({
  label,
  title,
  active,
  clearFilter,
  closeOnChange,
  onFirstOpen,
  children,
}) {
  const [menuAnchor, setMenuAnchor] = useState(null)
  const openedRef = useRef(false)

  const handlePillClick = (evt) => {
    const {parentElement} = evt.currentTarget
    if (menuAnchor) {
      setMenuAnchor(null)
    } else {
      setMenuAnchor(parentElement)
      if (!openedRef.current) {
        openedRef.current = true
        if (onFirstOpen) {
          onFirstOpen()
        }
      }
    }
  }

  const handleActionClick = (evt) => {
    if (active) {
      clearFilter()
      setMenuAnchor(null)
    } else {
      handlePillClick(evt)
    }
  }

  const handleMenuClose = (evt) => {
    setMenuAnchor(null)
  }

  useEffect(() => {
    if (closeOnChange) {
      handleMenuClose()
    }
  }, [label])

  return (
    <>
      <div
        className={`button-wrapper min-w-0 max-w-[160px] text-[#FF0099] ${
          active ? 'active' : ''
        } ${!menuAnchor ? '' : 'focused'}`}>
        <button type="button" className="truncate" onClick={handlePillClick}>
          {label}{' '}
        </button>
        <button type="button" onClick={handleActionClick} className="adornment">
          <ActionSVG active={active} menuAnchor={menuAnchor} />
        </button>
      </div>

      <Popper
        placement="bottom-end"
        anchorEl={menuAnchor}
        isOpen={!!menuAnchor}
        onClose={handleMenuClose}>
        <div>
          <div className="flex items-center justify-between mb-4">
            <strong className="pr-4 text-base">{title}</strong>
            <button type="button" onClick={handlePillClick} className="icon">
              <SVG name="close" className="w-5" />
            </button>
          </div>

          {children}
        </div>
      </Popper>
    </>
  )
}

function ActionSVG({active, menuAnchor}) {
  return active ? (
    <SVG name="close" />
  ) : (
    <SVG name={menuAnchor ? 'arrowUp' : 'arrowDown'} />
  )
}

export default FilterPill
