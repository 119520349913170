import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import useFetch from 'use-http'
import isEqual from 'react-fast-compare'
import {useDebounce} from 'use-debounce'
import Autocomplete from '@material-ui/lab/Autocomplete'
import QFHighlight from 'ui/QFHighlight'

import MUIThemeProvider from './MUIThemeProvider'
import SVG from '../Svg'
import {themeOverrides} from './QFAutocomplete'
import QFErrorPopper from './QFErrorPopper'

const placeToString = (place) =>
  place && Object.keys(place).length
    ? `${place.city}, ${place.stateCode} ${place.postalCode}`
    : ''

function QFPlaceLookup(props) {
  const {
    className = '',
    hideLabel = false,
    label,
    name,
    placeholder,
    disabled,
    value,
    onChange,
    touched,
    onBlur,
    error,
    place,
    onPlaceChange,
    noOptions,
  } = props
  // eslint-disable-next-line react/destructuring-assignment
  const id = props.id || name

  const handleChange = (e, option) => {
    if (option) {
      onChange(option._id)
    } else {
      onChange(null)
    }
  }

  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const [inputValue, setInputValue] = useState(placeToString(place))
  const handleInputChange = (e, newInputValue, reason) => {
    if (reason === 'reset') {
      return
    }
    setInputValue(newInputValue)
  }
  const [debouncedInputValue] = useDebounce(inputValue, 300)

  // If provided a value on init, create an options array
  // with only this value represented
  const [options, setOptions] = useState([])
  const getOptionSelected = (option, v) => {
    return option._id === v
  }

  const {get, loading, response} = useFetch()

  const getPlaces = async (search) => {
    if ((!search || search.length < 2) && !value) {
      setOptions([])
      return
    }

    const isLoadingInitial = options.length === 0 && !search && value
    const route = isLoadingInitial ? `places/${value}` : `places?s=${search}`
    const places = (await get(route)) || []

    if (response.ok) {
      const placeArray = isLoadingInitial ? [places] : places
      setOptions(placeArray)
    }
  }

  useEffect(() => {
    const option = options.find((o) => placeToString(o) === debouncedInputValue)
    if (!option) {
      getPlaces(debouncedInputValue)
    }
  }, [debouncedInputValue])

  useEffect(() => {
    const option = options.find((o) => o._id === value)
    if (option) {
      setInputValue(placeToString(option))
      if (onPlaceChange) {
        onPlaceChange(option)
      }
    }
  }, [value, options])

  useEffect(() => {
    if (place) {
      setInputValue(placeToString(place))
      onBlur({target: {name}})
    }
  }, [place?.city, place?.stateCode, place?.postalCode, place?.countryCode])

  useEffect(() => {
    const option = options.find((o) => placeToString(o) === inputValue)
    if (option) {
      onChange(option._id)
    }
  }, [inputValue, options])

  return (
    <div className={`field ${className} ${touched && error ? 'error' : ''}`}>
      {label && !hideLabel && (
        <label id={id}>
          {label}
          <QFErrorPopper error={error} touched={touched} />
        </label>
      )}

      <MUIThemeProvider overrides={themeOverrides}>
        <Autocomplete
          id={id}
          name={name}
          value={value}
          inputValue={inputValue || ''}
          options={options}
          autoHighlight
          selectOnFocus
          disablePortal
          loading={loading}
          clearOnBlur
          open={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          handleHomeEndKeys
          onChange={handleChange}
          getOptionSelected={getOptionSelected}
          onBlur={onBlur}
          onInputChange={handleInputChange}
          getOptionLabel={placeToString}
          noOptionsText={noOptions}
          renderOption={(
            option,
            {inputValue: currentInputValue, selected, ...rest},
          ) => {
            const optionLabel = placeToString(option)

            if (selected) {
              return <span>{optionLabel}</span>
            }

            return (
              <QFHighlight content={optionLabel} match={currentInputValue} />
            )
          }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref} className="select relative">
              <input
                style={{width: '100%'}}
                id={id}
                type="text"
                placeholder={placeholder}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params.inputProps}
                className={`${params.inputProps.className} input`}
                disabled={disabled}
              />
              <div className="drop-arrow absolute inset-y-0 right-0 pr-1 flex items-center pointer-events-none text-gray-500">
                <SVG name="arrowDown" className="transform" />
              </div>
            </div>
          )}
        />
      </MUIThemeProvider>
    </div>
  )
}

QFPlaceLookup.displayName = 'QFPlaceLookup'

QFPlaceLookup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  hideLabel: PropTypes.bool,
  place: PropTypes.shape({}),
  onPlaceChange: PropTypes.func,
}

export default React.memo(QFPlaceLookup, isEqual)
