/**
 * @typedef {Object} EquipmentType
 * @property {string} label
 * @property {string} value
 * @property {'tl' | 'ltl'} mode
 * @property {string} group
 * @property {number} [maxHeight]
 * @property {number} [maxLength]
 * @property {number} [maxWidth]
 */

/**
 * @type {EquipmentType}
 */
export const otherEquipmentType = {
  label: 'Other',
  value: 'Other',
  mode: 'tl',
  group: 'Other',
}

/**
 * @type {EquipmentType[]}
 */
export const equipmentTypes = [
  {
    label: 'LTL',
    value: 'LTL',
    mode: 'ltl',
    group: 'LTL',
    maxHeight: 108,
    maxLength: 327,
    maxWidth: 93,
  },
  {
    label: 'Dry Van',
    value: 'Dry Van',
    mode: 'tl',
    group: 'Dry Van',
    maxHeight: 108,
    maxLength: 630,
    maxWidth: 102,
  },
  {
    label: 'Dry Van 53',
    value: 'Dry Van 53',
    mode: 'tl',
    group: 'Dry Van',
    maxHeight: 108,
    maxLength: 630,
    maxWidth: 102,
  },
  {
    label: 'Dry Van 48',
    value: 'Dry Van 48',
    mode: 'tl',
    group: 'Dry Van',
    maxHeight: 108,
    maxLength: 570,
    maxWidth: 102,
  },
  {
    label: 'Straight Truck',
    value: 'Straight Truck',
    mode: 'tl',
    group: 'Dry Van',
    maxHeight: 144,
    maxWidth: 96,
    maxLength: 420,
  },
  {
    label: 'Cargo Van',
    value: 'Cargo Van',
    mode: 'tl',
    group: 'Dry Van',
    maxLength: 148,
    maxHeight: 110,
    maxWidth: 66,
  },
  {
    label: 'Flatbed',
    value: 'Flatbed',
    mode: 'tl',
    group: 'Flatbed',
  },
  {
    label: 'Flatbed 53',
    value: 'Flatbed 53',
    mode: 'tl',
    group: 'Flatbed',
  },
  {
    label: 'Flatbed 48',
    value: 'Flatbed 48',
    mode: 'tl',
    group: 'Flatbed',
  },
  {
    label: 'Step Deck',
    value: 'Step Deck',
    mode: 'tl',
    group: 'Flatbed',
  },
  {
    label: 'Double Drop',
    value: 'Double Drop',
    mode: 'tl',
    group: 'Flatbed',
  },
  {
    label: 'Removable Gooseneck',
    value: 'Removable Gooseneck',
    mode: 'tl',
    group: 'Flatbed',
  },
  {
    label: 'Conestoga',
    value: 'Conestoga',
    mode: 'tl',
    group: 'Flatbed',
    maxHeight: 162,
    maxWidth: 100,
  },
  {
    label: 'Hot Shot',
    value: 'Hot Shot',
    mode: 'tl',
    group: 'Flatbed',
  },
  {
    label: 'Refrigerated',
    value: 'Refrigerated',
    mode: 'tl',
    group: 'Refrigerated',
    maxHeight: 103.5,
    maxLength: 630,
    maxWidth: 98,
  },
  {
    label: 'Refrigerated 53',
    value: 'Refrigerated 53',
    mode: 'tl',
    group: 'Refrigerated',
    maxHeight: 103.5,
    maxLength: 630,
    maxWidth: 98,
  },
  {
    label: 'Refrigerated 48',
    value: 'Refrigerated 48',
    mode: 'tl',
    group: 'Refrigerated',
    maxHeight: 103.5,
    maxLength: 570,
    maxWidth: 98,
  },
  {
    label: 'Intermodal',
    value: 'Intermodal',
    mode: 'tl',
    group: 'Intermodal',
    maxHeight: 92.5,
    maxLength: 473,
    maxWidth: 93,
  },
  {
    label: 'Intermodal 20',
    value: 'Intermodal 20',
    mode: 'tl',
    group: 'Intermodal',
    maxHeight: 92.5,
    maxLength: 232,
    maxWidth: 93,
  },
  {
    label: 'Intermodal 20 High Cube',
    value: 'Intermodal 20 High Cube',
    mode: 'tl',
    group: 'Intermodal',
    maxHeight: 104.5,
    maxLength: 232,
    maxWidth: 93,
  },
  {
    label: 'Intermodal 40',
    value: 'Intermodal 40',
    mode: 'tl',
    group: 'Intermodal',
    maxHeight: 92.5,
    maxLength: 473,
    maxWidth: 93,
  },
  {
    label: 'Intermodal 40 High Cube',
    value: 'Intermodal 40 High Cube',
    mode: 'tl',
    group: 'Intermodal',
    maxHeight: 104.5,
    maxLength: 473,
    maxWidth: 93,
  },
  {
    label: 'Drayage',
    value: 'Drayage',
    mode: 'tl',
    group: 'Drayage',
    maxHeight: 92.5,
    maxLength: 473,
    maxWidth: 93,
  },
  {
    label: 'Drayage 20',
    value: 'Drayage 20',
    mode: 'tl',
    group: 'Drayage',
    maxHeight: 92.5,
    maxLength: 232,
    maxWidth: 93,
  },
  {
    label: 'Drayage 20 High Cube',
    value: 'Drayage 20 High Cube',
    mode: 'tl',
    group: 'Drayage',
    maxHeight: 104.5,
    maxLength: 232,
    maxWidth: 93,
  },
  {
    label: 'Drayage 20 Refrigerated',
    value: 'Drayage 20 Refrigerated',
    mode: 'tl',
    group: 'Drayage',
    maxHeight: 104.5,
    maxLength: 232,
    maxWidth: 93,
  },
  {
    label: 'Drayage 40',
    value: 'Drayage 40',
    mode: 'tl',
    group: 'Drayage',
    maxHeight: 92.5,
    maxLength: 473,
    maxWidth: 93,
  },
  {
    label: 'Drayage 40 High Cube',
    value: 'Drayage 40 High Cube',
    mode: 'tl',
    group: 'Drayage',
    maxHeight: 104.5,
    maxLength: 473,
    maxWidth: 93,
  },
  {
    label: 'Drayage 40 Refrigerated',
    value: 'Drayage 40 Refrigerated',
    mode: 'tl',
    group: 'Drayage',
    maxHeight: 104.5,
    maxLength: 473,
    maxWidth: 93,
  },
  {
    label: 'Dump',
    value: 'Dump',
    mode: 'tl',
    group: 'Dump',
  },
  {
    label: 'Bottom Dump',
    value: 'Bottom Dump',
    mode: 'tl',
    group: 'Dump',
  },
  {
    label: 'End Dump',
    value: 'End Dump',
    mode: 'tl',
    group: 'Dump',
  },
  {
    label: 'Side Dump',
    value: 'Side Dump',
    mode: 'tl',
    group: 'Dump',
  },
  {
    label: 'Power Only',
    value: 'Power Only',
    mode: 'tl',
    group: 'Power Only',
  },
  otherEquipmentType,
]

/**
 * Gets the equipmentType from the equipmentType value
 *
 * **NOTE:** If a value does not match the featured options
 * this assumes that the passed in value is a custom option.
 *
 * @param {string} [value]
 * @returns {EquipmentType | undefined}
 */
export const fromValue = (value) => {
  const featuredOption = equipmentTypes.find((opt) => opt.value === value)

  if (featuredOption) {
    return featuredOption
  }

  return value ? {...otherEquipmentType, value} : undefined
}

/**
 * Determines if the current equipment type is a featured equipment type
 * @param {string} value
 */
export const isFeaturedEquipmentType = (value) => {
  return fromValue(value)?.group !== otherEquipmentType.group
}

/**
 * Gets the height restriction from the equipmentType value
 *
 * @param {string} [value] The equipment type to find the restrictions for
 * @returns returns Infinity for no restrictions
 */
export const getDimensionRestrictionsFromValue = (value) => {
  const option = fromValue(value)

  return {
    maxHeight: option?.maxHeight ?? Infinity,
    maxWidth: option?.maxWidth ?? Infinity,
    maxLength: option?.maxLength ?? Infinity,
  }
}

/**
 * Gets the group based upon the equipmentType value passed in
 *
 * @param {string} [value]
 * @returns {string | undefined}
 */
export const getEquipmentGroupFromValue = (value) => {
  return fromValue(value)?.group
}

/**
 * Determines if the provided equipment type is able to be a partial truckload shipment.
 * @param {EquipmentType} equipmentType
 */
export const checkIsPartialApplicable = (equipmentType) =>
  ['Refrigerated', 'Dry Van', 'Flatbed'].includes(equipmentType?.group)

/**
 * Determines if the current equipment type has a temperature range
 * @param {EquipmentType} equipmentType
 */
export const checkIsTemperatureRangeApplicable = (equipmentType) =>
  equipmentType?.group === 'Refrigerated'

/**
 * Determines if the current equipment type is a custom equipment type
 * @param {EquipmentType} equipmentType
 */
export const checkIsOther = (equipmentType) => equipmentType?.group === 'Other'

/**
 * Determines if the current equipment type requires all 3 dimensions (L, W, H)
 * @param {string} value
 */
export const checkIsAllTotalDimensionsApplicable = (value) => {
  const equipmentType = fromValue(value)

  return (
    equipmentType &&
    ['Flatbed', 'Power Only', 'Other'].includes(equipmentType.group)
  )
}
