import React, {useRef} from 'react'
import PropTypes from 'prop-types'
import {colors} from 'style-vars'

export default function Avatar({url, title, size, className, square}) {
  if (!title) return null
  const attempts = useRef(0)
  const bgColor = colors.blue[600]
  const defaultImg = `https://ui-avatars.com/api/?name=${encodeURI(
    title,
  )}&rounded=${
    square ? 'false' : 'true'
  }&color=FFFFFF&background=${bgColor.slice(1)}&size=${size * 15}`

  return (
    <div className={`user flex justify-center ${className}`}>
      <img
        style={{height: `${size}rem`, width: `${size}rem`, maxWidth: 'none'}}
        className={square ? 'rounded-sm' : 'rounded-full'}
        src={url || defaultImg}
        alt={title}
        onError={(ev) => {
          if (attempts.current < 3) {
            ev.target.src = defaultImg
            attempts.current += 1
          }
        }}
      />
    </div>
  )
}

Avatar.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
  square: PropTypes.bool,
}

Avatar.defaultProps = {
  url: null,
  title: null,
  size: 8,
  className: '',
  square: false,
}
