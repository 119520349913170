export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

function formatCurrency(value, format, lang) {
  const stripSymbols = format.currencyDisplay === 'none'
  const localFormat = stripSymbols
    ? {...format, currencyDisplay: 'code'}
    : format
  let result = Intl.NumberFormat(lang, localFormat).format(value)
  if (stripSymbols) {
    result = result.replace(/[a-z]{3}/i, '').trim()
  }
  return result
}

export default function QFCurrency(props) {
  const {amount, noCents, noCurrencySymbol, currencyType, locale} = props
  let currencyTypeOption = 'USD'
  let localeType = locale
  let options = {}

  if (Number.isNaN(amount)) return null

  if (currencyType) {
    currencyTypeOption = currencyType
  }
  if (locale) {
    localeType = locale
  }
  options = {
    style: 'currency',
    currency: currencyTypeOption,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  if (noCents) {
    options.minimumFractionDigits = 0
    options.maximumFractionDigits = 0
  }

  if (noCurrencySymbol) {
    options.currencyDisplay = 'none'
  }
  return formatCurrency(amount, options, localeType)
}
