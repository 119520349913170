const config = {}

//////////////////////////////////////////////////////////////////////
// Called at app startup, this loads the current runtime configuration
// into an exported config object.
//
// DEV: In development mode, this takes the config object that webpack
// bundled with the app and applies those values.
//
// PRODUCTION: In production environments, the configuration is loaded
// from the global variable that was populated at runtime by the
// Express server.
//
//////////////////////////////////////////////////////////////////////

const initConfig = () => {
  let seedConfig

  if (process.env.QF_STATIC_CONFIG) {
    seedConfig = process.env.QF_STATIC_CONFIG
  } else {
    seedConfig = window.qf?.config
  }

  Object.assign(config, seedConfig)
}

if (!('NODE_ENV' in config)) {
  initConfig()
}

export default config
