import {useEffect} from 'react'
import auth0 from '../services/auth0'

export default function Logout() {
  useEffect(() => {
    auth0.logout({
      returnTo: `${window.document.location.origin}/login`,
    })
  }, [])

  return null
}
