import React, {useState} from 'react'
import {SVG} from 'components'
import {AccountListItem} from 'components/account'

export default function AccountList({entities, search}) {
  const [sort, setSort] = useState({key: 'name', asc: true})

  const sortList = (key) => {
    if (key === sort.key) {
      setSort((state) => ({
        ...state,
        asc: !sort.asc,
      }))
      return
    }
    setSort((state) => ({
      key,
      asc: true,
    }))
  }

  const sortEntities = () => {
    const {key, asc} = sort
    let sortedList
    if ('customersCount quoteRequestsCount shipmentsCount'.indexOf(key) > -1) {
      sortedList = entities.sort((a, b) => {
        if (a[key] < b[key]) return 1
        if (a[key] > b[key]) return -1
        return 0
      })
    } else if (key === 'lastShipment') {
      sortedList = entities.sort((a, b) => {
        const left = new Date(a.recentlyBookedShipments?.[0]?.bookedAt ?? 0)
        const right = new Date(b.recentlyBookedShipments?.[0]?.bookedAt ?? 0)

        if (!left) {
          return 1
        }

        if (!right) {
          return -1
        }

        if (left < right) return 1
        if (left > right) return -1
        return 0
      })
    } else {
      sortedList = entities.sort((a, b) => (a[key] || '').localeCompare(b[key]))
    }
    if (!asc) {
      sortedList.reverse()
    }
    return sortedList
  }
  const sortedEntities = sortEntities()

  const SortIndicator = ({sortKey}) => {
    if (sort.key !== sortKey) {
      return <SVG name="arrowAsc" className="asc w-4 h-4 ml-1 opacity-0" />
    }
    return (
      <>
        {sort.asc && <SVG name="arrowAsc" className="asc w-4 h-4 ml-1" />}
        {!sort.asc && <SVG name="arrowDesc" className="desc w-4 h-4 ml-1" />}
      </>
    )
  }

  return (
    <>
      <div className="flex items-center gap-2 text-12 text-black-100 px-4 pt-4 pb-2 sticky top-0 z-10 bg-gray-200 shadow-[0_0_0_2px] shadow-gray-200">
        <div className="flex-1 flex items-center">
          <button type="button" onClick={() => sortList('name')}>
            Name
          </button>
          <SortIndicator sortKey="name" />
          {search && (
            <div className="ml-6">
              <span className="text-green-500">search: </span>
              <span className="text-black-500">{search}</span>
            </div>
          )}
        </div>
        <div className="w-1/12 flex items-center justify-center">
          <button type="button" onClick={() => sortList('foo')}>
            Team
          </button>
          <SortIndicator sortKey="foo" />
        </div>
        <div className="w-1/12 flex items-center justify-center">
          <button type="button" onClick={() => sortList('customersCount')}>
            Customers
          </button>
          <SortIndicator sortKey="customersCount" />
        </div>
        <div className="w-1/12 flex items-center justify-center">
          <button type="button" onClick={() => sortList('quoteRequestsCount')}>
            Quotes
          </button>
          <SortIndicator sortKey="quoteRequestsCount" />
        </div>
        <div className="w-1/12 flex items-center justify-center">
          <button type="button" onClick={() => sortList('shipmentsCount')}>
            Shipments
          </button>
          <SortIndicator sortKey="shipmentsCount" />
        </div>
        <div className="w-1/12 flex items-center justify-center">
          <button type="button" onClick={() => sortList('lastShipment')}>
            Last
          </button>
          <SortIndicator sortKey="lastShipment" />
        </div>
      </div>

      <div className="flex flex-col gap-2 py-2">
        {sortedEntities.map((item) => (
          <AccountListItem key={item.id} account={item} />
        ))}
      </div>
    </>
  )
}
