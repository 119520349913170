import * as React from 'react'
import {Link, useNavigate} from '@reach/router'

import auth0 from '../services/auth0'
import ErrorPage from './ErrorPage'

/**
 * This component handles when Auth0 redirects back to
 * our App after authentication.
 */
export default function AuthCallback(props) {
  const triggered = React.useRef(false)

  const navigate = useNavigate()
  const [authError, setAuthError] = React.useState(null)

  React.useEffect(() => {
    if (triggered.current) return
    triggered.current = true
    ;(async () => {
      try {
        const result = await auth0.handleRedirectCallback()
        navigate(result?.appState?.redirectToAfterLogin || '/dashboard', {
          replace: true,
        })
      } catch (error) {
        setAuthError(error)
      }
    })()
  }, [])

  return authError ? (
    <ErrorPage title="Authentication error" error={authError}>
      <div className="flex">
        <Link to="/logout" className="btn outlined white text-17 wide mx-4">
          Logout
        </Link>
      </div>
    </ErrorPage>
  ) : null
}
