import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import api from '../services/api'

///////////////////////////////////////////////////////////////////////////////
// Async middleware
///////////////////////////////////////////////////////////////////////////////

export const fetchFeatures = createAsyncThunk('features/fetch', async () => {
  const result = await api.fetchFeatures()
  return result
})

///////////////////////////////////////////////////////////////////////////////
// Slice
///////////////////////////////////////////////////////////////////////////////

const initialState = {
  isLoading: false,
  loadedAt: null,
  values: [],
}

const slice = createSlice({
  name: 'features',
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchFeatures.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchFeatures.fulfilled, (state, action) => {
      state.isLoading = false
      state.loadedAt = new Date().getTime()
      state.values = action.payload
    })
    builder.addCase(fetchFeatures.rejected, (state, action) => {
      state.isLoading = false
      console.log(action.error)
    })
  },
})

export default slice.reducer
