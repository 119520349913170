import React from 'react'
import {Link} from '@reach/router'
import {SVG} from '@quotefactory/ui'

function ReportLink({children, label, path}) {
  return (
    <div className="bg-white flex items-center relative text-black-300 rounded-md group">
      <Link
        to={path}
        className="absolute inset-0 rounded-md hover:shadow-[0_0_0_2px] hover:shadow-blue-500"
        aria-label={label}
      />
      <div className="flex flex-col items-center justify-start gap-2 w-full px-10 py-20 text-17 text-center">
        {children}
      </div>
    </div>
  )
}

export default function ReportsList() {
  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 auto-rows-fr gap-4">
      <ReportLink
        label="Monthly Billing Summary"
        path="/reports/MonthlyBillingSummary">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-12">
          <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2">
            <rect width="15.299" height="14.317" x="4.351" y="5.691" rx="2" />
            <line x1="8.251" x2="8.251" y1="3.917" y2="7.223" />
            <line x1="15.749" x2="15.749" y1="3.917" y2="7.223" />
            <path d="M10.364 15.521h2.204a1.336 1.336 0 0 0 0-2.671h-1.136a1.336 1.336 0 1 1 0-2.672h2.204" />
            <line x1="12.17" x2="12.17" y1="10.178" y2="8.976" />
            <line x1="12.17" x2="12.17" y1="16.724" y2="15.521" />
          </g>
        </svg>
        <div>
          <div>Monthly Billing</div> <div className="absolute">Summary</div>
        </div>
      </ReportLink>
      <ReportLink
        label="Monthly Billing Broker Detail"
        path="/reports/MonthlyBillingBrokerDetail">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-12">
          <g fill="none" fillRule="evenodd">
            <circle cx="7.616" cy="12.761" r="1.25" fill="currentColor" />
            <circle cx="14.649" cy="12.761" r="1.25" fill="currentColor" />
            <circle cx="7.616" cy="16.783" r="1.25" fill="currentColor" />
            <circle cx="14.649" cy="16.783" r="1.25" fill="currentColor" />
            <circle cx="11.133" cy="12.761" r="1.25" fill="currentColor" />
            <circle cx="11.133" cy="16.783" r="1.25" fill="currentColor" />
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth="1.2"
              d="M15.265 6.854h1.05a2 2 0 0 1 2 2v9.75a2 2 0 0 1-2 2H5.95a2 2 0 0 1-2-2v-9.75a2 2 0 0 1 2-2H7"
            />
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth="1.2"
              d="M8 3.226h6.265a1 1 0 0 1 1 1v4.08a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4.08a1 1 0 0 1 1-1Z"
            />
          </g>
        </svg>
        <div>
          <div>Monthly Billing</div>{' '}
          <div className="absolute">Broker Detail</div>
        </div>
      </ReportLink>
      <ReportLink label="XN Shipments" path="/reports/ShipmentsXN">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-12 p-1">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.317 3.579c.47 0 .707.57.373.902L8.035 6.137l9.828 9.829 1.656-1.657a.529.529 0 0 1 .903.374l-.005 5.738h-5.734a.528.528 0 0 1-.373-.902l1.604-1.605-3.965-3.965-3.914 3.914 1.656 1.656a.529.529 0 0 1-.374.902l-5.738-.004v-5.734c0-.47.57-.707.902-.374l1.605 1.606L10 12 6.086 8.086 4.48 9.69a.529.529 0 0 1-.903-.374l.005-5.738Zm5.367 0 5.737.004v5.734c0 .47-.57.707-.902.374l-1.656-1.657-2.716 2.717-1.949-1.95 2.716-2.715-1.605-1.605a.529.529 0 0 1 .375-.902Z"
          />
        </svg>
        XN Shipments
      </ReportLink>
      <ReportLink label="Broker Milestones" path="/reports/BrokerMilestones">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-12">
          <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth="1.2">
            <rect width="17.969" height="7.748" x="3.016" y="12.279" rx="1" />
            <line
              x1="5.5"
              x2="18.144"
              y1="8.376"
              y2="8.376"
              strokeLinecap="round"
            />
            <line
              x1="8.405"
              x2="16.234"
              y1="4.474"
              y2="4.474"
              strokeLinecap="round"
            />
            <line
              x1="15.368"
              x2="17.549"
              y1="16.069"
              y2="16.069"
              strokeLinecap="round"
            />
          </g>
        </svg>
        Broker Milestones
      </ReportLink>

      <ReportLink label="Broker Pace" path="/reports/BrokerPace">
        <SVG name="speedometer" className="w-12" />
        Broker Pace
      </ReportLink>

      <ReportLink label="Accounts" path="/reports/Accounts">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-12 p-2">
          <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="1.44">
            <circle cx="11.968" cy="9.859" r="3.982" />
            <circle cx="11.968" cy="11.968" r="10.968" />
            <path d="M18.188 18.634a8.297 8.297 0 0 0-6.22-2.795 8.297 8.297 0 0 0-6.22 2.795" />
          </g>
        </svg>
        Accounts
      </ReportLink>

      <ReportLink label="Users" path="/reports/Users">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-12 p-2">
          <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="1.44">
            <circle cx="11.968" cy="9.859" r="3.982" />
            <circle cx="11.968" cy="11.968" r="10.968" />
            <path d="M18.188 18.634a8.297 8.297 0 0 0-6.22-2.795 8.297 8.297 0 0 0-6.22 2.795" />
          </g>
        </svg>
        Users
      </ReportLink>
      <ReportLink label="Vendors" path="/reports/Vendors">
        <SVG name="carrier" className="w-12" />
        Vendors
      </ReportLink>
      <ReportLink label="Factoring Accounts" path="/reports/FactoringAccounts">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-12">
          <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="1.2">
            <path d="m19.15 18.07-2.383 2.383-2.384-2.384L12 20.453l-2.383-2.384-2.384 2.384-2.383-2.384V4.668a1 1 0 0 1 1-1h12.3a1 1 0 0 1 1 1v13.401h0Z" />
            <g transform="translate(9.39 7.483)">
              <line x1=".563" x2="4.344" y1="3.685" y2="3.685" />
              <polyline points="5.22117065 -7.15487416e-15 0.563212163 -1.24474207e-14 0.563212163 6.81346154" />
            </g>
          </g>
        </svg>
        Factoring Accounts
      </ReportLink>
      <ReportLink
        label="PRO Number / Tracking Report"
        path="/reports/PRONumberAndTrackingReport">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-12">
          <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="1.2">
            <path d="M5 3.745h13.424a1 1 0 011 1v7.872a1 1 0 01-1 1H5a1 1 0 01-1-1V4.745a1 1 0 011-1z" />
            <path d="M9.459 3.745h4.505V8.25H9.459z" />
            <path
              strokeLinecap="round"
              d="M4 19.907v-2.868h15.424v2.868M11.712 17.22v2.715"
            />
          </g>
        </svg>
        PRO Number / Tracking Report
      </ReportLink>
      <ReportLink
        label="MacroPointCarriersReport"
        path="/reports/MacroPointCarriersReport">
        <SVG name="carrier" className="w-12" />
        MacroPoint Carriers Report
      </ReportLink>
      <ReportLink
        label="Account Integrations"
        path="/reports/AccountIntegrations">
        <SVG name="save" />
        Account Integrations
      </ReportLink>

      <ReportLink label="P44 Usage" path="/reports/P44Usage">
        <SVG name="paymentTerms" className="w-12" />
        P44 Usage
      </ReportLink>
    </div>
  )
}
