import {isNil} from './any'

/**
 * Converts a search string to URLSearchParams
 * @param {string} string
 */
export const stringToSearchParams = (string) => new URLSearchParams(string)

export const checkIsEmptySearchParams = (searchParams) =>
  Array.from(searchParams).length === 0

/**
 * Converts a URLSearchParam to an Object
 * @param {URLSearchParams} searchParams
 */
export const searchParamsToObject = (searchParams) =>
  Object.fromEntries([...searchParams])

/**
 * Creates a new URLSearchParams object with the supplied adustments
 * if the adjustments key is null or undefined the key will be removed.
 *
 * @param {URLSearchParams | string} params
 * @param {{[key: string]: string | undefined}} adjustments
 *
 * @returns A new URLSearchParams object with the changes applied
 */
export const adjustSearchParams = (params, adjustments = {}) => {
  const nextSearchParams = stringToSearchParams(
    typeof params === 'string' ? params : params.toString(),
  )

  Object.entries(adjustments).forEach(([key, value]) => {
    if (isNil(value)) {
      nextSearchParams.delete(key)
    } else {
      nextSearchParams.set(key, value)
    }
  })

  return nextSearchParams
}

/**
 * Creates a new URLSearchParams from the provided object
 * If a value from the object is an array, it will append
 *
 * @template {{[key: string]: string[] | number[] | boolean[] | string | number | boolean | undefined | null}} A
 *
 * @param {A} paramsObj
 * @param {{includeNull?: boolean}} [options]
 * @returns A new URLSearchParams object with the changes applied
 */
export const objectToSearchParams = (paramsObj, {includeNull = false} = {}) => {
  const searchParams = new URLSearchParams()

  for (const [key, value] of Object.entries(paramsObj)) {
    if (value !== undefined && value !== null && value !== '') {
      if (Array.isArray(value)) {
        for (const val of value) {
          searchParams.append(key, val.toString())
        }
      } else {
        searchParams.append(key, value.toString())
      }
    }

    if (includeNull && value === null) {
      searchParams.append(key, null)
    }
  }

  return searchParams
}

/**
 * Creates a new URLSearchParams string from the projected object
 *
 * @param {{[key: string]: [string] | string | undefined}} paramsObj
 *
 * @returns {string} A URLSearchParams string
 */
export const objectToSearchParamsString = (paramsObj) => {
  const searchParams = objectToSearchParams(paramsObj)

  return searchParams.toString()
}
