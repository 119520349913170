import React from 'react'
import {navigate} from '@reach/router'
import {Field, Label, Input, Toggle, Button} from '@quotefactory/ui'
import {useIntegrations} from '../hooks'

export default function Factor({factor, children}) {
  const {updateIntegration, createIntegration} = useIntegrations()

  const [error, setError] = React.useState('')
  const [isSaved, setIsSaved] = React.useState(true)
  const [formValues, setFormValues] = React.useState({
    name: factor?.name || '',
    logoURL: factor?.logoURL || '',
    website: factor?.website || '',
    active: factor?.active ?? true,
    company: factor?.address?.company || '',
    street1: factor?.address?.street1 || '',
    street2: factor?.address?.street2 || '',
    city: factor?.address?.city || '',
    stateCode: factor?.address?.stateCode || '',
    postalCode: factor?.address?.postalCode || '',
    institution: factor?.paymentSettings?.ach?.institution || '',
    accountNumber: factor?.paymentSettings?.ach?.accountNumber || '',
    routingNumber: factor?.paymentSettings?.ach?.routingNumber || '',
    instructions: factor?.paymentSettings?.instructions || '',
  })

  const isNew = !factor?.name

  const onChange = ({target}) => {
    const key = target.id === 'active' ? target.id : target.name
    const value = target.id === 'active' ? target.checked : target.value
    setFormValues((prev) => ({
      ...prev,
      [key]: value,
    }))

    if (isSaved) {
      setIsSaved(false)
    }

    if (error) {
      setError('')
    }
  }

  const onNameBlur = ({target}) => {
    if (!formValues.company) {
      setFormValues((prev) => ({
        ...prev,
        company: target.value,
      }))
    }
  }

  const buildIntegrationObject = () => ({
    integrationType: 'factoring',
    name: formValues.name,
    logoURL: formValues.logoURL,
    website: formValues.website,
    active: formValues.active,
    address: {
      company: formValues.company,
      street: [formValues.street1, formValues.street2].filter(Boolean),
      city: formValues.city,
      stateCode: formValues.stateCode,
      postalCode: formValues.postalCode,
    },
    paymentSettings: {
      instructions: formValues.instructions,
      ach: {
        institution: formValues.institution,
        accountNumber: formValues.accountNumber,
        routingNumber: formValues.routingNumber,
      },
    },
  })

  const onSubmit = async (evt) => {
    evt.preventDefault()

    if (error) {
      setError('')
    }

    const data = buildIntegrationObject()

    let result
    if (isNew) {
      result = await createIntegration(data)
      // Navigate to the factor we just created so further updates will be in update mode.
      if (result.data?.id) {
        navigate(`/factor/${result.data.id}`)
      }
    } else {
      result = await updateIntegration({
        ...data,
        id: factor.id,
      })
    }

    setIsSaved(true)

    if (result.error) {
      setError(result.error.data?.message || 'Something went wrong')
    }
  }

  return (
    <div className="max-w-6xl">
      <div className="flex items-center px-4 py-2 justify-between my-2">
        <h2 className="font-semibold">{children}</h2>
        <div className="flex items-center justify-between gap-2">
          <div>Active</div>
          <Toggle
            id="active"
            name="active"
            checked={formValues.active}
            onChange={onChange}
          />
        </div>
      </div>
      <form onSubmit={onSubmit} className="pb-20 flex flex-col gap-3">
        <div className="p-4 flex flex-col gap-3">
          <div className="flex gap-4">
            <Field className="flex-1 xl:w-full xl:max-w-xl">
              <Label htmlFor="name">
                Name <Field.ErrorTooltip />
              </Label>
              <Input
                name="name"
                id="name"
                placeholder="Factoring Company"
                onChange={onChange}
                onBlur={onNameBlur}
                value={formValues.name}
                required
              />
            </Field>

            <Field className="">
              <Label htmlFor="code">
                Code <Field.ErrorTooltip />
              </Label>

              <Input
                name="code"
                id="code"
                placeholder="code will be generated"
                onChange={() => null}
                value={factor?.code}
                disabled
              />
            </Field>
          </div>

          <Field className="xl:max-w-xl">
            <Label htmlFor="website">
              Website <Field.ErrorTooltip />
            </Label>

            <Input
              name="website"
              id="website"
              placeholder="https://some-company.com"
              onChange={onChange}
              value={formValues.website}
            />
          </Field>

          <Field className="xl:max-w-xl">
            <Label htmlFor="logoURL">
              Logo URL <Field.ErrorTooltip />
            </Label>

            <Input
              name="logoURL"
              id="logoURL"
              placeholder="https://some-company.com/logo.png"
              onChange={onChange}
              value={formValues.logoURL}
            />
          </Field>
        </div>

        <div className="flex flex-col md:flex-row border border-gray-400 bg-gray-300 rounded-lg mt-6">
          <div className="flex-1 p-6 flex flex-col gap-3">
            <div className="text-17 font-semibold">Remittance address</div>
            <Field>
              <Label htmlFor="company">
                Company <Field.ErrorTooltip />
              </Label>

              <Input
                name="company"
                id="company"
                placeholder="Factoring Company"
                onChange={onChange}
                value={formValues.company}
                required
              />
            </Field>

            <Field>
              <Label htmlFor="street1">
                Street 1 <Field.ErrorTooltip />
              </Label>

              <Input
                name="street1"
                id="street1"
                onChange={onChange}
                value={formValues.street1}
                required
              />
            </Field>

            <Field>
              <Label htmlFor="street2">
                Street 2 <Field.ErrorTooltip />
              </Label>

              <Input
                name="street2"
                id="street2"
                onChange={onChange}
                value={formValues.street2}
              />
            </Field>

            <div className="flex gap-4">
              <Field className="flex-1">
                <Label htmlFor="city">
                  City <Field.ErrorTooltip />
                </Label>

                <Input
                  name="city"
                  id="city"
                  onChange={onChange}
                  value={formValues.city}
                  required
                />
              </Field>

              <Field className="w-20">
                <Label htmlFor="stateCode">
                  State <Field.ErrorTooltip />
                </Label>

                <Input
                  name="stateCode"
                  id="stateCode"
                  onChange={onChange}
                  value={formValues.stateCode}
                  required
                />
              </Field>

              <Field className="w-28">
                <Label htmlFor="postalCode">
                  Postal code <Field.ErrorTooltip />
                </Label>

                <Input
                  name="postalCode"
                  id="postalCode"
                  onChange={onChange}
                  value={formValues.postalCode}
                  required
                />
              </Field>
            </div>
          </div>

          <div className="border-b md:border-l border-gray-500" />

          <div className="flex-1 p-6 flex flex-col gap-3 ">
            <div className="text-17 font-semibold">Payment settings</div>

            <Field>
              <Label htmlFor="instructions">
                Instructions <Field.ErrorTooltip />
              </Label>

              <Input
                name="instructions"
                id="instructions"
                placeholder="Please submit remittance to: "
                onChange={onChange}
                value={formValues.instructions}
              />
            </Field>

            <Field>
              <Label htmlFor="institution">
                ACH institution <Field.ErrorTooltip />
              </Label>

              <Input
                name="institution"
                id="institution"
                placeholder="Bank name"
                onChange={onChange}
                value={formValues.institution}
              />
            </Field>

            <Field>
              <Label htmlFor="accountNumber">
                ACH Account # <Field.ErrorTooltip />
              </Label>

              <Input
                name="accountNumber"
                id="accountNumber"
                onChange={onChange}
                value={formValues.accountNumber}
              />
            </Field>

            <Field>
              <Label htmlFor="routingNumber">
                ACH Routing # <Field.ErrorTooltip />
              </Label>

              <Input
                name="routingNumber"
                id="routingNumber"
                onChange={onChange}
                value={formValues.routingNumber}
              />
            </Field>
          </div>
        </div>

        {error && (
          <div className="my-2 p-4 bg-red-200 text-red-600 rounded-md">
            {error}
          </div>
        )}

        <div className="mt-6">
          <Button
            type="submit"
            disabled={isSaved || !!error}
            className="btn blue w-40 !text-17">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
