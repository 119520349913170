import * as React from 'react'
import {SVG, Toggle, Pressable, copyText} from '@quotefactory/ui'
import {AlwaysScrollToMe, ProgressBar} from 'components'

const ResultRow = ({result, levelsToShow, onClick}) => {
  const {level, msg, props} = result
  const classes = ['inline-block', 'text-13']
  classes.push(
    {
      info: 'text-[#ABB2BF] hover:text-white',
      warn: 'text-[#D19A66] hover:text-[#FFC690]',
      error: 'text-[#E06C75] hover:text-[#F98D96]',
    }[level],
  )

  if (!levelsToShow.includes(level)) {
    classes.push('hidden')
  }
  return (
    <button
      type="button"
      className={classes.join(' ')}
      onClick={() => onClick(props)}>
      {msg}
    </button>
  )
}

const ResultsConsole = ({levelsToShow, results, onClick}) => {
  return (
    <div className="flex-1 p-8 flex flex-col items-start gap-1 max-h-40">
      {results.map((result, idx) => (
        <ResultRow
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          result={result}
          levelsToShow={levelsToShow}
          onClick={onClick}
        />
      ))}
      <AlwaysScrollToMe />
    </div>
  )
}

export default function ResultsPanel({
  runState,
  results,
  stats,
  progress,
  runControls,
  onResultClick,
}) {
  const [levelsToShow, setLevelsToShow] = React.useState([
    'info',
    'warn',
    'error',
  ])

  const toggleLevel = (evt, checked) => {
    const level = evt.target.id
    if (levelsToShow.includes(level)) {
      setLevelsToShow((prev) => prev.filter((l) => l !== level))
    } else {
      setLevelsToShow((prev) => [...prev, level])
    }
  }
  const copyResultsToClipboard = () => {
    copyText(
      results
        .map((r) => (levelsToShow.includes(r.level) ? r.msg : ''))
        .filter(Boolean)
        .join('\n'),
    )
  }

  return (
    <div
      className={`flex-1 flex flex-col transition-opacity ${
        progress > 0 ? 'opacity-100' : 'opacity-50'
      }`}>
      <div className="px-4 pt-4">
        {runState === 'finished' ? (
          <div className="bg-green-500 text-white p-2 font-bold flex items-center gap-2 rounded-md -mx-2">
            <SVG name="check" />
            Finished importing
          </div>
        ) : (
          <ProgressBar label="Import" progress={progress} />
        )}
      </div>

      <div className="flex-1 flex flex-col relative">
        <div className="rounded-md m-2 flex flex-col bg-[#282C34] flex-1 h-0 grow overflow-auto ">
          <ResultsConsole
            results={results}
            levelsToShow={levelsToShow}
            onClick={onResultClick}
          />
        </div>
        <Pressable
          className="flex items-center gap-1 bg-white/20 hover:bg-white active:bg-blue-500 active:text-white rounded-full py-1 px-2 absolute bottom-4 right-7"
          onClick={copyResultsToClipboard}>
          <SVG name="copy" className="-mt-0.5 w-5 h-5" />
          Copy
        </Pressable>
      </div>
      <div className="flex items-center gap-4 pl-3 pr-5 pb-3">
        <div className="flex-1 h-8 flex items-center">
          {['running', 'paused'].includes(runState) && (
            <div className="flex items-center gap-2">
              {runState === 'running' && (
                <Pressable
                  onClick={runControls.pause}
                  className="hover:bg-gray-900/20 w-12 h-8 self-stretch flex items-center justify-center rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g fill="currentColor" fillRule="evenodd">
                      <path d="M4.222 4h5.333v16H4.222zM14.444 4h5.333v16h-5.333z" />
                    </g>
                  </svg>
                </Pressable>
              )}
              {runState === 'paused' && (
                <Pressable
                  onClick={runControls.resume}
                  className="hover:bg-gray-900/20 w-12 h-8 self-stretch flex items-center justify-center rounded animate-pulse">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="m5.244 4 13.512 8.25L5.244 20z"
                    />
                  </svg>
                </Pressable>
              )}
              <Pressable
                onClick={runControls.stop}
                className="hover:bg-gray-900/20 hover:text-red-600 w-12 h-8 self-stretch flex items-center justify-center rounded">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M4.141 4h15.717v16H4.141z"
                  />
                </svg>
              </Pressable>
            </div>
          )}
        </div>
        <div className="flex items-center gap-8">
          <div className="flex items-center gap-1">
            <div
              className={`flex items-center gap-1 ${
                levelsToShow.includes('info') ? 'opacity-100' : 'opacity-30'
              }`}>
              <div className="w-3 h-3 rounded-full bg-[#ABB2BF]" />
              <div>Info </div>
              <div className="bg-black-900/5 min-w-[20px] text-13 text-black-900/50 px-1 py-0.5 font-bold rounded">
                {stats.info.toLocaleString()}
              </div>
            </div>
            <Toggle
              id="info"
              name="info"
              className="small"
              checked={levelsToShow.includes('info')}
              onChange={toggleLevel}
            />
          </div>

          <div className="flex items-center gap-1">
            <div
              className={`flex items-center gap-1 ${
                levelsToShow.includes('warn') ? 'opacity-100' : 'opacity-30'
              }`}>
              <div className="w-3 h-3 rounded-full bg-[#D19A66]" />
              <div>Warning </div>
              <div className="bg-black-900/5 min-w-[20px] text-13 text-black-900/50 px-1 py-0.5 font-bold rounded">
                {stats.warn.toLocaleString()}
              </div>
            </div>
            <Toggle
              id="warn"
              name="warn"
              className="small"
              checked={levelsToShow.includes('warn')}
              onChange={toggleLevel}
            />
          </div>

          <div className="flex items-center gap-1">
            <div
              className={`flex items-center gap-1 ${
                levelsToShow.includes('error') ? 'opacity-100' : 'opacity-30'
              }`}>
              <div className="w-3 h-3 rounded-full bg-[#E06C75]" />
              <div>Error</div>
              <div className="bg-black-900/5 min-w-[20px] text-13 text-black-900/50 px-1 py-0.5 font-bold rounded">
                {stats.error.toLocaleString()}
              </div>
            </div>
            <Toggle
              id="error"
              name="error"
              className="small"
              checked={levelsToShow.includes('error')}
              onChange={toggleLevel}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
