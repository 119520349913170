import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'
import api from 'services/api'
import {errorPayload} from 'helpers'

///////////////////////////////////////////////////////////////////////////////
// Async middleware
///////////////////////////////////////////////////////////////////////////////

export const fetchProviderIntegrations = createAsyncThunk(
  'fetchProviderIntegrations',
  async (accountId, {rejectWithValue}) => {
    try {
      const result = await api.fetchAccountProviderIntegrations(accountId)
      return result
    } catch (err) {
      // This will set the `payload`
      return rejectWithValue(errorPayload(err))
    }
  },
)

///////////////////////////////////////////////////////////////////////////////
// Slice
///////////////////////////////////////////////////////////////////////////////

export const entityAdapter = createEntityAdapter()

const initialState = entityAdapter.getInitialState({
  isLoading: true,
})

const slice = createSlice({
  name: 'providerIntegrations',
  initialState,
  reducers: {
    clearProviderIntegrations: entityAdapter.removeAll,
  },
  extraReducers: (builder) => {
    // fetchProviderIntegrations
    builder.addCase(fetchProviderIntegrations.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchProviderIntegrations.fulfilled, (state, action) => {
      state.isLoading = false
      if (!action.payload.error) {
        entityAdapter.upsertMany(state, action)
      }
    })
  },
})

///////////////////////////////////////////////////////////////////////////////
// Selectors
///////////////////////////////////////////////////////////////////////////////
export const {
  selectById: selectProviderIntegrationById,
  selectAll: selectAllProviderIntegrations,
} = entityAdapter.getSelectors((state) => state.providerIntegrations)

export const selectProviderIntegrations = createSelector(
  [selectAllProviderIntegrations],
  (entities) => {
    return entities.sort((a, b) => (a.name || '').localeCompare(b.name))
  },
)

export const {clearProviderIntegrations} = slice.actions

export default slice.reducer
