import React from 'react'
import {Layout} from 'pages'

export default function NotFound({location}) {
  return (
    <Layout>
      <div className="flex h-screen">
        <div className="m-auto text-center">
          <h1 className="text-8xl pb-4">💩</h1>
          <h1 className="text-8xl">404</h1>
          <p className="text-xl">
            <span className="font-semibold">{location.pathname}</span> == #FAIL
          </p>
        </div>
      </div>
    </Layout>
  )
}
