/**
 * Determines the items that are different in two arrays.
 *
 * (symmetric difference using strict equality).
 *
 * @template T
 * @param {T[]} left
 * @param {T[]} right
 */
// eslint-disable-next-line import/prefer-default-export
export const difference = (left, right) => {
  /** @type {Set<T>} */
  const result = new Set()

  for (const item of left) {
    if (!right.includes(item)) {
      result.add(item)
    }
  }

  for (const item of right) {
    if (!left.includes(item)) {
      result.add(item)
    }
  }

  return Array.from(result)
}
