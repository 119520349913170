import React from 'react'
import {createFormatterFn} from '@quotefactory/ui'

import {QFIconWithCopy} from 'components/ui'

export const formatAsUSDsansDecimals = createFormatterFn({
  locale: 'en-US',
  currency: 'USD',
  decimalPlaces: 0,
})

export default function AccountBillingContact({contact}) {
  if (!contact) {
    return null
  }
  return (
    <div className="text-15 p-3 mb-3">
      <div className="flex items-center justify-between border-b border-gray-300 pb-2 mb-3">
        <div className="text-black-100 text-12 up font-semibold flex items-center space-x-1">
          <span>Billing Contact</span>
        </div>
      </div>
      <div className="">
        {contact.fullName && (
          <div className="font-semibold">{contact.fullName}</div>
        )}
        {contact.phone && (
          <div className="flex flex-col space-y-1">
            <div className="flex items-center">
              <a
                href={`tel:${contact.phone}`}
                target="_blank"
                className="font-semibold hover:text-blue-500 relative z-10"
                rel="noreferrer">
                {contact.phone}
              </a>
            </div>
          </div>
        )}
        {contact.email && (
          <div className="flex flex-col space-y-1">
            <div className="flex items-center">
              <a
                href={`mailto:${contact.email}`}
                target="_blank"
                className="font-semibold hover:text-blue-500 relative z-10"
                rel="noreferrer">
                {contact.email}
              </a>
              <QFIconWithCopy icon="copy" text={contact.email} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
