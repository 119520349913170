import React from 'react'
import PropTypes from 'prop-types'
import {escapeRegex} from '../../helpers/regex'

export default function QFHighlight({content, match}) {
  if (!match) {
    return content
  }

  const matchRegExp = new RegExp(escapeRegex(match), 'ig')
  const allMatches = [...content.matchAll(matchRegExp)]
  const parts = match === '' ? [content] : content.split(matchRegExp)

  return (
    <>
      {parts.map((part, i) => {
        const markPart = allMatches.pop()
        return (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i} className="whitespace-pre">
            {part}
            {markPart && <mark>{markPart}</mark>}
          </span>
        )
      })}
    </>
  )
}

QFHighlight.propTypes = {
  content: PropTypes.string,
  match: PropTypes.string,
}

QFHighlight.defaultProps = {
  content: '',
  match: null,
}
