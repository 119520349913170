/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import isEqual from 'react-fast-compare'
import {useDebounce} from 'use-debounce'
import {TextHighlight, ComboBox} from '@quotefactory/ui'
import {areaToString, parseAreaKey} from '../helpers/place'
import {useGetAreasQuery} from '../services/rtk-api'

const useAreaOptions = ({area, search}) => {
  const [debouncedSearch] = useDebounce(search, 300)

  const {data: areas = []} = useGetAreasQuery(
    {
      s: debouncedSearch,
    },
    {
      skip: !debouncedSearch || debouncedSearch.length === 0,
    },
  )

  const options = React.useMemo(() => {
    if (debouncedSearch === '') {
      return []
    }

    const areasList = [
      area,
      ...areas.filter((a) => !isSameArea(area, a)),
    ].filter((a) => a && a.key)

    return areasList.map((a) => ({
      ...a,
      value: a.key,
      label: areaToString(a),
    }))
  }, [areas, area, debouncedSearch])

  return options
}

function AreaLookupComboBoxInput({
  name,
  id = name,
  className,
  value,
  defaultValue,
  onChange: suppliedOnChange,
  noOptions,
  ...props
}) {
  const [inputValue, setInputValue] = React.useState(
    areaToString(parseAreaKey(value)),
  )

  /**
   * EFFECT: sync inputValue when area updates
   */
  React.useEffect(() => {
    if (value) {
      setInputValue(areaToString(parseAreaKey(value)))
    }
  }, [value])

  const handleChange = React.useCallback(
    (event, option) => {
      if (suppliedOnChange) {
        suppliedOnChange(event, option ?? null)
      }
    },
    [suppliedOnChange],
  )

  const options = useAreaOptions({area: value, search: inputValue})

  const handleInputChange = React.useCallback(
    (event, nextInputValue, reason) => {
      if (reason !== 'reset') {
        setInputValue(nextInputValue)

        if (value) {
          suppliedOnChange(event, null)
        }
      }
    },
    [setInputValue, options, value],
  )

  return (
    <ComboBox
      {...props}
      id={id}
      name={name}
      className={className}
      value={value}
      options={options}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getOptionLabel={areaToString}
      inputValue={inputValue}
      filterOptions={(option) => option}
      noOptionsText={noOptions}
      renderOption={(option, {inputValue: currentInputValue, selected}) => {
        const optionLabel = areaToString(option)

        if (selected) {
          return <span>{optionLabel}</span>
        }

        return <TextHighlight content={optionLabel} match={currentInputValue} />
      }}
    />
  )
}

function isSameArea(a, b) {
  return a?.key === b?.key
}

export default React.memo(AreaLookupComboBoxInput, isEqual)
