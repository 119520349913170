import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  fetchAccounts,
  selectAccounts,
  selectAccountById,
} from 'reducers/account'
import {FilterPill, FilterSection, FilterSelect} from 'components/filters'

export default function FilterAccount({onFilterChange, brokerId, isDesktop}) {
  const reduxDispatch = useDispatch()
  const {loadedAt} = useSelector((state) => state.account)
  const brokers = useSelector(selectAccounts) || []
  const broker =
    useSelector((state) => selectAccountById(state, brokerId)) || {}

  const UiComponent = isDesktop ? FilterPill : FilterSection

  useEffect(() => {
    if (!loadedAt) {
      reduxDispatch(fetchAccounts())
    }
  }, [])

  // If possible, limit the list of options to distinct values that exist in our data
  const options = brokers.map((item) => ({
    value: item.id,
    label: item.name,
    img: {url: item.logoUrl, name: item.name},
  }))

  const onSelect = (thisBrokerId) => {
    onFilterChange({brokerId: thisBrokerId})
  }

  const clearFilter = () => {
    onFilterChange({brokerId: null})
  }

  return (
    <UiComponent
      title="Broker"
      label={broker?.name || 'Broker'}
      active={!!broker?.name}
      closeOnChange
      clearFilter={clearFilter}>
      <FilterSelect
        entity="broker"
        placeholder="select a broker"
        options={options}
        selectedValue={brokerId}
        width={300}
        onSelect={onSelect}
      />
    </UiComponent>
  )
}
