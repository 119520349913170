import '@babel/polyfill'

import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import Helmet from 'react-helmet'
import {Provider as FetchProvider} from 'use-http'

import store from './services/store'
import auth0 from './services/auth0'
import Routes from './routes'

import '../assets/stylesheets/tailwind.css'
import '../assets/stylesheets/application.css'
import '../assets/stylesheets/admin.css'

function App() {
  return <Provider store={store}>
    <FetchProvider
      url={config.QF_API_URL}
      options={{
        interceptors: {
          request: async ({options, url, path, route}) => {
            const accountId = window.localStorage.getItem('accountId')
            const userId = window.localStorage.getItem('userId')

            try {
              const token = await auth0.getTokenSilently({
                customAccountId: accountId,
                customUserId: userId,
              })

              options.headers.Authorization = `Bearer ${token}`

              return options
            } catch (err) {
              return options
            }
          },
        },
      }}>
      <Helmet titleTemplate="%s | QF Admin" defaultTitle="QF Admin" />
      <Routes />
    </FetchProvider>
  </Provider>
}

const root = createRoot(document.getElementById('root'))
root.render(<App />)
