import React, {useRef} from 'react'

function ProviderLogo({code, name, className = ''}) {
  const attempts = useRef(0)
  if (!code) return null
  return (
    <img
      src={`${config.QF_CDN_URL}/images/carriers/${code
        ?.toString()
        .toLowerCase()}.png`}
      alt={name || code}
      className={`carrier-logo ${className}`}
      onError={(ev) => {
        if (attempts.current < 3) {
          ev.target.src = '/assets/images/carriers/default.png'
          attempts.current += 1
        }
      }}
    />
  )
}

export default React.memo(ProviderLogo)
