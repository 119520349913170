import * as React from 'react'
import api from 'services/api'
import {downloadBlob, filenameWithTimestamp} from '../helpers/files'

export default function useRunReport() {
  const [isRunning, setIsRunning] = React.useState(false)
  const [error, setError] = React.useState()
  const [result, setResult] = React.useState()
  const [data, setData] = React.useState()

  const clearData = () => setData(undefined)

  const run = async ({reportName, format = 'csv', runAsync, params}) => {
    const filename = filenameWithTimestamp({baseName: reportName, format})

    setIsRunning(true)
    setError(undefined)

    try {
      const res = await api.runReport({
        reportName,
        runAsync,
        params: {...params, filename},
      })
      if (res.status !== 200) {
        throw new Error(res.status)
      }
      if (format === 'json') {
        const json = await res.json()
        setData(json)
      } else if (runAsync) {
        const json = await res.json()
        setResult(json)
      } else {
        const fileBlob = await res.blob()
        downloadBlob(fileBlob, filename)
        setResult({msg: "CSV file is now in your browser's downloads folder"})
      }
    } catch (err) {
      console.error('Report failed')
      setError(`Report failed. ${err.message}`)
    } finally {
      setIsRunning(false)
    }
  }

  return [run, {isRunning, result, data, clearData, error}]
}
