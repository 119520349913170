/* eslint-disable react/jsx-props-no-spreading */
import React, {useState, useEffect, useMemo} from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {breakpoints} from 'style-vars'
import {Modal, SVG} from '@quotefactory/ui'
import {FiltersPanel} from 'components/filters'

export default function Filters({entityType, filters, setFilters}) {
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.ml}px)`)

  const [isFiltered, setIsFiltered] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const filterKeys = useMemo(() => new Set(Object.keys(filters)), [])

  useEffect(() => {
    setIsFiltered(!!Object.keys(filters).find((key) => filters[key]))
  }, [filters])

  const clearAllFilters = () => {
    setFilters({search: ''})
  }

  const toggleFilters = () => {
    setIsOpen(!isOpen)
  }

  if (isDesktop) {
    return (
      <div className="filters flex items-center justify-between">
        {isFiltered && (
          <button
            type="button"
            className="text-gray-700 hover:text-red-500 px-2"
            onClick={clearAllFilters}
            alt="Clear filters">
            <SVG name="close" className="w-5 h-5" />
          </button>
        )}
        <div className="flex items-center justify-between flex-1">
          <FiltersPanel
            entityType={entityType}
            filterKeys={filterKeys}
            filters={filters}
            setFilters={setFilters}
            isFiltered={isFiltered}
            isDesktop={isDesktop}
          />
        </div>
      </div>
    )
  }
  return (
    <div className="filters flex items-center ml-auto pr-2 sm:pr-6">
      <div
        className={`button-wrapper min-w-0 ${isFiltered ? 'active' : ''} ${
          isOpen ? 'focused' : ''
        }`}>
        <button type="button" className="truncate" onClick={toggleFilters}>
          Filters{' '}
        </button>
        <button type="button" onClick={toggleFilters} className="adornment">
          <SVG name={isOpen ? 'arrowUp' : 'arrowDown'} />
        </button>
      </div>
      <Modal isOpen={isOpen} onClose={toggleFilters} fullScreen>
        <Modal.Content className="flex-1 w-full md:w-full max-h-screen">
          <div className="filters-mobile p-4">
            <div className="flex items-center pb-4">
              <h6 className="font-bold flex-1 py-2">
                Filters{' '}
                {isFiltered && (
                  <button
                    type="button"
                    className="text-gray-900 hover:text-blue-500 px-2 text-sm"
                    onClick={clearAllFilters}
                    alt="Clear filters">
                    clear
                  </button>
                )}
              </h6>
            </div>

            <FiltersPanel
              entityType={entityType}
              filterKeys={filterKeys}
              filters={filters}
              setFilters={setFilters}
              isFiltered={isFiltered}
              isDesktop={isDesktop}
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}
