import React from 'react'
import PropTypes from 'prop-types'
import {Avatar} from 'components'

export default function UserAvatar({
  user = {photoUrl: null, username: null},
  size = 8,
  className,
}) {
  const url = user.photoUrl
  const title = user.fullName || user.username
  if (!title) return null
  return <Avatar url={url} title={title} size={size} className={className} />
}

UserAvatar.propTypes = {
  user: PropTypes.shape({
    photoUrl: PropTypes.string,
    username: PropTypes.string,
  }),
  size: PropTypes.number,
}
