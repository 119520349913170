import React from 'react'
import {Timestamp} from '@quotefactory/ui'
import {UserAvatar} from 'components'

export default function AccountInvitee({invite}) {
  if (!invite) return null
  const {
    // id,
    email,
  } = invite

  return (
    <div className="flex items-center opacity-40">
      <UserAvatar user={{username: invite.email}} size={4} />
      <div className="ml-4 leading-6 space-y-1">
        <div>{email}</div>
        <div className="text-indigo-600 text-sm">
          Invited <Timestamp timestamp={invite.createdAt} format="distance" />
        </div>
      </div>
    </div>
  )
}
