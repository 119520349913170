/* eslint-disable react/jsx-props-no-spreading */
/* Component copied from qf-app */
import * as React from 'react'
import {
  SVG,
  Button,
  Field,
  InputWrapper,
  NumberInput,
  triggerInputEvent,
} from '@quotefactory/ui'

export default function OverrideGlobalNumberField({
  id,
  label,
  units,
  decimals,
  localValue,
  globalValue,
  ...props
}) {
  const inputRef = React.useRef()

  const thisLocalValue = localValue ?? undefined
  const thisGlobalValue = `${globalValue ?? ''}`

  // Trashcan click handler
  const removeOverride = () => {
    const target = inputRef.current
    triggerInputEvent(target, '')
    props?.onBlur({target})
  }

  return (
    <Field className="!py-0">
      {label && (
        <label
          htmlFor={id}
          className="w-full pb-1 flex items-center z-10 text-black-300 text-13">
          {label}
          <Field.ErrorTooltip />
          <Button
            className={`ml-auto hover:text-black-600 ${
              thisLocalValue !== undefined ? '' : 'text-transparent'
            }`}
            onClick={removeOverride}>
            <SVG name="trash" className="w-5 h-5" />
          </Button>
        </label>
      )}
      <InputWrapper
        className={`field-invalid:border-red-500 field-invalid:bg-white justify-end w-28 ${
          thisLocalValue !== undefined
            ? 'bg-white border-gray-400'
            : 'bg-transparent border-dashed border-gray-500'
        }`}
        revealOnHover>
        <NumberInput
          id={id}
          ref={inputRef}
          step={0.1}
          largeStep={1}
          options={{
            minimumFractionDigits: 0,
            maximumFractionDigits: decimals ?? 0,
          }}
          defaultValue={thisLocalValue}
          placeholder={thisGlobalValue}
          className={`w-[6ch] border-none outline-none text-right ${
            thisLocalValue !== undefined
              ? 'custom'
              : 'global text-black-300 focus:text-black-500 placeholder:text-black-100'
          }`}
          {...props}
        />
        <div className="text-gray-800 ml-px select-none">{units}</div>
      </InputWrapper>
    </Field>
  )
}
