import * as React from 'react'
import {FileUploader} from 'react-drag-drop-files'
import {SVG} from '@quotefactory/ui'

const FILE_TYPES = ['CSV']

const DropZone = ({disabled, text}) => {
  const label = text || `Select ${FILE_TYPES.join(', ')}`
  return (
    <div className="flex-1 flex flex-col">
      <div
        className={`flex-1 flex items-center justify-center p-4 border-2 border-dashed border-gray-400 text-lg text-black-500 font-medium rounded-md ${
          disabled ? 'opaity-30' : ''
        } ${
          !disabled && 'hover:bg-gray-50 hover:text-gray-900'
        } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:border-blue-700 transition duration-150 ease-in-out cursor-pointer`}>
        <SVG name="upload" size={32} lineWidth={1.5} />
        <div className="ml-2">{label}</div>
      </div>
    </div>
  )
}

function useFileDragDrop() {
  const [file, setFile] = React.useState(null)
  const [error, setError] = React.useState(null)

  const reset = () => {
    setError(null)
    setFile(null)
  }

  const handleChange = (selectedFile) => {
    setFile(selectedFile)
  }

  const onTypeError = (err) => {
    setError(err)
  }

  const FileSelector = ({disabled, dropZoneText}) => (
    <div className="flex flex-col flex-1">
      {error && <div className="text-red-500">{error}</div>}
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={FILE_TYPES}
        onTypeError={onTypeError}
        disabled={disabled}
        classes="flex-1 flex flex-col">
        <DropZone disabled={disabled} text={dropZoneText} />
      </FileUploader>
    </div>
  )

  return {
    file,
    FileSelector,
    reset,
  }
}

export default useFileDragDrop
