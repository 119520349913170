import React, {useRef} from 'react'
import {CSSTransition} from 'react-transition-group'

export default function Transition({
  show,
  enter,
  enterFrom,
  enterTo,
  leave,
  leaveFrom,
  leaveTo,
  children,
}) {
  const enterClasses = enter.split(' ')
  const enterFromClasses = enterFrom.split(' ')
  const enterToClasses = enterTo.split(' ')
  const leaveClasses = leave.split(' ')
  const leaveFromClasses = leaveFrom.split(' ')
  const leaveToClasses = leaveTo.split(' ')

  const nodeRef = useRef(null)
  return (
    <CSSTransition
      unmountOnExit
      nodeRef={nodeRef}
      in={show}
      addEndListener={(done) => {
        nodeRef.current.addEventListener('transitionend', done, false)
      }}
      onEnter={() => {
        nodeRef.current.classList.add(...enterClasses, ...enterFromClasses)
      }}
      onEntering={() => {
        nodeRef.current.classList.remove(...enterFromClasses)
        nodeRef.current.classList.add(...enterToClasses)
      }}
      onEntered={() => {
        nodeRef.current.classList.remove(...enterToClasses, ...enterClasses)
      }}
      onExit={() => {
        nodeRef.current.classList.add(...leaveClasses, ...leaveFromClasses)
      }}
      onExiting={() => {
        nodeRef.current.classList.remove(...leaveFromClasses)
        nodeRef.current.classList.add(...leaveToClasses)
      }}
      onExited={() => {
        nodeRef.current.classList.remove(...leaveToClasses, ...leaveClasses)
      }}>
      <div ref={nodeRef}>{children}</div>
    </CSSTransition>
  )
}
