import React, {useRef, useState} from 'react'
import {Popper} from '@quotefactory/ui'
import {SVG} from 'components'

/* An individual sort option */
function OptionItem({value, text, selected, onClick}) {
  return (
    <li className={`item ${selected ? 'active' : ''}`}>
      <button type="button" onClick={() => onClick(value)}>
        {text}
      </button>
    </li>
  )
}

function SortButton({title = 'Sort by', options = [], selectedSort, onChange}) {
  const [menuAnchor, setMenuAnchor] = useState(null)
  const openedRef = useRef(false)
  const sortDir = selectedSort.split(':')[1]

  const handleClick = (evt) => {
    const {parentElement} = evt.currentTarget
    if (menuAnchor) {
      setMenuAnchor(null)
    } else {
      setMenuAnchor(parentElement)
      if (!openedRef.current) {
        openedRef.current = true
      }
    }
  }

  const handleMenuClose = (evt) => {
    setMenuAnchor(null)
  }

  const onSelection = (value) => {
    onChange(value)
    handleMenuClose()
  }

  const label =
    options.find((o) => o.value === selectedSort)?.label || 'Sort by'

  return (
    <>
      <div
        className={`sort-button min-w-0 my-4 ${selectedSort ? 'active' : ''} ${
          !menuAnchor ? '' : 'focused'
        }`}>
        <button type="button" className="truncate" onClick={handleClick}>
          <span className="text-black-100">
            Sort by: <span className="text-black-500">{label}</span>{' '}
          </span>
          <SVG
            name={sortDir === 'ASC' ? 'arrowAsc' : 'arrowDesc'}
            className="adornment text-black-500"
          />
        </button>
      </div>

      <Popper
        placement="bottom-end"
        anchorEl={menuAnchor}
        isOpen={!!menuAnchor}
        onClose={handleMenuClose}>
        <>
          <div className="flex items-center justify-between mb-4">
            <strong className="pr-4 text-base">{title}</strong>
            <button type="button" onClick={handleClick} className="icon">
              <SVG name="close" className="w-5" />
            </button>
          </div>

          <ul className="menu-list -mx-2 w-60">
            {options.map(({value, text}) => (
              <OptionItem
                key={value}
                value={value}
                text={text}
                selected={value === selectedSort}
                onClick={onSelection}
              />
            ))}
          </ul>
        </>
      </Popper>
    </>
  )
}

export default SortButton
