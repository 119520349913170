import * as React from 'react'
import {Button} from '@quotefactory/ui'

export default function AccountIntegrations({onRun, isRunning}) {
  const onSubmit = () => {
    onRun({
      reportName: 'AccountIntegrations',
    })
  }

  return (
    <>
      <h2>Account Integrations</h2>
      <Button className="mt-4 !px-10" loading={isRunning} onClick={onSubmit}>
        Run
      </Button>
    </>
  )
}
