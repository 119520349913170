import React from 'react'
import auth0 from '../services/auth0'

//////////////////////////////////////////////////////////////////////
// This component kicks off a login attempt by redirecting to Auth0.
//////////////////////////////////////////////////////////////////////

export default function Login() {
  const handleClick = async () => {
    await auth0.loginWithRedirect()
  }

  return (
    <div className="p-8">
      <button onClick={handleClick} type="button" className="btn">
        Login
      </button>
    </div>
  )
}
