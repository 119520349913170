import React from 'react'
import {FilterPill, FilterSection} from 'components/filters'
import {SVG} from 'components'

const DEFAULT_VALUE = 'exclude'

export default function FilterInterline({onFilterChange, value, isDesktop}) {
  const filterValue = value || DEFAULT_VALUE

  const UiComponent = isDesktop ? FilterPill : FilterSection

  /* An individual filter option */
  const FilterItem = ({thisValue, children}) => {
    const selected = thisValue === filterValue
    return (
      <li className={`item ${selected ? 'active' : ''}`}>
        <button
          type="button"
          onClick={() => {
            onFilterChange({
              interline: thisValue,
            })
          }}>
          {children}
        </button>
      </li>
    )
  }

  return (
    <UiComponent
      title={
        <div className="inline-flex items-center">
          <SVG name="interline" className="mr-2" />
          Interline
        </div>
      }
      label={
        <div className="inline-flex items-center">
          <SVG name="interline" className="mr-2" />
          Interline
        </div>
      }
      active={filterValue !== 'exclude'}
      clearFilter={() => onFilterChange({interline: ''})}>
      <ul className="menu-list -mx-2 w-64">
        <FilterItem thisValue="exclude">
          <div className="flex items-center">Direct only</div>
        </FilterItem>
        <FilterItem thisValue="include">
          <div className="flex items-center">Interline only</div>
        </FilterItem>
      </ul>
    </UiComponent>
  )
}
