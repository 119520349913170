/* eslint-disable react/jsx-props-no-spreading */
import React, {forwardRef, useMemo} from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {colors} from 'style-vars'
import QFHighlight from 'ui/QFHighlight'
import MUIThemeProvider from './MUIThemeProvider'
import SVG from '../Svg'

export const themeOverrides = {
  MuiInputLabel: {
    outlined: {
      color: 'red',
    },
  },
  MuiAutocomplete: {
    option: {
      color: colors.black[500],
      '&[data-focus="true"]': {
        backgroundColor: colors.gray[200],
        '&:hover': {
          backgroundColor: colors.gray[200],
        },
      },
      '&[aria-selected="true"]': {
        backgroundColor: colors.blue[500],
        color: '#FFFFFF',
        '&:hover': {
          backgroundColor: colors.blue[400],
          color: '#FFFFFF',
        },
        '&[data-focus="true"]': {
          backgroundColor: colors.blue[500],
          color: '#FFFFFF',
        },
      },
    },
    listbox: {
      lineHeight: '1.5rem',
    },
    noOptions: {
      color: colors.gray[900],
    },
    paper: {
      boxShadow:
        '0px 5px 5px -3px rgba(21,35,45,0.2), 0px 8px 10px 1px rgba(21,35,45,0.14), 0px 3px 44px 2px rgba(21,35,45,0.12)',
    },
  },
}

const QFAutocomplete = forwardRef((props, ref) => {
  const {
    name,
    value,
    onChange,
    onBlur,
    onInputChange,
    options,
    isLoading,
    placeholder,
    onClose,
    onOpen,
    isOpen,
    inputValue,
    autoSelect,
    inputMode,
    pattern,
    freeSolo,
    renderOption,
    getOptionLabel,
    getOptionSelected,
    filterOptions,
    className,
    isDisabled,
  } = props

  // eslint-disable-next-line react/destructuring-assignment
  const id = props.id || name

  const handleChange = (event, option) => {
    onChange(option?.value)
  }

  const handleInputChange = (event) => {
    const nextValue = event?.currentTarget?.value

    if (nextValue) {
      onInputChange(nextValue)
    }
  }
  const selectedOption = useMemo(() => {
    return options.find((option) => option.value === value) || null
  }, [value, options])

  return (
    <MUIThemeProvider overrides={themeOverrides}>
      <Autocomplete
        options={options}
        getOptionLabel={getOptionLabel || ((option) => option.label || '')}
        getOptionSelected={
          getOptionSelected ||
          ((option, val) => {
            return option === val
          })
        }
        style={{width: '100%'}}
        value={selectedOption}
        autoSelect={autoSelect}
        autoHighlight
        loading={isLoading}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onBlur={onBlur}
        onClose={onClose}
        onOpen={onOpen}
        open={isOpen}
        inputValue={inputValue}
        ref={ref}
        disabled={isDisabled}
        freeSolo={freeSolo}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <div
            ref={params.InputProps.ref}
            className="select qfautocomplete relative">
            <input
              style={{width: '100%'}}
              id={id}
              type="text"
              name={name}
              inputMode={inputMode}
              pattern={pattern}
              placeholder={placeholder}
              {...params.inputProps}
              className={`${params.inputProps.className} ${className} input`}
            />
            <div className="drop-arrow absolute inset-y-0 right-0 pr-1 flex items-center pointer-events-none text-gray-500">
              <SVG name="arrowDown" className="transform" />
            </div>
          </div>
        )}
        renderOption={
          renderOption ||
          ((option, {inputValue: currentInputValue, selected}) => {
            return selected ? (
              <span>{option.label}</span>
            ) : (
              <QFHighlight content={option.label} match={currentInputValue} />
            )
          })
        }
      />
    </MUIThemeProvider>
  )
})

export default QFAutocomplete

QFAutocomplete.displayName = 'QFAutocomplete'

QFAutocomplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]),
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  inputValue: PropTypes.string,
  autoSelect: PropTypes.bool,
  freeSolo: PropTypes.bool,
  renderOption: PropTypes.func,
  filterOptions: PropTypes.func,
  isDisabled: PropTypes.bool,
}

QFAutocomplete.defaultProps = {
  value: undefined,
  isLoading: false,
  placeholder: undefined,
  onInputChange: () => {},
  onOpen: undefined,
  onClose: undefined,
  isOpen: undefined,
  inputValue: undefined,
  autoSelect: true,
  freeSolo: false,
  renderOption: undefined,
  filterOptions: undefined,
  isDisabled: false,
}
