import * as React from 'react'
import {navigate, useMatch} from '@reach/router'
import {Modal} from '@quotefactory/ui'
import {Layout} from 'pages'
import {ShipmentDetail} from 'components'
import ShipmentsList from 'components/ShipmentsList'

export default function Shipments({location}) {
  const {shipmentId} = useMatch('/shipments/:shipmentId') || {}
  const isOpen = !!shipmentId

  const handleClickShipment = React.useCallback((id) => {
    navigate(`/shipments/${id}${window.location.search}`)
  }, [])

  const handleCloseShipment = React.useCallback(() => {
    navigate(`/shipments${window.location.search}`)
  }, [])

  return (
    <Layout>
      <ShipmentsList onRowClick={handleClickShipment} />
      <Modal isOpen={isOpen} onClose={handleCloseShipment} shouldTriggerOnCloseWithOutsideClick>
        <Modal.Content>
          <ShipmentDetail
            shipmentId={shipmentId}
            onClose={handleCloseShipment}
          />
        </Modal.Content>
      </Modal>
    </Layout>
  )
}
