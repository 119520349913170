import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'
import api from 'services/api'
import {errorPayload} from 'helpers'

///////////////////////////////////////////////////////////////////////////////
// Async middleware
///////////////////////////////////////////////////////////////////////////////

export const fetchAccounts = createAsyncThunk(
  'fetchAccounts',
  async (light = false, {rejectWithValue}) => {
    try {
      const result = await api.fetchAccounts(light)
      return result
    } catch (err) {
      // This will set the `payload`
      return rejectWithValue(errorPayload(err))
    }
  },
)

export const fetchAccount = createAsyncThunk(
  'fetchAccount',
  async (id, {rejectWithValue}) => {
    try {
      const result = await api.fetchAccount(id)
      return result
    } catch (err) {
      // This will set the `payload`
      return rejectWithValue(errorPayload(err))
    }
  },
)

export const updateAccount = createAsyncThunk(
  'updateAccount',
  async (data, {rejectWithValue}) => {
    try {
      const {accountId, ...props} = data
      const result = await api.updateAccount(accountId, props)
      return result
    } catch (err) {
      return rejectWithValue({
        message: err.message,
        statusCode: err.statusCode,
        fieldErrors: err.fieldErrors,
        apiStack: err.apiStack,
      })
    }
  },
)

export const updateAccountIntegration = createAsyncThunk(
  'updateAccountIntegration',
  async (payload, {rejectWithValue}) => {
    try {
      return await api.updateAccountIntegration(payload)
    } catch (err) {
      return rejectWithValue({
        message: err.message,
        statusCode: err.statusCode,
        apiStack: err.apiStack,
      })
    }
  },
)

///////////////////////////////////////////////////////////////////////////////
// Slice
///////////////////////////////////////////////////////////////////////////////

export const entityAdapter = createEntityAdapter()
const initialState = entityAdapter.getInitialState({
  isLoading: true,
})

const accountSlice = createSlice({
  name: 'account',
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    // fetchAccounts
    builder.addCase(fetchAccounts.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchAccounts.fulfilled, (state, action) => {
      state.isLoading = false
      if (!action.payload.error) {
        entityAdapter.upsertMany(state, action)
      }
    })
    // fetchAccount
    builder.addCase(fetchAccount.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchAccount.fulfilled, (state, action) => {
      state.isLoading = false
      if (!action.payload.error) {
        entityAdapter.upsertOne(state, action.payload)
      }
    })
    // updateAccount
    builder.addCase(updateAccount.fulfilled, (state, action) => {
      if (!action.payload.error) {
        entityAdapter.upsertOne(state, action.payload)
      }
    })
  },
})

///////////////////////////////////////////////////////////////////////////////
// Selectors
///////////////////////////////////////////////////////////////////////////////
export const {
  selectById: selectAccountById,
  selectAll: selectAllAccounts,
  selectIds: selectAllAccountIds,
} = entityAdapter.getSelectors((state) => state.account)

export const selectAccounts = createSelector(
  [selectAllAccounts],
  (entities) => {
    return entities.sort((a, b) => (a.name || '').localeCompare(b.name))
  },
)

export const selectAccountsByType = createSelector(
  [selectAllAccounts, (_, accountType) => accountType],
  (entities, accountType) => {
    return entities
      .filter((e) => e.accountType === accountType)
      .sort((a, b) => (a.name || '').localeCompare(b.name))
  },
)

export default accountSlice.reducer
