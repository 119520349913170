import React from 'react'
import {Checkbox} from '@quotefactory/ui'
import {FilterPill, FilterSection} from 'components/filters'
import {equipmentTypes} from 'helpers/equipment-types'

const ITEMS = equipmentTypes

const FilterEquipmentTypesItem = function({
  label,
  value,
  checked,
  disabled,
  onChange,
}) {
  return (
    <li className="my-2 field no-pad">
      <label className={`flex items-center ${disabled && 'disabled'}`}>
        <Checkbox
          checked={checked}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="ml-3 text-base text-black-600">{label}</span>
      </label>
    </li>
  )
}

/* The checkbox filter panel */
export default function FilterEquipmentType({
  onFilterChange,
  items = '',
  isDesktop,
}) {
  const checkedItems = new Set(items.split(',').filter((i) => i))

  const UiComponent = isDesktop ? FilterPill : FilterSection

  const onChange = ({target}) => {
    console.log({checkedItems, items})

    if (target.checked) {
      checkedItems.add(target.value)
    } else {
      checkedItems.delete(target.value)
    }

    const nextItems = [...checkedItems].join(',')

    console.log({checkedItems, nextItems})

    onFilterChange({equipment: nextItems})
  }

  const clearFilter = () => {
    onFilterChange({equipment: ''})
  }

  const buildLabel = () => {
    if (checkedItems.size > 0) {
      const {label} = ITEMS.find((i) => checkedItems.has(i.value))

      if (checkedItems.size === 1) {
        return `${label}`
      }
      return `${label} +${checkedItems.size - 1}`
    }
    return 'Type'
  }

  return (
    <UiComponent
      title="Equipment types"
      label={buildLabel()}
      active={checkedItems.size > 0}
      clearFilter={clearFilter}>
      <ul>
        {ITEMS.map(({value, label, color}) => (
          <FilterEquipmentTypesItem
            key={value}
            label={label}
            value={value}
            checked={checkedItems.has(value)}
            onChange={onChange}
          />
        ))}
      </ul>
    </UiComponent>
  )
}
