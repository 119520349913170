import {useDispatch, useSelector} from 'react-redux'
import {fetchFeatures} from '../reducers/features'

export default function useFeatures() {
  const reduxDispatch = useDispatch()
  const {isLoading, loadedAt, values} = useSelector((state) => state.features)

  if (!loadedAt && !isLoading) {
    reduxDispatch(fetchFeatures())
  }

  return values
}
