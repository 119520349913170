const errorPayload = (err) => ({
  message: err.message,
  statusCode: err.statusCode,
  fieldErrors: err.fieldErrors,
  apiStack: err.apiStack,
})

export {
  // eslint-disable-next-line import/prefer-default-export
  errorPayload,
}
