import * as React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {parseISO, format} from 'date-fns'
import pluralize from 'pluralize'
import {formatAsUSD, SVG, Card, ClickToCopy} from '@quotefactory/ui'
import {
  fetchShipmentById,
  selectShipmentById,
  uncancelShipmentById,
  xnCloneShipment,
} from 'reducers/shipment'
import ShipmentStatus from 'components/ShipmentStatus'
import Address from 'components/Address'
import Transition from 'components/Transition'
import api from 'services/api'

const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
})

const getPackageTypeLabel = (handlingUnit = {packageType: ''}) => {
  return handlingUnit.packageType.split('_')[0]
}

export default function ShipmentDetail({shipmentId, onClose}) {
  const reduxDispatch = useDispatch()
  const [costDetailsOpen, setCostDetailsOpen] = React.useState(false)

  const {isFetching, shipment} =
    useSelector((state) => selectShipmentById(state, shipmentId)) || {}

  React.useEffect(() => {
    reduxDispatch(fetchShipmentById(shipmentId))
  }, [])

  const [{bolUrl, bolUrlIsLoading}, setBolDownloadUrl] = React.useState({
    bolUrl: null,
    bolUrlIsLoading: false,
  })

  const fetchBolURL = async () => {
    const response = await api.fetchShipmentBOL(shipment.id)
    if (!response.error) {
      setBolDownloadUrl({bolUrl: response.url, bolUrlIsLoading: false})
    } else {
      setBolDownloadUrl({bolUrl: null, bolUrlIsLoading: false})
    }
  }

  React.useEffect(() => {
    if (shipment && !bolUrl && !bolUrlIsLoading) {
      setBolDownloadUrl({bolUrl: null, bolUrlIsLoading: true})
      fetchBolURL()
    }
  }, [shipment])

  // For cloned XN shipments, we want to find the clone so we can link to it.
  const [xnClone, setXnClone] = React.useState()

  const fetchClone = async () => {
    try {
      const cloneShipment = await api.getXnCloneShipment(shipment.id)
      setXnClone(cloneShipment)
    } catch (error) {
      // Move along, folks
    }
  }

  React.useEffect(() => {
    if (shipment?.clonedAt) {
      fetchClone()
    }
  }, [shipment?.clonedAt])

  // A loading UI
  if (isFetching) {
    return (
      <div className="m-16">
        <SVG name="loading" />
      </div>
    )
  }

  if (!shipment) {
    return null
  }
  const {
    id,
    bolNumber,
    originalBOLNumber,
    proNumber,
    broker,
    bookedAt,
    status,
    pickupOn,
    handlingUnits,
    totalWeightLB,
    totalHandlingUnits,
    totalLength,
    origin,
    destination,
    currentExternalId,
    provider,
  } = shipment

  const quote = shipment.quote || shipment.houseQuote || {}

  const equipmentType = shipment.mode === 'ltl' ? 'LTL' : shipment.equipmentType

  const {isExtendedNetwork, effectiveRef: quoteNumber} = quote
  // const isExtendedNetwork = true
  const networkProfit = isExtendedNetwork ? quote.cost - quote.networkCost : 0
  const networkMargin = isExtendedNetwork
    ? Math.round((networkProfit * 10000) / quote.cost) / 100
    : 0
  const bookedDate = bookedAt ? parseISO(bookedAt) : undefined

  let pickupDate = shipment.tracking?.pickedUpAt || pickupOn
  pickupDate = pickupDate ? parseISO(pickupDate) : undefined

  let deliveryDate =
    shipment.tracking?.deliveredAt ||
    destination.date ||
    quote.estimatedDeliveryDate
  deliveryDate = deliveryDate ? parseISO(deliveryDate) : undefined

  const charges = quote.charges || []

  return (
    <Card className="bg-gray-300 overflow-auto sm:overflow-hidden sm:rounded-lg w-[calc(100vw-2rem)] md:w-[800px]">
      <div className="flex items-start justify-between px-8 py-5 sm:px-8">
        <div className="flex items-center gap-4 text-lg font-semibold">
          <a
            target="_blank"
            rel="noreferrer"
            href={`${config.QF_APP_URL}/broker/shipment/${id}?activeAccountId=${broker.id}`}
            className="text-gray-900 hover:text-black-500 flex items-center gap-1">
            {isExtendedNetwork && <SVG name="xn" />}
            <span className="text-black-500">BOL {bolNumber}</span>
            <SVG name="external" className="-mt-0.5 w-5 h-5" />
          </a>

          <ClickToCopy
            className="text-gray-900 hover:text-black-500"
            text={`${window.location.origin}/shipments/${shipment.id}`}>
            <SVG name="copy" className="-mt-0.5 w-5 h-5" />
          </ClickToCopy>

          <div className="-mt-1">
            <a
              className="text-gray-900 hover:text-black-500"
              href={`/shipment/${shipmentId}/json`}
              title="view JSON"
              target="_blank"
              rel="noreferrer">
              {`{}`}
            </a>
          </div>

          {provider?.integrations?.ltlDispatch === 'p44' && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://na12.voc.project44.com/portal/v2/shipment-list?sortBy%5B0%5D%5BfieldName%5D=createdDate&sortBy%5B0%5D%5Bdirection%5D=DESC&search=${bolNumber}`}
              className="text-gray-900 hover:text-black-500 flex items-center gap-1"
              title="p44 Tracking"
              label="p44 Tracking">
              <SVG name="location" className="-mt-0.5 w-5 h-5" />
            </a>
          )}

          {bolUrl && (
            <a
              href={bolUrl}
              className="text-gray-900 hover:text-black-500 flex items-center gap-1">
              <SVG name="download" className="w-5 h-5" />
              <span className="text-12">PDF</span>
            </a>
          )}

          {isExtendedNetwork && !shipment.clonedAt && (
            <button
              type="button"
              onClick={() => {
                reduxDispatch(xnCloneShipment({shipmentId: shipment.id}))
              }}
              className="text-gray-900 hover:text-black-500 flex items-center gap-1">
              <SVG name="copy" className="w-5 h-5" />
              <span className="text-12"> XN Clone</span>
            </button>
          )}

          {isExtendedNetwork && shipment.clonedAt && !xnClone && (
            <button
              type="button"
              className="hover:text-black-500 flex items-center gap-1"
              disabled>
              <SVG name="check" className="w-5 h-5" />
              <span className="text-12">XN Cloned</span>
            </button>
          )}

          {isExtendedNetwork && xnClone && (
            <a
              rel="noreferrer"
              href={`/shipments/${xnClone.id}`}
              className="text-gray-900 hover:text-black-500 flex items-center gap-1">
              <SVG name="check" className="w-5 h-5" />
              <span className="text-12">XN Cloned</span>
            </a>
          )}

          {shipment?.cloneSourceId && (
            <a
              href={`/shipments/${shipment.cloneSourceId}`}
              className="text-gray-900 hover:text-black-500 flex items-center gap-1"
              aria-label="View XN clone shipment">
              <SVG name="xn" className="w-5 h-5" />
              <span className="text-12">Source shipment</span>
            </a>
          )}
        </div>

        <div className="flex items-center">
          {status === 'canceled' && (
            <button
              type="button"
              className="btn"
              onClick={() => {
                if (
                  // eslint-disable-next-line no-alert
                  window.confirm(
                    'Are you sure you want to un-cancel this shipment?',
                  )
                ) {
                  reduxDispatch(
                    uncancelShipmentById({
                      accountId: broker?.id,
                      shipmentId: shipment.id,
                    }),
                  )
                }
              }}>
              Uncancel
            </button>
          )}

          <ShipmentStatus status={status} />
        </div>
      </div>

      <div className="px-8 pb-8 flex items-end justify-between">
        <div className="text-12 text-black-500 grid grid-cols-[120px_1fr] gap-2">
          <span className="text-black-100">Internal BOL</span>
          <div>{originalBOLNumber}</div>

          {bookedDate && (
            <>
              <span className="text-black-100">Booked</span>
              <div>{format(bookedDate, 'eee, MMM\u00A0d, yyyy p')}</div>
            </>
          )}

          {currentExternalId && (
            <>
              <span className="text-black-100">External ID</span>
              <ClickToCopy className="text-left" text={currentExternalId}>
                {currentExternalId}
              </ClickToCopy>
            </>
          )}
        </div>
        <div className="text-12 text-black-500 grid grid-cols-[120px_1fr] gap-2 mt-2">
          {proNumber && (
            <>
              <span className="text-black-100">PRO number</span>
              <ClickToCopy text={proNumber}>
                <div>{proNumber}</div>
              </ClickToCopy>
            </>
          )}

          {quoteNumber && (
            <>
              <span className="text-black-100">Quote number</span>
              <ClickToCopy text={quoteNumber}>
                <div>{quoteNumber}</div>
              </ClickToCopy>
            </>
          )}
        </div>
      </div>
      <Card>
        <div className="p-8 flex flex-col gap-4">
          <div className="flex justify-between">
            <div>
              <div className="flex items-center">
                <img
                  src={broker.logoUrl}
                  title={broker.name}
                  alt={broker.name}
                  className="h-8"
                />
                <div className="ml-4 text-black-100 text-12">{broker.name}</div>
              </div>
              <div className="text-12 text-black-500 grid grid-cols-[120px_1fr] gap-2 mt-2">
                <span className="text-black-100">Billing address</span>
                <Address address={broker.billingAddress} showCompany={false} />
                <span className="text-black-100">Billing Email</span>{' '}
                <div>{broker.billingEmail}</div>
                <span className="text-black-100">Account Number</span>{' '}
                <div>{broker.accountNumber}</div>
              </div>
            </div>

            <div className="text-sm text-black-500 text-right">
              <div>{quote.carrierName}</div>
              {quote.providerId !== quote.carrierId && (
                <div> via {quote.providerName}</div>
              )}
              {quote.isExtendedNetwork && (
                <div className="flex items-center gap-1 text-12 text-black-100">
                  <SVG name="xn" className="w-5 h-5" />
                  <div>{quote.providerIntegrationRef}</div>
                </div>
              )}
              {equipmentType && <div>{equipmentType}</div>}
            </div>
          </div>

          <div className="pt-4 border-t border-gray-200">
            <dl className="grid grid-cols-1 gap-3 sm:grid-cols-2">
              <div>
                <dt className="text-12 text-black-100 truncate">Origin</dt>
                <dd className="mt-1">
                  <Address address={origin} />
                  {pickupDate && (
                    <div className="text-17 mt-3">
                      {format(pickupDate, 'E, MMM\u00A0d')}
                      <span className="text-12 text-black-100 pl-1">
                        {format(pickupDate, 'yyyy')}
                      </span>
                    </div>
                  )}
                </dd>
              </div>
              <div>
                <dt className="text-12 text-black-100 truncate">Destination</dt>
                <dd className="mt-1">
                  <Address address={destination} />
                  {deliveryDate && (
                    <div className="text-17 mt-3">
                      {format(deliveryDate, 'E, MMM\u00A0d')}
                      <span className="text-12 text-black-100 pl-1">
                        {format(deliveryDate, 'yyyy')}
                      </span>
                    </div>
                  )}
                </dd>
              </div>
            </dl>
          </div>

          <div className="pt-4 border-t border-gray-200 flex flex-col gap-1">
            <div className="text-12 text-black-100">
              {numberFormatter.format(totalHandlingUnits)} Handling units
            </div>
            {handlingUnits.map((unit) => (
              <div
                key={unit.id}
                className="text-13 flex items-baseline gap-2 hover:bg-gray-100 rounded-sm -mx-2 px-2 py-1">
                <span>{unit.description}</span>

                {unit.quantity && unit.packageType ? (
                  <span className="text-black-300 text-12">
                    {unit.quantity}&nbsp;
                    {pluralize(
                      getPackageTypeLabel(unit) ?? '',
                      unit.quantity ?? 0,
                    )}
                  </span>
                ) : null}

                <span className="text-black-300 text-11 border px-0.5 rounded">
                  {unit.freightClass}
                </span>

                <div className="pl-2 ml-auto">{unit.weightLB} lbs</div>
              </div>
            ))}
            <div className="flex justify-end gap-4 text-13 font-bold py-1">
              <div>
                {numberFormatter.format(totalWeightLB)}
                <abbr> lb</abbr>{' '}
              </div>
              {totalLength && (
                <div>
                  {numberFormatter.format(totalLength)}
                  <abbr>ft</abbr>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="p-8 flex flex-col gap-3 border-t border-gray-300">
          {isExtendedNetwork && (
            <>
              <div className="flex items-baseline">
                {charges.length < 1 ? (
                  <div className="text-12 text-black-100">Network cost</div>
                ) : (
                  <button
                    type="button"
                    className="flex items-center group"
                    onClick={() => {
                      setCostDetailsOpen((isOpen) => !isOpen)
                    }}>
                    <div className="text-12 font-semibold !text-black-100 !opacity-100">
                      Network cost
                    </div>
                    <SVG
                      name={costDetailsOpen ? 'arrowDown' : 'arrowRight'}
                      className="w-5 h-5 text-gray-600 group-hover:text-gray-900"
                    />
                  </button>
                )}
                <span className="ml-auto">
                  {formatAsUSD(quote.networkCost)}
                </span>
              </div>
              <Charges charges={charges} costDetailsOpen={costDetailsOpen} />

              <div className="flex items-baseline">
                <div className="text-12 text-black-100">Network profit</div>
                <span className="ml-auto">
                  <span className="text-xs mr-2 text-gray-800">
                    ({networkMargin}%)
                  </span>
                  {formatAsUSD(networkProfit)}
                </span>
              </div>
            </>
          )}
          <div className="flex items-baseline">
            {isExtendedNetwork || charges.length < 1 ? (
              <div className="text-12 text-black-100">Broker cost</div>
            ) : (
              <button
                type="button"
                className="flex items-center group"
                onClick={() => {
                  setCostDetailsOpen((isOpen) => !isOpen)
                }}>
                <div className="text-12 text-black-100">Broker cost</div>
                <SVG
                  name={costDetailsOpen ? 'arrowDown' : 'arrowRight'}
                  className="w-5 h-5 text-gray-600 group-hover:text-gray-900"
                />
              </button>
            )}
            <span className="ml-auto">{formatAsUSD(quote.cost)}</span>
          </div>
          {!isExtendedNetwork && (
            <Charges charges={charges} costDetailsOpen={costDetailsOpen} />
          )}
          <div className="flex items-baseline">
            <div className="text-12 text-black-100">Broker profit</div>
            <span className="ml-auto">
              <span className="text-xs mr-2 text-gray-800">
                ({Math.round(quote.grossMargin, 2)}%)
              </span>
              {formatAsUSD(quote.grossProfit)}
            </span>
          </div>
          <div className="flex items-baseline">
            <div className="text-12 text-black-100">Customer price</div>
            <span className="ml-auto">{formatAsUSD(quote.price)}</span>
          </div>
        </div>
      </Card>
    </Card>
  )
}

function Charges({charges, costDetailsOpen} = {}) {
  if (charges.length < 1) return null

  return (
    <Transition
      show={costDetailsOpen}
      enter="transition-all duration-1000"
      enterFrom="opacity-0 max-h-0"
      enterTo="opacity-100 max-h-[600px]"
      leave="transition-all duration-300"
      leaveFrom="opacity-100 max-h-[600px]"
      leaveTo="opacity-0 max-h-0"
      unmount={false}>
      <div className="flex flex-col divide-y">
        {charges.map((charge, index) => (
          // Currently this is view only and does not change when on page,
          // using index as we have no other option for identifier
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="flex items-baseline py-1 text-12">
            {charge.name}{' '}
            <span className="ml-auto">{formatAsUSD(charge.amount)}</span>
          </div>
        ))}
      </div>
    </Transition>
  )
}
