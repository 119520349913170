import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  fetchProviders,
  selectAllProviders,
  selectProviderById,
} from 'reducers/providers'
import api from 'services/api'
import {FilterPill, FilterSection, FilterSelect} from 'components/filters'

export default function FilterCarrier({
  entityType,
  onFilterChange,
  carrierId,
  isDesktop,
}) {
  const reduxDispatch = useDispatch()
  const {loadedAt} = useSelector((state) => state.providers)
  const providers = useSelector(selectAllProviders) || []
  const provider =
    useSelector((state) => selectProviderById(state, carrierId)) || {}

  const [distinctValues, setDistinctValues] = useState()
  const UiComponent = isDesktop ? FilterPill : FilterSection

  useEffect(() => {
    if (!loadedAt) {
      reduxDispatch(fetchProviders())
    }
  }, [])

  const fetchDistinctValues = async () => {
    let values
    if (entityType === 'shipment') {
      values = await api.fetchDistinctShipmentValues('quote.carrierId')
    }
    if (values) {
      setDistinctValues(new Set(values))
    }
  }

  const onFirstOpen = () => {
    fetchDistinctValues()
  }

  // If possible, limit the list of options to distinct values that exist in our data
  let options
  if (distinctValues) {
    options = providers.filter((item) => distinctValues.has(item.id))
  } else {
    options = providers
  }
  options = options.map((item) => ({
    value: item.id,
    label: item.friendlyName,
    img: item.code,
  }))

  const onSelect = (thisCarrierId) => {
    onFilterChange({carrierId: thisCarrierId})
  }

  const clearFilter = () => {
    onFilterChange({carrierId: null})
  }

  return (
    <UiComponent
      title="Carrier"
      label={provider?.friendlyName || 'Carrier'}
      active={provider?.friendlyName?.length > 0}
      closeOnChange
      onFirstOpen={onFirstOpen}
      clearFilter={clearFilter}>
      <FilterSelect
        entity="provider"
        placeholder="select a carrier"
        options={options}
        selectedValue={carrierId}
        width={300}
        onSelect={onSelect}
      />
    </UiComponent>
  )
}
