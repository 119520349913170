import React, {useState} from 'react'
import {Checkbox, IconWithCopy} from '@quotefactory/ui'
import {HiddenValue, ProviderLogo} from 'components'
import api from 'services/api'

export const statusColor = (status) =>
  ({
    activating: 'text-yellow-500',
    activated: 'text-green-500',
    error: 'text-red-500',
  })[status] || 'text-gray-500'

const KeyValRow = function ({label, value}) {
  return (
    <tr className="h-8">
      <th className="whitespace-nowrap text-right pr-2">{label}:</th>
      <td>
        <div className="flex items-center">
          {label === 'Password' ? <HiddenValue value={value} /> : value}
          <div className="ml-2">
            <IconWithCopy icon="copy" text={value} />
          </div>
        </div>
      </td>
    </tr>
  )
}

export default function ProviderIntegrationFormRoot({item, onSave}) {
  if (!item) return null

  return <ProviderIntegrationForm item={item} onSave={onSave} />
}

const ProviderIntegrationForm = function ({item, onSave}) {
  const {
    accountProviderId,
    id: integrationId,
    accountId,
    provider,
    code,
    accountNumber,
    username,
    password,
    enabled,
    active,
    blanket,
    details,
  } = item

  const [formValues, setFormValues] = useState({
    active,
    enabled,
    blanket,
    accountNumber: accountNumber || '',
  })
  const [error, setError] = useState()

  const onToggle = ({target}) => {
    const {id, checked} = target
    setFormValues((prev) => ({...prev, [id]: checked}))
  }

  const onChange = ({target}) => {
    const {name, value} = target
    setFormValues((prev) => ({...prev, [name]: value}))
  }

  const submitForm = async () => {
    if (!formValues.accountNumber && formValues.enabled) {
      setError('Account Number required')
      return
    }
    await api.updateProviderIntegration({
      ...formValues,
      accountProviderId,
      integrationId,
      accountId,
    })
    if (onSave) {
      onSave()
    }
  }

  const connectionDetailRows = React.useMemo(
    () =>
      Object.entries(provider.connectionDetails ?? {}).map(([key, label]) => (
        <KeyValRow key={key} label={label} value={details?.[key] ?? ''} />
      )),
    [provider.connectionDetails],
  )

  return (
    <div className="p-8">
      <div className="flex-1 flex flex-col items-center justify-center">
        <ProviderLogo
          code={provider.code}
          name={provider.friendlyName}
          className="my-4 w-48"
        />
        <div className="m-hide text-center">
          <small className="text-gray-900">{provider.friendlyName}</small>
        </div>
      </div>
      <div className="pt-8">
        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}>
          <table className="table-auto">
            <tbody>
              <KeyValRow label="Carrier Code" value={provider.code} />
              <KeyValRow label="Code" value={code} />
              <tr className="h-8">
                <th className="whitespace-nowrap text-right pr-2">
                  Acct Number:
                </th>
                <td>
                  <div className="flex items-center">
                    <input
                      className="p-1"
                      name="accountNumber"
                      onChange={onChange}
                      value={formValues.accountNumber}
                      required
                      aria-label="account number"
                    />
                  </div>
                </td>
              </tr>
              <KeyValRow label="Username" value={username} />
              <KeyValRow label="Password" value={password} />
              {connectionDetailRows}
            </tbody>
          </table>

          <div className="mt-6 mx-12">
            <label className="flex items-center my-3">
              <Checkbox
                id="enabled"
                name="enabled"
                checked={formValues.enabled}
                onChange={onToggle}
              />
              <span className="ml-2">Enabled</span>
            </label>

            <label className="flex items-center my-3">
              <Checkbox
                name="active"
                checked={formValues.active}
                onChange={onToggle}
              />
              <span className="ml-2">Active</span>
            </label>

            <label className="flex items-center my-3">
              <Checkbox
                name="blanket"
                checked={formValues.blanket}
                onChange={onToggle}
              />
              <span className="ml-2">Blanket</span>
            </label>
          </div>

          {error && <div className="p-2 text-red-600 rounded-md">{error}</div>}

          <div className="mt-8">
            <span className="block w-full">
              <button
                type="button"
                onClick={submitForm}
                className="btn blue shadowed w-full">
                Save
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}
