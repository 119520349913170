import React, {useState, useEffect, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  fetchProviderIntegrations,
  selectProviderIntegrations,
} from 'reducers/providerIntegrations'
import {SVG, ProviderIntegrationListItem} from 'components'

export default function ProviderIntegrationList({accountId}) {
  const reduxDispatch = useDispatch()
  const entities = useSelector(selectProviderIntegrations) || []
  const [sort, setSort] = useState({key: 'friendlyName', asc: true})

  const fetchData = async () => {
    await reduxDispatch(fetchProviderIntegrations(accountId))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const flatEntities = useMemo(() => {
    const data = []
    for (const accountProvider of entities) {
      for (const integration of accountProvider.integrations) {
        data.push({
          ...integration,
          provider: accountProvider.provider,
          accountId: accountProvider.accountId,
          accountProviderId: accountProvider.id,
          extendedNetwork: integration.extendedNetwork ? 'true' : 'false',
        })
      }
    }
    return data
  }, [entities])

  const sortedEntities = useMemo(() => {
    const {key, asc} = sort
    let sortedList
    if (['quoteRequestsCount', 'shipmentsCount'].includes(key)) {
      sortedList = flatEntities.sort((a, b) => {
        if (a[key] < b[key]) return 1
        if (a[key] > b[key]) return -1
        return 0
      })
    } else if (key === 'friendlyName') {
      sortedList = flatEntities.sort((a, b) => {
        const ret = a.provider.friendlyName.localeCompare(
          b.provider.friendlyName,
        )
        if (ret !== 0) {
          return ret
        }
        if (a.createdAt < b.createdAt) return 1
        if (a.createdAt > b.createdAt) return -1
        return 0
      })
    } else {
      sortedList = flatEntities.sort((a, b) =>
        (a[key] || '').localeCompare(b[key]),
      )
    }
    if (!asc) {
      sortedList.reverse()
    }
    return sortedList
  }, [flatEntities, sort])

  const sortList = (key) => {
    let {asc} = sort
    if (key === sort.key) {
      asc = !asc
    }
    setSort({key, asc})
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function SortIndicator({sortKey}) {
    if (sort.key !== sortKey) {
      return null
    }
    return (
      <span>
        {sort.asc && <SVG name="arrowAsc" className="asc" />}
        {!sort.asc && <SVG name="arrowDesc" className="desc" />}
      </span>
    )
  }

  const sortClass = (sortKey) => {
    return `sort uppercase text-xs ${sort.key === sortKey ? 'sorted' : ''}`
  }

  return (
    <div className="table-cards">
      {/* Header Row */}
      <div className="header flex px-6 py-2 text-sm font-semibold sticky top-0 text-black-100 mb-3">
        <div className="w-1/3">
          <button
            type="button"
            className={sortClass('name')}
            onClick={() => sortList('friendlyName')}>
            Carrier
            <SortIndicator sortKey="friendlyName" />
          </button>
        </div>
        <div className={`w-1/12 ${sortClass('accountNumber')}`}>Acct</div>
        <div className="w-1/6">
          <button
            type="button"
            className={sortClass('createdAt')}
            onClick={() => sortList('createdAt')}>
            Request Date
            <SortIndicator sortKey="createdAt" />
          </button>
        </div>
        <div className="w-1/6">
          <button
            type="button"
            className={sortClass('status')}
            onClick={() => sortList('status')}>
            Status
            <SortIndicator sortKey="status" />
          </button>
        </div>
        <div className="w-1/12">
          <button
            type="button"
            className={sortClass('extendedNetwork')}
            onClick={() => sortList('extendedNetwork')}>
            XN
            <SortIndicator sortKey="extendedNetwork" />
          </button>
        </div>
        <div className="w-1/12">
          <button
            type="button"
            className={sortClass('quoteRequestsCount')}
            onClick={() => sortList('quoteRequestsCount')}>
            Quotes
            <SortIndicator sortKey="quoteRequestsCount" />
          </button>
        </div>
        <div className="w-1/12">
          <button
            type="button"
            className={sortClass('shipmentsCount')}
            onClick={() => sortList('shipmentsCount')}>
            Shipments
            <SortIndicator sortKey="shipmentsCount" />
          </button>
        </div>
      </div>

      {sortedEntities.map((item) => (
        <ProviderIntegrationListItem
          key={item.id}
          integration={item}
          refreshData={fetchData}
        />
      ))}

      {entities.length < 1 && (
        <div className="py-4 px-6 text-sm text-black-300">
          No carrier integrations yet
        </div>
      )}
    </div>
  )
}
