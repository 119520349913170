import {configureStore} from '@reduxjs/toolkit'
import rootReducer from '../reducers'
import qfAPI from './rtk-api'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(qfAPI.middleware)
  },
})

export default store
