import React from 'react'
import {FilterPill, FilterSection} from 'components/filters'

const DEFAULT_VALUE = 'exclude'

/**
 * Filter for test shipments
 */
export default function FilterTest({onFilterChange, value, isDesktop}) {
  const filterValue = value || DEFAULT_VALUE

  const UiComponent = isDesktop ? FilterPill : FilterSection

  /* An individual filter option */
  const FilterItem = ({thisValue, children}) => {
    const selected = thisValue === filterValue
    return (
      <li className={`item ${selected ? 'active' : ''}`}>
        <button
          type="button"
          onClick={() => {
            onFilterChange({
              // Explicity set the value for isTest or the api will ignore it (intentionally).
              isTest: thisValue,
            })
          }}>
          {children}
        </button>
      </li>
    )
  }

  return (
    <UiComponent
      title={<div>Test shipments</div>}
      label={<div className="flex items-center">{filterValue} tests</div>}
      active={filterValue !== 'exclude'}
      clearFilter={() => onFilterChange({isTest: ''})}>
      <ul className="menu-list -mx-2 w-64">
        <FilterItem thisValue="exclude">
          <div className="flex items-center">exclude tests</div>
        </FilterItem>
        <FilterItem thisValue="include">
          <div className="flex items-center">include tests</div>
        </FilterItem>
        <FilterItem thisValue="only">
          <div className="flex items-center">only tests</div>
        </FilterItem>
      </ul>
    </UiComponent>
  )
}
