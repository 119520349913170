import * as React from 'react'
import {Checkbox, ClickToCopy, SVG} from '@quotefactory/ui'
import config from 'config'

const hostURL = config.QF_API_URL

const MacroPointForm = function ({
  accountId,
  formValues,
  setFormValues,
  submitForm,
  connectionConfig,
  code,
  error,
}) {
  const [mpid, setMpid] = React.useState('')
  const [venuePass, setVenuePass] = React.useState('')

  React.useEffect(() => {
    const mpidConfig =
      connectionConfig.find((c) => c.key === 'mpid')?.value || ''
    const venuePassConfig =
      connectionConfig.find((c) => c.key === 'venuePass')?.value || ''
    setMpid(mpidConfig)
    setVenuePass(venuePassConfig)
  }, [connectionConfig])

  const inputChange = (e) => {
    const {name, value} = e.target
    if (name === 'mpid') setMpid(value)
    else if (name === 'venuePass') setVenuePass(value)
  }

  const activeChange = ({target}) => {
    setFormValues((prev) => ({...prev, active: target.checked}))
  }

  const onSubmit = () => {
    const updateIntegration = {
      connectionConfig: [
        {key: 'mpid', value: mpid},
        {key: 'venuePass', value: venuePass},
      ],
      connectionState: 'connected',
    }

    submitForm(updateIntegration)
  }

  return (
    <div className="p-8">
      <div className="pt-8">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <span className="font-semibold">Code:</span>
              <span>{code}</span>
            </div>

            <div className="flex justify-between items-center">
              <span className="font-semibold">Slack:</span>
              <a
                target="_blank"
                href="https://quotefactory.slack.com/archives/C06FT8XD52N"
                rel="noreferrer"
                className="text-blue-600 hover:text-blue-800">
                #launch-macropoint
              </a>
            </div>

            <div className="flex justify-between items-center">
              <span className="font-semibold">Webhooks:</span>
              <div />
            </div>

            <div className="flex flex-col items-end gap-2">
              <ClickToCopy
                className="flex items-center"
                text={`${hostURL}/hooks/integrations/macropoint/${accountId}/location`}>
                <span>Location Updates</span>
                <SVG className="w-5 h-5 text-gray-700" name="copy" />
              </ClickToCopy>
              <ClickToCopy
                className="flex items-center"
                text={`${hostURL}/hooks/integrations/macropoint/${accountId}/orders`}>
                <span>Order status changes</span>
                <SVG className="w-5 h-5 text-gray-700" name="copy" />
              </ClickToCopy>
              <ClickToCopy
                className="flex items-center"
                text={`${hostURL}/hooks/integrations/macropoint/${accountId}/schedule-alerts`}>
                <span>Schedule Alerts</span>
                <SVG className="w-5 h-5 text-gray-700" name="copy" />
              </ClickToCopy>
              <ClickToCopy
                className="flex items-center"
                text={`${hostURL}/hooks/integrations/macropoint/${accountId}/trip-events`}>
                <span>Trip Event updates</span>
                <SVG className="w-5 h-5 text-gray-700" name="copy" />
              </ClickToCopy>
            </div>

            <div className="flex justify-between items-center">
              <span className="font-semibold mr-2">MPID:</span>
              <input
                className="p-2 border border-gray-300 rounded w-64"
                name="mpid"
                value={mpid}
                onChange={inputChange}
              />
            </div>

            <div className="flex justify-between items-center">
              <span className="font-semibold mr-2">Password:</span>
              <input
                className="p-2 border border-gray-300 rounded w-64"
                name="venuePass"
                type="password"
                value={venuePass}
                onChange={inputChange}
                required
              />
            </div>
          </div>

          <div className="flex justify-center my-3">
            <label className="flex">
              <Checkbox
                name="active"
                checked={formValues.active}
                onChange={activeChange}
              />
              <span className="ml-2">Active</span>
            </label>
          </div>

          {error && <div className="p-2 text-red-600 rounded-md">{error}</div>}

          <div className="mt-8">
            <span className="block w-full">
              <button
                type="button"
                onClick={onSubmit}
                className="btn blue shadowed w-full">
                Update
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MacroPointForm
