import * as React from 'react'
import {IconWithCopy, Toggle} from '@quotefactory/ui'
import yn from 'yn'

const QuickbooksForm = function ({
  formValues,
  setFormValues,
  submitForm,
  connectionConfig,
  code,
  error,
}) {
  const toggles = [
    {
      key: 'isExcludingFactored',
      label: 'Exclude factored shipments',
    },
    {
      key: 'isRemitToCompanyName',
      label: 'Remit-to company name to vendor display name',
    },
  ]

  const [settings, setSettings] = React.useState(() =>
    toggles.reduce((acc, {key}) => {
      const configValue = yn(
        connectionConfig.find((item) => item.key === key)?.value,
      )
      return {...acc, [key]: configValue ?? false}
    }, {}),
  )

  const handleSettingChange = (key) => (event) => {
    const newValue = event.target.checked
    setSettings((prev) => ({...prev, [key]: newValue}))
    setFormValues((prev) => ({...prev, [key]: newValue}))
  }

  const onSubmit = () => {
    submitForm({
      connectionConfig: toggles.map(({key}) => ({
        key,
        value: String(settings[key]),
      })),
    })
  }

  const getConfigVal = (key, len) => {
    const val = connectionConfig.find((i) => i.key === key)?.value || ''
    return len && val.length > len ? `${val.slice(0, len)}...` : val
  }

  const configItems = [
    {key: 'realmId', label: 'Company Id:', truncate: 25},
    {key: 'billAccountId', label: 'Bill Account ID:', truncate: 5},
    {key: 'invoiceServiceId', label: 'Invoice Service ID:', truncate: 5},
  ]

  return (
    <div className="p-8 w-[500px]">
      <div className="pt-2">
        <form>
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold">Quickbooks Online Settings</h2>
            <hr className="flex-grow ml-4 border-t-2 border-gray-300" />
          </div>

          <div className="flex flex-col gap-4">
            {configItems.map(({key, label, truncate}) => (
              <div key={key} className="flex justify-between items-center">
                <span className="font-semibold">{label}</span>
                <div className="flex items-center">
                  <span className="mr-1">{getConfigVal(key, truncate)}</span>
                  <div>
                    <IconWithCopy
                      className="w-4"
                      icon="copy"
                      text={getConfigVal(key)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <hr className="flex-grow border-t-2 border-gray-300 mt-4" />

          <div className="flex flex-col gap-4 my-3">
            <div className="flex justify-between items-center">
              <span className="font-semibold">Options:</span>
            </div>
          </div>

          {toggles.map(({key, label}) => (
            <div key={key} className="flex justify-end my-3">
              <span className="ml-2 mr-2">{label}</span>
              <label className="flex">
                <Toggle
                  name={key}
                  checked={settings[key]}
                  onChange={handleSettingChange(key)}
                />
              </label>
            </div>
          ))}

          {error && <div className="p-2 text-red-600 rounded-md">{error}</div>}

          <div className="mt-8">
            <span className="block w-full">
              <button
                type="button"
                onClick={onSubmit}
                className="btn blue shadowed w-full">
                Update
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}

export default QuickbooksForm
