import * as React from 'react'
import {SVG} from '@quotefactory/ui'

export default function ErrorPage(props) {
  const {
    error,
    title = 'Error occurred',
    description = 'The page you’re looking for is missing.',
    children = null,
  } = props

  React.useEffect(() => {
    console.warn(error, error.metadata)
  }, [])

  return (
    <div className="flex-1 overflow-y-auto focus:outline-none bg-blue-700 text-blue-100 flex flex-col items-center justify-center min-h-screen p-8 text-center w-full">
      <SVG name="wrecker" className="mb-4 w-32" />
      <h1 className="text-blue-200 my-2">{title}</h1>
      <p className="text-blue-500 mb-16">{description ?? error.message}</p>

      {children}
    </div>
  )
}
