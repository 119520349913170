import {
  useGetIntegrationsQuery,
  useUpdateIntegrationMutation,
  useCreateIntegrationMutation,
} from 'services/rtk-api'

export default function useIntegrations(props) {
  const {data: integrations = [], isLoading} = useGetIntegrationsQuery(
    {},
    {skip: !!props?.skip},
  )

  const getIntegrationById = (id) => integrations.find((i) => i.id === id)

  const getIntegrationByCode = (code) =>
    integrations.find((i) => i.code === code)

  const getIntegrations = ({type}) =>
    integrations.filter(
      (i) =>
        // Is it the type we want
        !type || i.integrationType === type,
    )

  const [updateIntegration] = useUpdateIntegrationMutation()
  const [createIntegration] = useCreateIntegrationMutation()

  return {
    integrations,
    isLoading,
    getIntegrationById,
    getIntegrationByCode,
    getIntegrations,
    updateIntegration,
    createIntegration,
  }
}
