import * as React from 'react'
import {Button, Modal, Tab} from '@quotefactory/ui'
import {JSONTree} from 'react-json-tree'
import useRunReport from 'hooks/use-run-report'
import api from '../services/api'
import DataTable from './reports/DataTable'
import {FMCSACard} from './FMCSACard'
import {useGetProviderQuery} from '../services/rtk-api'

export default function ProviderModal({provider, onClose}) {
  const {data: fullProvider} = useGetProviderQuery(
    {providerId: provider?.id},
    {skip: !provider?.id},
  )

  const fmcsaData = fullProvider?.orcusProvider?.fmcsaData

  const authorizedFor = React.useMemo(() => {
    const aFor = []

    if (fmcsaData?.brokerAuthorityStatus === 'A') aFor.push('Broker')
    if (fmcsaData?.contractAuthorityStatus === 'A') aFor.push('Carrier')

    return aFor
  }, [fmcsaData?.brokerAuthorityStatus, fmcsaData?.contractAuthorityStatus])

  const runBrokersByVendor = async () => {
    if (provider) {
      await run({
        reportName: 'Vendors',
        runAsync: false,
        format: 'json',
        params: {
          providerId: provider.id,
        },
      })
    }
  }

  const [run, {isRunning, data, clearData}] = useRunReport()

  const sortedData = React.useMemo(() => {
    if (!data) {
      return []
    }
    return data.sort((a, b) => {
      if (a.broker < b.broker) return -1
      if (a.broker > b.broker) return 1
      return 0
    })
  }, [data])

  const [isRefreshing, setRefreshing] = React.useState(false)
  const refreshProvider = React.useCallback(async () => {
    if (provider) {
      setRefreshing(true)
      await api.refreshProviders({usdots: [provider.usdot]})
      setRefreshing(false)
    }
  }, [provider])

  return (
    <Modal isOpen={!!provider} onClose={onClose}>
      <Modal.Content className="ml:w-[1000px] overflow-hidden">
        <div>
          <div className="flex items-start p-6 md:p-10">
            <div className="flex-1 flex flex-col">
              <div className="pb-2">
                <div className="text-20 font-semibold">
                  {provider?.friendlyName}
                </div>
              </div>
              <div className="flex flex-col space-y-4 text-13 text-black-100">
                <div className="flex items-center space-x-4">
                  {provider?.scac && (
                    <span className="uppercase">{provider.scac}</span>
                  )}
                  <span>USDOT {provider?.usdot}</span>
                  {provider?.mcNumbers?.length > 0 &&
                    provider?.mcNumbers.map((mc) => (
                      <span key={mc}>MC-{mc}</span>
                    ))}
                </div>
                {provider?.ffNumbers?.length > 0 && (
                  <span>
                    {provider?.ffNumbers.map((ff) => (
                      <React.Fragment key={ff}>FF-{ff}</React.Fragment>
                    ))}
                  </span>
                )}
              </div>
            </div>

            <Button
              type="button"
              onClick={refreshProvider}
              className="btn"
              loading={isRefreshing}>
              SAFER Refresh
            </Button>
          </div>

          <Tab.Group>
            <Tab.List className="w-full flex items-center gap-2 my-4 px-6 border-b-2">
              <Tab as={React.Fragment}>
                {({selected}) => (
                  <button
                    type="button"
                    onClick={clearData}
                    title={`General information ${
                      provider?.friendlyName || 'this provider'
                    }`}
                    className={`px-2 pb-1 border-b-[3px] ${
                      selected
                        ? 'text-black-500 border-blue-500'
                        : 'text-black-100 hover:text-black-300 border-gray-300 hover:border-blue-200'
                    }`}>
                    FMCSA
                  </button>
                )}
              </Tab>

              <Tab as={React.Fragment}>
                {({selected}) => (
                  <button
                    type="button"
                    onClick={clearData}
                    title={`View "raw" JSON properties of ${
                      provider?.friendlyName || 'this provider'
                    }`}
                    className={`px-2 pb-1 border-b-[3px] ${
                      selected
                        ? 'text-black-500 border-blue-500'
                        : 'text-black-100 hover:text-black-300 border-gray-300 hover:border-blue-200'
                    }`}>
                    JSON
                  </button>
                )}
              </Tab>
              <Tab as={React.Fragment}>
                {({selected}) => (
                  <button
                    type="button"
                    onClick={runBrokersByVendor}
                    title={`View what brokers are configured to use ${
                      provider?.friendlyName || 'this provider'
                    }`}
                    className={`px-2 pb-1 border-b-[3px] ${
                      selected
                        ? 'text-black-500 border-blue-500'
                        : 'text-black-100 hover:text-black-300 border-gray-300 hover:border-blue-200'
                    }`}>
                    Brokers
                  </button>
                )}
              </Tab>
            </Tab.List>

            <Tab.Panels className="flex-1 flex flex-col max-h-[70vh] overflow-y-scroll">
              <Tab.Panel>
                {fullProvider && (
                  <FMCSACard
                    provider={fullProvider}
                    authorizedFor={authorizedFor}
                    fmcsaData={fmcsaData}
                  />
                )}
              </Tab.Panel>

              <Tab.Panel>
                <div className="pb-4 px-6 pr-4">
                  <div>
                    <JSONTree
                      data={provider}
                      hideRoot
                      shouldExpandNode={(keyPath) => {
                        if (
                          keyPath.length === 1 &&
                          keyPath.includes('orcusProvider')
                        ) {
                          return true
                        }
                      }}
                      theme={{
                        scheme: 'monokai',
                        author: 'wimer hazenberg (http://www.monokai.nl)',
                        base00: '#272822',
                        base01: '#383830',
                        base02: '#49483e',
                        base03: '#75715e',
                        base04: '#a59f85',
                        base05: '#f8f8f2',
                        base06: '#f5f4f1',
                        base07: '#f9f8f5',
                        base08: '#f92672',
                        base09: '#fd971f',
                        base0A: '#f4bf75',
                        base0B: '#a6e22e',
                        base0C: '#a1efe4',
                        base0D: '#66d9ef',
                        base0E: '#ae81ff',
                        base0F: '#cc6633',
                      }}
                    />
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="py-4 px-6 min-h-[70vh]">
                  <DataTable
                    data={sortedData}
                    filename={`${provider?.friendlyName || 'vendor'}-${
                      provider?.usdot
                    }-brokers.csv`}
                    isLoading={isRunning}
                  />
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Modal.Content>
    </Modal>
  )
}
