import React from 'react'
import {format, parseISO} from 'date-fns'
import {
  SVG,
  Pressable,
  formatAsUSD,
  twMerge,
  dateFormats,
  timeFormats,
  isNotNil,
  first,
  last,
  Tooltip,
} from '@quotefactory/ui'
import {parse24HourTime} from 'helpers'

// Several functions copied from qf-app ShipmentCard.js

/**
 * Gets the visual context helper for a shipment card date.
 *
 * For dates within the year, the day is shown, for dates
 * outside of this year the year is shown.
 *
 * @param {Date} day
 */
const getDayOrYear = (day) => {
  if (!day) {
    return false
  }

  return day.getFullYear() !== new Date().getFullYear()
    ? day.getFullYear().toString()
    : format(day, dateFormats.day)
}

const formatWeight = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 0,
}).format

const useShipmentCardInput = (shipment) => {
  const quote = shipment?.quote ?? shipment?.houseQuote

  //////////////////////////////////////
  // Pickup
  //////////////////////////////////////

  const actualPickupDate =
    !!shipment.origin?.pickedUpAt && parseISO(shipment.origin.pickedUpAt)
  const expectedPickupDate = !!shipment.pickupOn && parseISO(shipment.pickupOn)
  const pickupDate = actualPickupDate || expectedPickupDate
  const formattedPickupDate =
    !!pickupDate && format(pickupDate, dateFormats.shortDate)
  const pickupDay = !!pickupDate && getDayOrYear(pickupDate)
  const pickupTime = undefined
  const isPickedUp = !!actualPickupDate
  const originAppointmentTime =
    shipment.origin?.appointment?.time && pickupDate
      ? format(
          parse24HourTime(shipment.origin.appointment.time, pickupDate),
          'h:mmaaa',
        )
      : undefined

  //////////////////////////////////////
  // Delivery
  //////////////////////////////////////

  const actualDeliveryDate =
    !!shipment.destination?.deliveredAt &&
    parseISO(shipment.destination.deliveredAt)
  const providedDeliveryDate =
    !!shipment.destination?.date && parseISO(shipment.destination.date)
  const expectedDeliveryDate =
    !!quote?.estimatedDeliveryDate && parseISO(quote.estimatedDeliveryDate)
  const deliveryDate =
    actualDeliveryDate || providedDeliveryDate || expectedDeliveryDate
  const isEstimatedDeliveryDate = deliveryDate === expectedDeliveryDate
  const isDelivered = !!actualDeliveryDate
  const formattedDeliveryDate =
    deliveryDate && format(deliveryDate, dateFormats.shortDate)
  const deliveryDay = deliveryDate && getDayOrYear(deliveryDate)
  const deliveryTime = deliveryDate && format(deliveryDate, timeFormats.display)

  const leadsWithQuotes = React.useMemo(
    () =>
      (shipment.coverageLeads ?? [])
        .reduce(
          (acc, lead) => (isNotNil(lead?.cost) ? [...acc, lead.cost] : acc),
          /** @type {number[]} */ ([]),
        )
        .sort(),
    [shipment.coverageLeads],
  )

  const leadCostRange = React.useMemo(() => {
    const firstLead = first(leadsWithQuotes)
    const lastLead = last(leadsWithQuotes)

    if (firstLead && lastLead && firstLead !== lastLead) {
      return (
        <>
          {formatAsUSD(firstLead)} &mdash; {formatAsUSD(lastLead)}
        </>
      )
    }

    if (firstLead) {
      return formatAsUSD(firstLead)
    }

    return <>$ &ndash; &ndash; &ndash;</>
  }, [leadsWithQuotes])

  const {status} = shipment

  const customer = shipment.customer?.name

  const shipper =
    customer !== shipment.origin?.company && shipment.origin?.company

  // Hide the consignee label if the customer is the consignee
  const consignee =
    customer !== shipment.destination?.company && shipment.destination?.company

  const result = {
    carrier: quote?.carrierName,
    customer,
    cost:
      shipment.brokerPayable?.amount || quote?.cost
        ? formatAsUSD(shipment.brokerPayable?.amount || quote?.cost)
        : null,
    price:
      shipment.customerPayable?.amount || quote?.price
        ? formatAsUSD(shipment.customerPayable?.amount || quote?.price)
        : null,

    pickupDate: formattedPickupDate,
    pickupDay,
    pickupTime,
    isPickedUp,
    originAppointmentTime,

    isEstimatedDeliveryDate,
    deliveryDate: formattedDeliveryDate,
    deliveryDay,
    deliveryTime,
    isDelivered,

    bol: shipment.bolNumber,
    isExtendedNetwork: quote?.isExtendedNetwork,
    isCloned: !!shipment.clonedAt,
    isBrokerProvider: quote?.isBrokerProvider,
    providerName: quote?.providerName,
    shipper,
    originCity: shipment.origin?.city,
    originState: shipment.origin?.stateCode,
    stops: shipment.locs && shipment.locs.length > 2,
    destinationCity: shipment.destination?.city,
    destinationState: shipment.destination?.stateCode,
    consignee,
    status,
    statusColor:
      /* eslint-disable no-nested-ternary */
      status === 'requested' && !shipment.isFromCustomer
        ? 'bg-gray-400 border-dashed'
        : status === 'requested' && shipment.isFromCustomer
        ? 'bg-orange-500'
        : status === 'booked'
        ? 'bg-orange-500'
        : status === 'scheduled'
        ? 'bg-blue-200'
        : status === 'in-transit'
        ? 'bg-blue-500'
        : status === 'delivered'
        ? 'bg-green-500'
        : status === 'canceled'
        ? 'bg-red-200'
        : 'bg-gray-400',
    totalWeight: `${formatWeight(shipment.totalWeightLB)} lb`,
    equipment: shipment.mode === 'ltl' ? 'LTL' : shipment.equipmentType,
    isPartialAllowed: !!shipment.equipmentOptions?.isPartialOkay,
    isPartial: shipment.quote?.isPartial ?? undefined,
    hasLeads: !!shipment.coverageLeads?.length,
    totalLeads: shipment.coverageLeads?.length,
    hasQuoteLeads: !!leadsWithQuotes.length,
    leadCostRange,
    locs: shipment.locs,
    attention: shipment.isAutoCover,
  }

  return result
}

/**
 * @typedef ShipmentCardProps
 * @prop {QF.Shipment} item
 * @prop {React.MouseEventHandler} [onClick]
 */

export default function ShipmentCard({shipment, onRowClick}) {
  const {broker, totalHandlingUnits, isTest} = shipment
  const {
    carrier,
    customer,
    cost,
    price,
    pickupDate,
    pickupDay,
    originAppointmentTime,
    isDelivered,
    isPickedUp,
    deliveryDate,
    deliveryDay,
    deliveryTime,
    bol,
    shipper,
    originCity,
    originState,
    stops,
    destinationCity,
    destinationState,
    consignee,
    status,
    statusColor,
    totalWeight,
    equipment,
    isPartialAllowed,
    isPartial,
    hasLeads,
    totalLeads,
    hasQuoteLeads,
    leadCostRange,
    isExtendedNetwork,
    isCloned,
    isBrokerProvider,
    providerName,
    locs,
    attention,
  } = useShipmentCardInput(shipment)

  return (
    <>
      <div className="@container">
        <Pressable
          className={twMerge(
            `text-13 flex flex-wrap px-3 @3xl:px-2 my-0 w-full items-start rounded-md group shadow-[0_0_0_2px] shadow-gray-200 hover:shadow-blue-500 before:absolute before:-inset-y-[4px] before:inset-x-1.5 before:border-b before:border-gray-400/0 after:absolute after:inset-0 after:-z-1 after:rounded-md after:bg-white hover:after:bg-white duration-100 relative z-0 py-3`,
            status === 'requested' && 'before:border-dashed',
            !!attention &&
              'after:border-l-[4px] after:border-purple-300/50 hover:after:border-purple-500 hover:shadow-purple-500',
            status === 'canceled' && 'opacity-50 hover:opacity-100',
          )}
          onClick={() => onRowClick(shipment.id)}>
          <div className="pl-2 pr-1 @md:pr-2 @7xl:pr-4 w-1/12 min-w-[6rem] relative">
            <div className="flex items-center pr-1 @lg:pr-0">
              <div
                className={twMerge(
                  `w-[10px] h-[6px] rounded-sm shrink-0 mr-[6px]`,
                  statusColor,
                )}
              />
              <div className=" text-black-500 min-w-0 flex items-center">
                <div>{bol}</div>
              </div>
            </div>
            <div className="text-11 text-transparent group-hover:text-black-100 transition pt-1 pl-4">
              <span className="text-11 capitalize">{status}</span>&nbsp;
            </div>
            {attention && (
              <SVG
                name="magic"
                className="text-purple-300/50 fill-purple-300/50 group-hover:text-purple-500 group-hover:fill-purple-500 absolute left-1 -bottom-3 w-5 h-5"
              />
            )}
          </div>

          <div className="px-1 @md:px-2 @7xl:px-3 @3xl:w-1/12 @3xl:min-w-[6.5rem] @4xl:min-w-[6rem]">
            <div className="min-w-0 flex items-center">
              {status === 'in-transit' && (
                <SVG
                  name="arrow"
                  className="rotate-90 w-3 h-3 shrink-0 -ml-3 text-gray-700"
                  lineWidth={3}
                />
              )}
              {status === 'delivered' && (
                <SVG
                  name="arrow"
                  className="rotate-90 w-3 h-3 shrink-0 -ml-3 text-green-500"
                  lineWidth={3}
                />
              )}

              <div className="truncate">
                {isDelivered ? (
                  <>
                    {deliveryDate}{' '}
                    <span className="text-black-100">{deliveryDay}</span>
                  </>
                ) : (
                  <>
                    {pickupDate}{' '}
                    <span className="text-black-100">{pickupDay}</span>
                  </>
                )}
              </div>
            </div>
            <div className="text-11 text-black-100 pt-1 truncate">
              {isDelivered
                ? deliveryTime
                : originAppointmentTime
                ? `Appt @ ${originAppointmentTime}`
                : ''}
              {status === 'in-transit' && isPickedUp && 'Picked up'}
            </div>
          </div>

          <div className="px-1 @md:px-2 @7xl:px-4 ml-auto @3xl:ml-0 min-w-[50%] @3xl:min-w-0 text-right @3xl:text-left @2xl:w-2/12 z-50">
            <Tooltip id={shipment.id} className="relative z-50">
              <Tooltip.Trigger
                as="div"
                className="truncate flex items-center gap-1 cursor-pointer">
                <div
                  title={isTest ? 'test' : 'live'}
                  className={`w-2 h-2 border rounded-full ${
                    isTest
                      ? 'bg-orange-500 border-orange-600'
                      : 'bg-green-500 border-green-600'
                  }`}
                />
                {broker.name}
              </Tooltip.Trigger>
              <Tooltip.Content placement="top">
                <span className="text-black-100">Customer:</span> {customer}
              </Tooltip.Content>
            </Tooltip>

            <div className="text-11 text-black-100 pt-1">Price: {price}</div>
          </div>

          <div className="flex-1 flex min-w-full @3xl:min-w-0 py-2 @3xl:py-0 my-2 @3xl:my-0 relative before:border-y before:absolute before:inset-x-0 before:inset-y-0 @3xl:before:hidden order-0 @3xl:order-none">
            <div className="px-1 @md:px-2 @7xl:px-4 flex-1 min-w-0">
              <div className="flex justify-between">
                <div className="truncate">{originCity}</div>
                <div className="shrink-0">{originState}</div>
              </div>
              <div className="text-11 text-black-100 pt-1 truncate">
                {shipper}
              </div>
            </div>

            <div className="w-10 @3xl:w-auto flex justify-center">
              {stops ? (
                <div className="text-11 text-gray-900 font-semibold leading-none w-4 h-4 flex items-center justify-center border border-gray-500 rounded-full relative before:absolute before:border-t before:border-gray-500 before:-left-1.5 before:w-1.5 after:absolute after:border-t after:border-gray-500 after:-right-1.5 after:w-1.5">
                  {locs.length - 2}
                </div>
              ) : (
                <SVG
                  name="arrow"
                  className="self-start text-gray-700 w-4 h-2 pt-1 shrink-0"
                />
              )}
            </div>

            <div className="px-1 @md:px-2 @7xl:px-4 flex-1 min-w-0">
              <div className="flex justify-between">
                <div className="truncate">{destinationCity}</div>
                <div className="shrink-0">{destinationState}</div>
              </div>
              <div className="text-11 text-black-100 pt-1 truncate">
                {/* REF 62345234572 */}
                {consignee}
              </div>
            </div>
          </div>

          <div className="pr-1 pl-1 @md:pl-2 @7xl:pl-4 min-w-full @3xl:w-1/12 @3xl:min-w-[6rem] @4xl:min-w-[8rem] order-last @3xl:order-none flex justify-between @3xl:block">
            <div className="truncate">
              {isPartial && (
                <span className="text-white text-11 font-bold mr-1 px-1 rounded bg-gray-800 pointer-events-auto">
                  P
                </span>
              )}
              {equipment}
            </div>
            <div className="text-11 text-black-100 pt-1 truncate">
              {totalWeight}{' '}
              {totalHandlingUnits && <>&mdash; {totalHandlingUnits} HUs</>}
            </div>
          </div>

          <div
            className={`pr-2 pl-1 @md:pl-2 @7xl:pl-4 text-right @3xl:ml-auto min-w-full @3xl:min-w-[4rem] flex justify-between @3xl:block w-2/12 `}>
            {carrier ? (
              <>
                <div className="flex items-center justify-end min-w-0">
                  <div className="truncate">{carrier}</div>
                  {/* Show Extended Network icon */}
                  {isExtendedNetwork && (
                    <div className="flex relative group/xn">
                      <SVG
                        name="xn"
                        className={`w-4 ${
                          isCloned ? 'text-gray-800' : 'text-orange-500'
                        }`}
                      />
                      <div className="absolute -top-6 -left-4 @3xl:left-auto @3xl:right-0 hidden group-hover/xn:block whitespace-nowrap bg-white rounded-md px-2 py-1 shadow-sm border border-gray-400">
                        Extended Network
                      </div>
                    </div>
                  )}
                  {/* Show Via icon */}
                  {isBrokerProvider && !isExtendedNetwork && (
                    <div className="flex relative group/xn">
                      <SVG name="shared" className="w-4 text-gray-800" />
                      <div className="absolute -top-6 -left-4 @3xl:left-auto @3xl:right-0 hidden group-hover/xn:block whitespace-nowrap bg-white rounded-md px-2 py-1 shadow-sm border border-gray-400">
                        via {providerName}
                      </div>
                    </div>
                  )}
                </div>
                <div className="text-11 text-black-100 pt-1">{cost}</div>
              </>
            ) : (
              <>
                {hasLeads ? (
                  <>
                    <div className="truncate text-black-100">
                      &nbsp;
                      <span className="text-11">
                        {totalLeads} {totalLeads === 1 ? 'lead' : 'leads'}
                      </span>
                      {isPartialAllowed && (
                        <span className="text-gray-800 text-11 font-bold ml-1 px-1 rounded border border-dashed border-gray-700">
                          P
                        </span>
                      )}
                    </div>

                    {hasQuoteLeads ? (
                      <div className="text-11 text-black-100 pt-1 flex items-center justify-end gap-2">
                        <span className="text-black-100 truncate">
                          {leadCostRange}
                        </span>
                      </div>
                    ) : (
                      <div className="text-11 text-gray-500 pt-1 truncate">
                        {leadCostRange}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="truncate text-gray-500">
                      No carrier
                      {isPartialAllowed && (
                        <span className="text-gray-800 text-11 font-bold ml-1 px-1 rounded border border-dashed border-gray-700">
                          P
                        </span>
                      )}
                    </div>
                    <div className="text-11 text-gray-500 pt-1 truncate">
                      {leadCostRange}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Pressable>
      </div>
    </>
  )
}
