import React from 'react'
import {capitalize} from 'helpers'
import {twMerge} from '@quotefactory/ui'

/**
 * Gets the visuals for a shipment statuses
 * @param {string} status
 */
const getDisplayStatus = (status) => capitalize(status)

export default function ShipmentStatus({status}) {
  const statusColor =
    {
      requested: 'bg-yellow-200 text-yellow-900',
      booked: 'bg-yellow-500 text-black-500',
      scheduled: 'bg-blue-200 text-black-500',
      'in-transit': 'bg-blue-500 text-white',
      delivered: 'bg-green-600 text-white',
      canceled: 'bg-gray-300 text-black-100',
    }[status] || 'bg-gray-300'

  return (
    <span
      className={twMerge(
        `px-1.5 py-0.5 rounded text-xs text-white`,
        statusColor,
      )}>
      {getDisplayStatus(status)}
    </span>
  )
}
