import React from 'react'
import {Router, Redirect, globalHistory} from '@reach/router'
import {QueryParamProvider} from 'use-query-params'
import {ReachAdapter} from 'use-query-params/adapters/reach'
import {AuthRoute, AuthCallback} from 'components'
import {
  Dashboard,
  DashboardMetabase,
  Login,
  Logout,
  NotFound,
  Accounts,
  Account,
  Shipments,
  ShipmentJSON,
  Providers,
  Reports,
  Factors,
  Factor,
} from 'pages'
import {
  AccountImportCustomer,
  AccountImportAddressBook,
  AccountImportShipment,
  AccountImportVendor,
} from 'pages/data-import'

function Routes() {
  return (
    <Router>
      <QueryParamProvider
        {...{path: '/'}}
        reachHistory={globalHistory}
        adapter={ReachAdapter}>
        <Redirect from="/" to="/dashboard" noThrow />

        <Login path="/login" />
        <Logout path="/logout" />

        <AuthCallback path="/auth" />

        <AuthRoute path="/">
          <Dashboard path="/dashboard" />
          <Dashboard path="/dashboard/broker-pace" />
          <DashboardMetabase path="/dashboard/metabase/:dashboardId" />
          <Accounts path="/accounts" />
          <Account path="/account/:accountId" />
          <AccountImportCustomer path="/account/:accountId/import/customer" />
          <AccountImportAddressBook path="/account/:accountId/import/address-book" />
          <AccountImportShipment path="/account/:accountId/import/shipment" />
          <AccountImportVendor path="/account/:accountId/import/vendor" />
          <Shipments path="/shipments/*" />
          <ShipmentJSON path="/shipment/:shipmentId/json" />
          <Providers path="/providers" />
          <Reports path="/reports" />
          <Reports path="/reports/:report" />
          <Factors path="/factors" />
          <Factor path="/factor/:factorId" />

          <NotFound default />
        </AuthRoute>
      </QueryParamProvider>
    </Router>
  )
}

export default Routes
