import * as React from 'react'
import {Checkbox} from '@quotefactory/ui'

const TruckerToolsForm = function ({
  formValues,
  setFormValues,
  submitForm,
  connectionConfig,
  code,
  error,
}) {
  const [accountId, setAccountId] = React.useState('')

  React.useEffect(() => {
    const accountIdConfig =
      connectionConfig.find((c) => c.key === 'accountId')?.value || ''
    setAccountId(accountIdConfig)
  }, [connectionConfig])

  const inputChange = (e) => {
    const {name, value} = e.target
    if (name === 'accountId') setAccountId(value)
  }

  const activeChange = ({target}) => {
    setFormValues((prev) => ({...prev, active: target.checked}))
  }

  const onSubmit = () => {
    const updateIntegration = {
      connectionConfig: [{key: 'accountId', value: accountId}],
      connectionState: 'connected',
    }

    submitForm(updateIntegration)
  }

  return (
    <div className="p-8">
      <div className="pt-8">
        <form>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <span className="font-semibold">Code:</span>
              <span>{code}</span>
            </div>

            <div className="flex justify-between items-center">
              <span className="font-semibold">Slack:</span>
              <a
                target="_blank"
                href="https://quotefactory.slack.com/archives/C07D41HB5PE"
                rel="noreferrer"
                className="text-blue-600 hover:text-blue-800">
                #launch-trucker-tools
              </a>
            </div>

            <div className="flex justify-between items-center">
              <span className="font-semibold mr-2">Account ID:</span>
              <input
                className="p-2 border border-gray-300 rounded w-64"
                name="accountId"
                value={accountId}
                onChange={inputChange}
              />
            </div>
          </div>

          <div className="flex justify-center my-3">
            <label className="flex">
              <Checkbox
                name="active"
                checked={formValues.active}
                onChange={activeChange}
              />
              <span className="ml-2">Active</span>
            </label>
          </div>

          {error && <div className="p-2 text-red-600 rounded-md">{error}</div>}

          <div className="mt-8">
            <span className="block w-full">
              <button
                type="button"
                onClick={onSubmit}
                className="btn blue shadowed w-full">
                Update
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}

export default TruckerToolsForm
