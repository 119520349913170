import React from 'react'
import {Link} from '@reach/router'
import {SVG} from '@quotefactory/ui'
import {Layout} from 'pages'
import reports from 'components/reports'
import useRunReport from 'hooks/use-run-report'

const {ReportsList} = reports

export default function Reports({report}) {
  const [run, {isRunning, result, error}] = useRunReport()

  const Report = reports[report]

  const onRun = async ({reportName, runAsync, params}) => {
    await run({reportName, runAsync, params})
  }

  return (
    <Layout>
      <div className="flex items-center py-2 justify-between my-6">
        <h2>Reports</h2>
        <div className="flex item-center">
          <SVG name="link" />
          <a
            target="_blank"
            rel="noreferrer"
            className="link"
            href="https://drive.google.com/drive/folders/1rVxcrUGFR81XEzUAVh0dhGYG_tAaIkte">
            Google Drive
          </a>
        </div>
      </div>

      {report ? (
        <div>
          <Link
            className="pb-4 text-gray-800 hover:text-black-500 flex items-center"
            to="/reports">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <polyline
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="1.8"
                points="6.81699988 9.37115215 12.0746956 14.6288479 17.1830001 9.52054332"
                transform="matrix(0 -1 -1 0 24 24)"
              />
            </svg>
            back to list
          </Link>
          <div className="flex">
            <div className="bg-white px-10 py-20 rounded-lg min-w-[500px] flex-1 flex flex-col items-center">
              <Report onRun={onRun} isRunning={isRunning} />
            </div>
          </div>
        </div>
      ) : (
        <ReportsList />
      )}

      {result && !isRunning && (
        <>
          <div className="pt-8 text-green-700">{result.msg}</div>
          {result.msg.includes('Google Drive') && (
            <div className="flex item-center">
              <SVG name="link" />
              <a
                target="_blank"
                rel="noreferrer"
                className="link"
                href="https://drive.google.com/drive/folders/1rVxcrUGFR81XEzUAVh0dhGYG_tAaIkte">
                Google Drive
              </a>
            </div>
          )}
        </>
      )}
      {error && !isRunning && <div className="py-8 text-red-700">{error}</div>}
    </Layout>
  )
}
