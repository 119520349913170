/* eslint-disable react/jsx-props-no-spreading */
import React, {useState} from 'react'
import isEqual from 'react-fast-compare'
import {Popper} from '@quotefactory/ui'
import {SVG} from 'components'

export default React.memo(({error, touched = true, isOverlayed = false}) => {
  const errorArray = Array.isArray(error) ? error : [error]
  const touchedArray = Array.isArray(touched) ? touched : [touched]

  const anyTouchedWithError = errorArray.some((field, i) => {
    return touchedArray[i] && errorArray[i]
  })

  const [anchorEl, setAnchorEl] = useState(null)

  if (!anyTouchedWithError) return null

  return (
    <>
      <span
        aria-owns={anyTouchedWithError ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        className={`error-icon ${isOverlayed ? 'overlay' : ''}`}
        style={{
          verticalAlign: 'middle',
          lineHeight: '1.25em',
        }}
        onMouseEnter={(e) => {
          if (anchorEl !== e.currentTarget) {
            setAnchorEl(e.currentTarget)
          }
        }}
        onMouseLeave={(e) => {
          setAnchorEl(null)
        }}>
        <SVG name="error" className="inline text-red-500" />
      </span>

      <Popper anchorEl={anchorEl} isOpen={!!anchorEl}>
        {errorArray
          .filter((err, i) => err && touchedArray[i])
          .map((err, i) => {
            return <div key={err}>{err}</div>
          })}
      </Popper>
    </>
  )
}, isEqual)
