import * as React from 'react'
import {Link, useLocation} from '@reach/router'
import {PopperMenu, SVG, twMerge} from '@quotefactory/ui'
import {useListMetabaseDashboardsQuery} from '../services/rtk-api'

export default function DashboardSelect() {
  const location = useLocation()

  const {data} = useListMetabaseDashboardsQuery({})

  const options = React.useMemo(() => {
    const $options = [
      {
        label: 'Broker Pace',
        type: 'native',
        value: 'broker-pace',
        href: '/dashboard/broker-pace',
      },
    ]

    if (data) {
      const metabaseOptions = Object.entries(data).map(
        ([name, dashboardId]) => ({
          label: name,
          type: 'metabase',
          value: dashboardId,
          href: `/dashboard/metabase/${dashboardId}`,
        }),
      )

      $options.push(...metabaseOptions)
    }

    return $options
  }, [data])

  const selectedOption = React.useMemo(() => {
    const href = location.pathname

    const matchingOption = options.find((option) => option.href === href)

    if (matchingOption) return matchingOption

    if (href === '/dashboard') {
      return options[0]
    }
  }, [location.pathname, options])

  return (
    <PopperMenu placement="bottom-start">
      <PopperMenu.Trigger className="pl-1 relative flex items-center text-black-100 hover:text-black-500">
        {selectedOption?.label}{' '}
        <SVG name="arrowDown" className="w-4 h-4  -mr-2" lineWidth="2" />
      </PopperMenu.Trigger>

      <PopperMenu.Popper className="p-2 w-[200px] dark:bg-black-600 dark:border-black-700">
        <div className="flex flex-col gap-1 relative">
          {options.map((option) => (
            <Link to={option.href}>
              <PopperMenu.MenuItem
                key={option.value}
                className={twMerge(
                  'flex items-center justify-between dark:text-white rounded-md p-2 group cursor-pointer',
                  option === selectedOption
                    ? 'bg-blue-400 text-white'
                    : 'hover:bg-gray-200 hover:dark:bg-black-500 dark:text-white',
                )}>
                <span>{option.label}</span>
              </PopperMenu.MenuItem>
            </Link>
          ))}
        </div>
      </PopperMenu.Popper>
    </PopperMenu>
  )
}
