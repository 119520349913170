import React, {useRef, useState} from 'react'
import {SVG} from 'components'

export default function FilterSection({
  label,
  title,
  active,
  clearFilter,
  closeOnChange,
  onFirstOpen,
  children,
}) {
  const [isExpanded, setIsExpanded] = useState(null)
  const openedRef = useRef(false)

  const toggle = () => {
    if (!openedRef.current) {
      openedRef.current = true
      if (onFirstOpen) {
        onFirstOpen()
      }
    }
    setIsExpanded(!isExpanded)
  }

  return (
    <>
      <div
        className={`filter-section flex items-center min-w-0 border-t border-gray-300 mt-4 pt-4 ${
          active ? 'active' : ''
        } ${!isExpanded ? '' : 'focused'}`}>
        <button type="button" className="truncate" onClick={toggle}>
          {label}{' '}
        </button>
        <button
          type="button"
          onClick={toggle}
          className="adornment ml-auto text-gray-500">
          <SVG name={isExpanded ? 'arrowUp' : 'arrowDown'} />
        </button>
      </div>

      {isExpanded && <div className="pt-4">{children}</div>}
    </>
  )
}
