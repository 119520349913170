import * as React from 'react'
import {Link} from '@reach/router'
import {TextHighlight, SVG, svgsByName} from '@quotefactory/ui'

const factoringIconsByCode = /** @type {{[key: string]: string|undefined}} */ ({
  triumph: 'triumph',
  ecapital: 'ecapital',
  'otr-solutions': 'otr',
  'prime-factoring': 'primefactoring',
})

/**
 * Gets the custom factoring icon from a factoring integration code.
 *
 * @param {Object} props
 * @param {string} [props.code]
 * @param {string} [props.defaultIcon]
 * @returns {string} The custom code or undefined if none found
 */
export function getFactoringIcon({code = '', defaultIcon = 'factoring'}) {
  if (!code) return defaultIcon

  const iconName = factoringIconsByCode[code] || code
  return iconName in svgsByName ? iconName : defaultIcon
}

export default function FactorsListItem({factor, match}) {
  const {name, code, connectionConfig = []} = factor
  return (
    <Link
      to={`/factor/${factor.id}`}
      className="flex gap-x-2 relative pl-6 pr-5 py-4 mb-1.5 bg-white border border-gray-300/0 shadow-[0_0_0_2px] shadow-gray-200 p-1 w-full rounded-lg text-left hover:shadow-[0_0_0_2px] hover:shadow-blue-500 text-13 @3xl:text-15"
      aria-label={`View details for ${name}`}>
      <div className="w-4/12 @3xl:w-7/12 @3xl:flex items-center text-15 font-bold">
        <SVG name={getFactoringIcon({code})} className="w-7 mr-4" />
        <TextHighlight content={name} match={match} />
      </div>
      <div className="w-[25%] @3xl:w-3/12">
        {connectionConfig[0] ? 'YES' : ''}
      </div>
    </Link>
  )
}
